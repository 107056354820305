import React from 'react';
/**
 * Name : BarBtnComponent
 * Desc : bar btn 공통 컴포넌트
 **/

type Props = {
  btn: {
    on: boolean;
    onClick?: () => void;
    txt: string;
  }[];
};
const BarBtnComponent = ({ btn }: Props) => {
  return (
    <div className={'bar-div'}>
      {btn.map((item, idx) => (
        <span
          key={idx}
          className={item.on ? 'on' : undefined}
          onClick={item.onClick ? item.onClick : undefined}>
          {item.txt}
        </span>
      ))}
    </div>
  );
};

export default BarBtnComponent;
