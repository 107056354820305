import React, { useEffect, useState } from 'react';
import ImgComponent from '../../../components/common/ImgComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMmbrshp } from '../../../modules/user/user';
import { dtFormat } from '../../../utils/commonFunc';
import { RootState } from '../../../modules';
import { useTranslation } from 'react-i18next';

type Props = {
  id: number;
  reject: boolean;
};
const Membership = ({ id, reject }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { profile, mmbrshp } = useSelector(({ user }: RootState) => user);

  const [modify, setModify] = useState(false);
  const [reason, setReason] = useState(mmbrshp.rjctRsn);

  const jobs =
    profile?.jobs?.map(item =>
      t('constants.jobkywdtpcd.' + item.jobKywdTpCd.toLowerCase()),
    ) || [];

  useEffect(() => {
    id && id !== 0 && dispatch(getUserMmbrshp(id));
  }, [id]);
  useEffect(() => {
    setModify(false);
    setReason(mmbrshp.rjctRsn);
  }, [reject]);

  return (
    <div className={'flex p-tb-15'}>
      <div className={'detail-content y-scroll'}>
        {!id && <p className={'color-sub'}>{t('membership.nomembership')}</p>}
        {id && (
          <>
            <table className={'tb-p-15'}>
              <tbody>
                <tr>
                  <th>{t('membership.nickname')}</th>
                  <td>{mmbrshp.nickname}</td>
                </tr>
                <tr>
                  <th>{t('membership.afltn')}</th>
                  <td>{mmbrshp.afltn}</td>
                </tr>
                <tr>
                  <th>{t('membership.statemsg')}</th>
                  <td>{mmbrshp.stateMsg}</td>
                </tr>
                <tr>
                  <th>{t('membership.jobs')}</th>
                  <td>{jobs.join(', ')}</td>
                </tr>
                {mmbrshp.snsLinks.map((item, idx) => (
                  <tr key={idx}>
                    <th>{item.snsCd}</th>
                    <td className={'color-b295'}>{item.snsLink}</td>
                  </tr>
                ))}
                {mmbrshp.articles.map((item, idx) => (
                  <tr key={idx}>
                    <th>
                      {t('membership.articles')} {idx + 1}
                    </th>
                    <td className={'color-b295'}>{item.link}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <table className={'m-t-50'}>
              <tbody>
                <tr>
                  <th>{t('membership.frstrgstdttm')}</th>
                  <td>
                    {dtFormat(mmbrshp.frstRgstDttm, 'date')}{' '}
                    <span className={'color-sub'}>
                      {dtFormat(mmbrshp.frstRgstDttm, 'time')}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>{t('membership.confirmdt')}</th>
                  <td>
                    {dtFormat(mmbrshp.confirmDt, 'date')}{' '}
                    <span className={'color-sub'}>
                      {dtFormat(mmbrshp.confirmDt, 'time')}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>{t('membership.fnlmoddttm')}</th>
                  <td>
                    {dtFormat(mmbrshp.fnlModDttm, 'date')}{' '}
                    <span className={'color-sub'}>
                      {dtFormat(mmbrshp.fnlModDttm, 'time')}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>

      {reject && (
        <div className={'detail-content p-l-15'}>
          <div className={'flex justify-between m-b-10'}>
            <span>{t('membership.writereject')}</span>
            <span
              className={'color-b295 cursor-pointer'}
              onClick={() => setModify(!modify)}>
              {modify ? t('membership.save') : t('membership.modify')}
            </span>
          </div>
          <div className={modify ? 'text-editor on' : 'text-editor'}>
            <div className={'flex m-b-10'}>
              <div className={'operator-logo'}>
                <ImgComponent src={'mini_leaf.svg'} />
              </div>
              <span className={'m-r-5'}>{t('membership.hoxxi')}</span>
              <span className={'color-sub font-12'}>
                {t('membership.operator')}
              </span>
            </div>
            <textarea
              disabled={!modify}
              value={reason}
              onChange={e => setReason(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Membership;
