import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import VerifiedIcon from '@mui/icons-material/Verified';
import CommentIcon from '@mui/icons-material/Comment';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import React from 'react';

export interface SubMenu {
  key: string;
  title: string;
  path: string;
}

export interface Menu {
  key: string;
  title: string;
  open: boolean;
  icon: React.ElementType;
  subMenu: SubMenu[];
}

export const MENU_WEB: Menu[] = [
  // 회원관리
  {
    key: 'user',
    title: 'sidemenulayout.usermanage',
    open: true,
    icon: PeopleIcon,
    subMenu: [
      {
        key: 'usermanage',
        title: 'usermanageall.tabmenu.user',
        path: '/usermanage/all',
      },
      {
        key: 'invite',
        title: 'usermanageall.tabmenu.invite',
        path: '/usermanage/invite',
      },
    ],
  },
  // 콘텐츠
  {
    key: 'contents',
    title: 'sidemenulayout.contentsv1',
    open: false,
    icon: DescriptionIcon,
    subMenu: [
      {
        key: 'story',
        title: 'contentsstory.tabmenu.story',
        path: '/contents/story',
      },
      {
        key: 'action',
        title: 'contentsstory.tabmenu.action',
        path: '/v2/action',
      },
      {
        key: 'challenge',
        title: 'contentsstory.tabmenu.challenge',
        path: '/contents/challenge/challenge',
      },
    ],
  },
  // 인증하기
  {
    key: 'certification',
    title: 'sidemenulayout.certify',
    open: true,
    icon: VerifiedIcon,
    subMenu: [
      {
        key: 'action',
        title: 'certify.tabmenu.action',
        path: '/certify/action',
      },
      {
        key: 'challenge',
        title: 'certify.tabmenu.challenge',
        path: '/certify/challenge',
      },
    ],
  },
  // 댓글관리
  {
    key: 'comment',
    title: 'sidemenulayout.comment',
    open: false,
    icon: CommentIcon,
    subMenu: [
      {
        key: 'story',
        title: 'comment.tabmenu.story',
        path: '/comment/story',
      },
      {
        key: 'action',
        title: 'comment.tabmenu.action',
        path: '/comment/action',
      },
      {
        key: 'challenge',
        title: 'comment.tabmenu.challenge',
        path: '/comment/challenge',
      },
    ],
  },
  // 리워드 관리
  {
    key: 'reward',
    title: 'sidemenulayout.reward',
    open: false,
    icon: EnergySavingsLeafIcon,
    subMenu: [
      {
        key: 'all',
        title: 'reward.btn.all',
        path: '/reward/all',
      },
      {
        key: 'reward',
        title: 'reward.btn.cero',
        path: '/reward/cero',
      },
      {
        key: 'userpoint',
        title: 'reward.btn.userpoint',
        path: '/userpointlist',
      },
    ],
  },
  // 서포트
  {
    key: 'support',
    title: 'sidemenulayout.support',
    open: false,
    icon: HelpCenterIcon,
    subMenu: [
      {
        key: 'inquiry',
        title: 'support.tabmenu.inquiry',
        path: '/support/inquiry',
      },
      {
        key: 'faq',
        title: 'support.tabmenu.faq',
        path: '/support/faq',
      },
      {
        key: 'report',
        title: 'support.tabmenu.report',
        path: '/support/report',
      },
    ],
  },
  //운영관리
  {
    key: 'setting',
    title: 'sidemenulayout.setting',
    open: false,
    icon: BuildCircleIcon,
    subMenu: [
      {
        key: 'notice',
        title: 'setting.tabmenu.notice',
        path: '/setting/notice',
      },
      {
        key: 'popup',
        title: 'setting.tabmenu.popup',
        path: '/setting/popup',
      },
      {
        key: 'alarm',
        title: 'setting.tabmenu.alarm',
        path: '/setting/alarm',
      },
    ],
  },
  //스토어
  {
    key: 'store',
    title: 'sidemenulayout.store',
    open: false,
    icon: ShoppingCartIcon,
    subMenu: [
      {
        key: 'goods',
        title: 'store.tabmenu.product',
        path: '/store/goods',
      },
      {
        key: 'vendor',
        title: 'store.tabmenu.vendor',
        path: '/store/vendor',
      },
      {
        key: 'order',
        title: 'store.tabmenu.order',
        path: '/store/order',
      },
    ],
  },
  // 통계
  {
    key: 'statistics',
    title: 'sidemenulayout.statistics',
    open: false,
    icon: AnalyticsIcon,
    subMenu: [
      {
        key: 'statistics',
        title: 'statisticssearch.main.title',
        path: '/statistics',
      },
    ],
  },
];

export const MENU_MOBILE: Menu[] = [
  // 콘텐츠
  {
    key: 'contents',
    title: 'sidemenulayout.contentsv1',
    open: true,
    icon: DescriptionIcon,
    subMenu: [
      {
        key: 'story',
        title: 'contentsstory.tabmenu.story',
        path: '/m/contents/story',
      },
      {
        key: 'action',
        title: 'contentsstory.tabmenu.action',
        path: '/m/contents/action',
      },
      {
        key: 'challenge',
        title: 'contentsstory.tabmenu.challenge',
        path: '/m/contents/challenge',
      },
    ],
  },
  // 인증하기
  {
    key: 'certification',
    title: 'sidemenulayout.certify',
    open: true,
    icon: VerifiedIcon,
    subMenu: [
      {
        key: 'action',
        title: 'certify.tabmenu.action',
        path: '/m/certify/action',
      },
      {
        key: 'challenge',
        title: 'certify.tabmenu.challenge',
        path: '/m/certify/challenge',
      },
    ],
  },
];

export const MENU_MALL = [
  //스토어
  {
    key: 'store',
    title: 'sidemenulayout.store',
    open: false,
    icon: ShoppingCartIcon,
    subMenu: [
      {
        key: 'goods',
        title: 'store.tabmenu.product',
        path: '/store/goods',
      },
      {
        key: 'vendor',
        title: 'store.tabmenu.vendor',
        path: '/store/vendor',
      },
      {
        key: 'order',
        title: 'store.tabmenu.order',
        path: '/store/order',
      },
    ],
  },
];
