import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
const prefix = 'login';
const TOKEN = prefix + '/TOKEN';

interface InitialState {
  token: string | null;
  adminAuth: string | null;
  isMobile: boolean | null;
}
const initialState: InitialState = {
  token: null,
  adminAuth: null,
  isMobile: false,
};

const login = createSlice({
  name: prefix,
  initialState,
  reducers: {
    doLogin: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
    },
    setAdminAuth: (state, { payload }: PayloadAction<string>) => {
      state.adminAuth = payload;
    },
    setIsMobile: (state, { payload }: PayloadAction<boolean>) => {
      state.isMobile = payload;
    },
  },
});

export default login.reducer;
export const { doLogin, setAdminAuth, setIsMobile } = login.actions;
