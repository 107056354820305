import { addComma } from '../../utils/commonFunc';
import React from 'react';

type Props = {
  className: string | null | undefined;
  onClick: () => void;
  circle?: string;
  txt: string;
  count: number;
};
const CountBtnComponent = ({
  count,
  txt,
  onClick,
  className,
  circle,
}: Props) => {
  return (
    <p className={'btn btn-none m-r-10 ' + className} onClick={onClick}>
      {circle && <span className={'circle m-r-5 m-t-2 ' + circle} />}
      {txt}
      <span className="m-l-5 m-r-5">|</span>
      <span className="color-b295">{addComma(count)}</span>
    </p>
  );
};

export default CountBtnComponent;
