import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources: Resource = {};

type RequireContext = {
  (id: string): any;
  keys(): string[];
  resolve(id: string): string;
};

// 언어 파일이 추가될 때마다 하지 말고 자동으로 추가하게끔 만들기 위한 유틸리티 함수
const importAll = (r: RequireContext) => {
  r.keys().forEach((key: string) => {
    const langCode = key.replace('./', '').replace('.json', '');
    resources[langCode] = { translation: r(key) };
  });
};

importAll(require.context('../langs/', false, /\.json$/));

// 브라우저 언어를 기본 언어로 설정
const browserLanguage = navigator.language.split('-')[0];
const savedLanguage = localStorage.getItem('language');

i18n.use(initReactI18next).init({
  resources,
  debug: false,
  lng: savedLanguage || browserLanguage,
  fallbackLng: 'en',
  interpolation: { escapeValue: true },
  returnObjects: true,
  returnEmptyString: true,
  returnNull: true,
});

export default i18n;
