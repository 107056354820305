import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../utils/createReqSaga';
import { axiosGet } from '../../layout/AppLayout';
import codeData from '../../data/codeData.json';
import {
  ActionListParamType,
  CateActionListParamType,
  RecActionListParamType,
} from '../../types/action/actionParamType';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ActionCountType,
  ActionListType,
  ActionType,
  CategoryListType,
  CategoryType,
  RecActionListType,
  RecActionType,
} from '../../types/action/actionReduxType';

const prefix = 'action';
const GET_CNT = prefix + '/GET_CNT';

const GET_ACTION_LIST = prefix + '/GET_ACTION_LIST';
const GET_CATE_ACTION_LIST = prefix + '/GET_CATE_ACTION_LIST';
const GET_REC_ACTION_LIST = prefix + '/GET_REC_ACTION_LIST';

const GET_ACTION = prefix + '/GET_ACTION';
const GET_CATE_ACTION = prefix + '/GET_CATE_ACTION';
const GET_REC_ACTION = prefix + '/GET_REC_ACTION';

const UPDATE_SEARCH = prefix + 'action/UPDATE_SEARCH';

export const getActionCnt = createAction(GET_CNT);

export const getActionList = createAction<ActionListParamType>(GET_ACTION_LIST);
export const getCateActionList =
  createAction<CateActionListParamType>(GET_CATE_ACTION_LIST);
export const getRecActionList =
  createAction<RecActionListParamType>(GET_REC_ACTION_LIST);

export const getAction = createAction<number>(GET_ACTION);
export const getCateAction = createAction<number>(GET_CATE_ACTION);
export const getRecAction = createAction<number>(GET_REC_ACTION);

export const updateSearch = createAction<string>(UPDATE_SEARCH);

//Api
const getActionCntApi = () => axiosGet('/action/count');

const getActionListApi = (sendData: ActionListParamType) =>
  axiosGet('/action/list', sendData);
const getCateActionListApi = (sendData: CateActionListParamType) =>
  axiosGet('/action/cate/list', sendData);
const getRecActionListApi = (sendData: RecActionListParamType) =>
  axiosGet('/action/recom/list', sendData);

const getActionApi = (sendData: number) => axiosGet('/action/' + sendData);
const getCateActionApi = (sendData: number) =>
  axiosGet('/action/cate/' + sendData);
const getRecActionApi = (sendData: number) =>
  axiosGet('/action/recom/' + sendData);

const getActionCntSaga = createRequestSaga(GET_CNT, getActionCntApi);

const getActionListSaga = createRequestSaga(GET_ACTION_LIST, getActionListApi);
const getCateActionListSaga = createRequestSaga(
  GET_CATE_ACTION_LIST,
  getCateActionListApi,
);
const getRecActionListSaga = createRequestSaga(
  GET_REC_ACTION_LIST,
  getRecActionListApi,
);

const getactionaga = createRequestSaga(GET_ACTION, getActionApi);
const getCateactionaga = createRequestSaga(GET_CATE_ACTION, getCateActionApi);
const getRecactionaga = createRequestSaga(GET_REC_ACTION, getRecActionApi);

export function* actionSaga() {
  yield takeLatest(GET_CNT, getActionCntSaga);

  yield takeLatest(GET_ACTION_LIST, getActionListSaga);
  yield takeLatest(GET_CATE_ACTION_LIST, getCateActionListSaga);
  yield takeLatest(GET_REC_ACTION_LIST, getRecActionListSaga);

  yield takeLatest(GET_ACTION, getactionaga);
  yield takeLatest(GET_CATE_ACTION, getCateactionaga);
  yield takeLatest(GET_REC_ACTION, getRecactionaga);
}

interface InitialStateType {
  count: ActionCountType;
  cateList: CategoryListType;
  actionList: ActionListType;
  recList: RecActionListType;
  action: ActionType;
  cateAction: CategoryType;
  recAction: RecActionType;
  search: { state: string };
}

const initialState: InitialStateType = {
  count: {
    action: {
      all: 0,
      use: 0,
      notUse: 0,
    },
    recommend: {
      all: 0,
      use: 0,
      notUse: 0,
    },
    category: {
      all: 0,
      use: 0,
      notUse: 0,
    },
  },
  cateList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  actionList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  recList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  action: {
    mssnId: 0,
    cateId: 0,
    cateTitle: '',
    prpsCd: 'ENERGY',
    prpsCdDesc: '',
    placeCd: 'FAMILY',
    placeCdDesc: '',
    imgUrl: '',
    mssnTitle: '',
    mssnTitleDtl: '',
    point: 0,
    exp: 0,
    mssnEtc: '',
    mssnEffect: '',
    useYn: 'N',
    recAction: [],
    langCd: 'KO',
  },
  cateAction: {
    cateId: 0,
    cateTitle: '',
    cateContents: '',
    imgUrl: '',
    prpsCd: 'ENERGY',
    placeCd: 'FAMILY',
    useYn: 'N',
  },
  recAction: {
    recomId: 0,
    imgUrl: '',
    recomTitle: '',
    recomContents: '',
    useYn: 'N',
    actionList: [],
  },
  search: { state: 'all' },
};

const action = createSlice({
  name: prefix,
  initialState,
  reducers: {
    updateSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = { ...state.search, state: payload };
    },
    setActionInit: state => {
      state.action = initialState.action;
    },
    setCateInit: state => {
      state.cateAction = initialState.cateAction;
    },
    setRecInit: state => {
      state.recAction = initialState.recAction;
    },
  },
  extraReducers: {
    [GET_CNT + '_SUC']: (
      state,
      { payload }: PayloadAction<ActionCountType>,
    ) => {
      state.count = payload;
    },
    [GET_ACTION_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<ActionListType>,
    ) => {
      state.actionList = payload;
    },
    [GET_CATE_ACTION_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<CategoryListType>,
    ) => {
      state.cateList = payload;
    },
    [GET_REC_ACTION_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<RecActionListType>,
    ) => {
      state.recList = payload;
    },
    [GET_ACTION + '_SUC']: (state, { payload }: PayloadAction<ActionType>) => {
      state.action = payload;
    },
    [GET_CATE_ACTION + '_SUC']: (
      state,
      { payload }: PayloadAction<CategoryType>,
    ) => {
      state.cateAction = payload;
    },
    [GET_REC_ACTION + '_SUC']: (
      state,
      { payload }: PayloadAction<RecActionType>,
    ) => {
      state.recAction = payload;
    },
  },
});

export default action.reducer;
export const { setActionInit, setCateInit, setRecInit } = action.actions;
