import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosGet } from '../../layout/AppLayout';
import createRequestSaga from '../../utils/createReqSaga';
import { takeLatest } from 'redux-saga/effects';
import { ReportListType } from '../../types/report/reportReduxType';
import { ReportListParamType } from '../../types/report/reportParamType';

const prefix = 'report';

const GET_LIST = prefix + '/GET_LIST';

export const getReportList = createAction<ReportListParamType>(GET_LIST);

const getReportListApi = (sendData: ReportListParamType) =>
  axiosGet('/report/list', sendData);

const getReportListSaga = createRequestSaga(GET_LIST, getReportListApi);

export function* reportSaga() {
  yield takeLatest(GET_LIST, getReportListSaga);
}

interface InitialStateType {
  reportList: ReportListType;
}

const initialState: InitialStateType = {
  reportList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
};

const report = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: {
    [GET_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<ReportListType>,
    ) => {
      state.reportList = payload;
    },
  },
});

export default report.reducer;
export const {} = report.actions;
