import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useRef } from 'react';
import DatePickerComponent from './DatePickerComponent';

type Props = {
  // value: any;
  placeholder: string;
  // onChange: (value: any) => void;
  onSearch: () => void;
  type?: 'INPUT' | 'DATE';
  normalValue?: {
    value: string;
    onChange: (value: string) => void;
  };
  dateValue?: {
    startDt: string;
    endDt: string;
    onChangeStartDt: (value: string) => void;
    onChangeEndDt: (value: string) => void;
  };
};
const SearchFormComponent = ({
  onSearch,
  placeholder,
  normalValue,
  dateValue,
  type,
}: Props) => {
  return (
    <div className={'search'}>
      {type === 'INPUT' ? (
        <input
          value={normalValue?.value}
          placeholder={placeholder}
          onChange={e => normalValue?.onChange(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onSearch();
            }
          }}
          autoComplete={'one-time-code'}
        />
      ) : (
        <div className={'flex'}>
          <DatePickerComponent
            format={'yyyy.MM.dd'}
            value={dateValue?.startDt}
            onChange={e => dateValue?.onChangeStartDt(e)}
            boxClass={'flex search-input m-t-0'}
            placeholder={'시작일'}
          />
          ~
          <DatePickerComponent
            format={'yyyy.MM.dd'}
            value={dateValue?.endDt}
            minDate={dateValue?.startDt}
            onChange={e => dateValue?.onChangeEndDt(e)}
            boxClass={'flex search-input m-t-0'}
            placeholder={'종료일'}
          />
        </div>
      )}
      <FontAwesomeIcon
        icon={faSearch}
        style={{ cursor: 'pointer' }}
        onClick={onSearch}
      />
    </div>
  );
};

SearchFormComponent.defaultProps = {
  type: 'INPUT',
};

export default SearchFormComponent;
