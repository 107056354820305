import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../utils/createReqSaga';
import { axiosGet } from '../../layout/AppLayout';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

const prefix = 'notice';

const GET_CNT = prefix + '/GET_CNT';
const GET_LIST = prefix + '/GET_LIST';

export const getNoticeCnt = createAction<any>(GET_CNT);
export const getNoticeList = createAction<any>(GET_LIST);

const getNoticeCntApi = (sendData: any) => axiosGet('/notice/count', sendData);
const getNoticeListApi = (sendData: any) => axiosGet('/notice/list', sendData);

const getNoticeCntSaga = createRequestSaga(GET_CNT, getNoticeCntApi);
const getNoticeListSaga = createRequestSaga(GET_LIST, getNoticeListApi);

export function* noticeSaga() {
  yield takeLatest(GET_CNT, getNoticeCntSaga);
  yield takeLatest(GET_LIST, getNoticeListSaga);
}

const initialState = {
  count: { all: 0, show: 0, hide: 0 },
  list: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
};

const notice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: {
    [GET_CNT + '_SUC']: (state, action: PayloadAction<any>) => {
      state.count = action.payload;
    },
    [GET_LIST + '_SUC']: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
  },
});

// const notice = handleActions(
//   {
//     [GET_CNT_SUC]: (state, action) => ({
//       ...state,
//       count: action.payload,
//     }),
//     [GET_LIST_SUC]: (state, action) => ({
//       ...state,
//       list: action.payload,
//     }),
//   },
//   initialState,
// );

export default notice.reducer;
