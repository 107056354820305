import React, { useState, memo } from 'react';
import i18n from '../../utils/i18nUtil';
import { useTranslation } from 'react-i18next';
import { Resource } from 'i18next';

const SelectLanguage = () => {
  const [language, setLanguage] = useState(i18n.language);
  const { t } = useTranslation();
  const supportedLanguages: string[] = Object.keys(
    i18n.options.resources as Resource,
  );

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <select
      className={'h-32 w-90'}
      value={language}
      onChange={handleLanguageChange}>
      {supportedLanguages.map(langCode => (
        <option key={langCode} value={langCode}>
          {t('language', { lng: langCode })}
        </option>
      ))}
    </select>
  );
};

export default memo(SelectLanguage);
