import { OptionsType } from '@@types/common';

export const userSearchOptions: { value: string; label: string }[] = [
  { value: 'nickname', label: 'usercommontype.select.user.nickname' },
  { value: 'creator', label: 'usercommontype.select.user.creator' },
  { value: 'email', label: 'usercommontype.select.user.email' },
];

export const orgSearchOptions: { value: string; label: string }[] = [
  { value: 'nickname', label: 'usercommontype.select.org.orgNm' },
  { value: 'email', label: 'usercommontype.select.org.email' },
  { value: 'mngNm', label: 'usercommontype.select.org.mngNm' },
  { value: 'mngEmail', label: 'usercommontype.select.org.mngEmail' },
];
export const inviteSearchOptions: { value: string; label: string }[] = [
  { value: 'MMBR', label: 'usercommontype.select.invite.mmbr' },
  { value: 'IVTMMBR', label: 'usercommontype.select.invite.ivtmmbr' },
  { value: 'REGTERM', label: 'usercommontype.select.invite.regterm' },
];

export const organCateCdOptions: OptionsType<string>[] = [
  { value: 'ORGANIZATION', label: 'code.organcatecd.organization' },
  { value: 'SCHOOL', label: 'code.organcatecd.school' },
  { value: 'CLUB', label: 'code.organcatecd.club' },
];
