import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';
import AppLayout from './layout/AppLayout';
import Support from './pages/support/Support';
import React, { useMemo } from 'react';
import './utils/i18nUtil';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '@modules/index';

/* 다국어 Lib */

const Login = loadable(() => import('./pages/login/Login'));
const Dashboard = loadable(() => import('./pages/dashboard/Dashboard'));
const UserManageAll = loadable(
  () => import('./pages/userManage/UserManageAll'),
);
const UserListPointAll = loadable(
  () => import('./pages/reward/UserPointCameManage/UserListPointAll'),
);
const ContentsStory = loadable(() => import('./pages/contents/ContentsStory'));
const Certify = loadable(() => import('./pages/certify/Certify'));
const Comment = loadable(() => import('./pages/comment/Comment'));
const Notice = loadable(() => import('./pages/setting/Notice'));
const Alarm = loadable(() => import('./pages/setting/Alarm'));

const Reward = loadable(() => import('./pages/reward/Reward'));
const CategoryAction = loadable(
  () => import('./pages/contents/v1/action/CategoryAction'),
);
const Action = loadable(() => import('./pages/contents/v1/action/Action'));
const RecAction = loadable(
  () => import('./pages/contents/v1/action/RecAction'),
);
const Challenge = loadable(
  () => import('./pages/contents/v1/challenge/Challenge'),
);
const ChallengeGroup = loadable(
  () => import('./pages/contents/v1/challenge/ChallengeGroup'),
);
const Popup = loadable(() => import('./pages/setting/Popup'));
const Store = loadable(() => import('./pages/store/Store'));
const Statistics = loadable(() => import('./pages/statistics/Statistics'));
const Contents = loadable(() => import('./pages/contents/v2/Contents'));
const Sample = loadable(() => import('./mobilepages/sample/Sample'));

const MobileDashboard = loadable(
  () => import('./mobilepages/dashboard/MobileDashboard'),
);
const MobileCertify = loadable(
  () => import('./mobilepages/certify/MobileCertify'),
);
const MobileBehaviorManage = loadable(
  () => import('./mobilepages/action/MobileBehaviorManage'),
);
const MobileChallengeManage = loadable(
  () => import('./mobilepages/challenge/MobileChallengeManage'),
);
const MobileStoryManage = loadable(
  () => import('./mobilepages/story/MobileStoryManage'),
);
declare global {
  interface Window {
    tempProperty: string;
  }
}

function App() {
  const { token, adminAuth, isMobile } = useSelector(
    ({ login }: RootState) => login,
    shallowEqual,
  );

  // 로그인 체크.
  const isLogin = useMemo(() => {
    return token && adminAuth;
  }, [token, adminAuth]);

  let routes = null;
  if (!isLogin) {
    routes = (
      <>
        <Route path={'*'} element={<Navigate replace={true} to={'/login'} />} />
        <Route path={'/login'} element={<Login />} />
      </>
    );
  } else if (adminAuth === 'ROLE_MALL') {
    routes = (
      <>
        <Route
          path={'*'}
          element={<Navigate replace={true} to={'/store/goods'} />}
        />
        <Route path="/store/goods" element={<Store />} />
        <Route path="/store/vendor" element={<Store />} />
        <Route path="/store/order" element={<Store />} />
      </>
    );
  } else {
    routes = (
      <>
        <Route
          path={'*'}
          element={
            <Navigate
              replace={true}
              to={isMobile ? '/m/dashboard' : '/usermanage/all'}
            />
          }
        />
        {/* 대시보드 */}
        {/*<Route path={'/dashboard'} element={<Dashboard />} />*/}
        {/* 회원관리 */}
        <Route
          path="/usermanage/all"
          element={<UserManageAll path={'USER'} />}
        />
        <Route
          path="/usermanage/invite"
          element={<UserManageAll path={'INVITE'} />}
        />
        <Route path="/userpointlist" element={<UserListPointAll />} />
        {/* 콘텐츠 */}
        <Route path="/contents/story" element={<ContentsStory />} />
        <Route path="/contents/action/category" element={<CategoryAction />} />
        <Route path="/contents/action/action" element={<Action />} />
        <Route path="/contents/action/rec" element={<RecAction />} />
        <Route path={'/contents/challenge/challenge'} element={<Challenge />} />
        <Route
          path={'/contents/challenge/group'}
          element={<ChallengeGroup />}
        />
        {/* 인증하기 */}
        <Route path="/certify/action" element={<Certify />} />
        <Route path="/certify/challenge" element={<Certify />} />
        {/* 댓글관리 */}
        <Route path="/comment/story" element={<Comment />} />
        <Route path="/comment/action" element={<Comment />} />
        <Route path="/comment/challenge" element={<Comment />} />
        {/* 리워드관리 */}
        <Route path={'/reward/all'} element={<Reward />} />
        <Route path={'/reward/cero'} element={<Reward />} />
        {/* 서포트 */}
        <Route path="/support/inquiry" element={<Support />} />
        <Route path="/support/faq" element={<Support />} />
        <Route path="/support/report" element={<Support />} />
        {/* 운영관리 */}
        <Route path="/setting/notice" element={<Notice />} />
        <Route path="/setting/popup" element={<Popup />} />
        <Route path="/setting/alarm" element={<Alarm />} />
        {/* 스토어 */}
        <Route path="/store/goods" element={<Store />} />
        <Route path="/store/vendor" element={<Store />} />
        <Route path="/store/order" element={<Store />} />
        {/* 통계 */}
        <Route path="/statistics" element={<Statistics />} />{' '}
        <Route path="/v2/action" element={<Contents />} />{' '}
        <Route path="/v2/action/grp" element={<Contents />} />{' '}
        <Route path="/v2/action/recom" element={<Contents />} />
        {/*
        //// 모바일 ////
        */}
        {/* Sample */}
        <Route path="/m/sample" element={<Sample />} />
        {/* DashBoard */}
        <Route path="/m/dashboard" element={<MobileDashboard />} />
        {/* Certify */}
        <Route path="/m/certify/action" element={<MobileCertify />} />
        <Route path="/m/certify/challenge" element={<MobileCertify />} />
        {/* Action */}
        <Route path="/m/contents/action" element={<MobileBehaviorManage />} />
        {/* Challenge */}
        <Route
          path={'/m/contents/challenge'}
          element={<MobileChallengeManage />}
        />
        <Route path={'/m/contents/story'} element={<MobileStoryManage />} />
      </>
    );
  }

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
      <AppLayout>
        <Routes>{routes}</Routes>
      </AppLayout>
    </BrowserRouter>
  );
}

export default App;
