import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosGet } from '../../layout/AppLayout';
import {
  ChallengeGrpListParamType,
  ChallengeListParamType,
} from '../../types/challenge/challengeParamType';
import createRequestSaga from '../../utils/createReqSaga';
import { takeLatest } from 'redux-saga/effects';
import {
  challengeInit,
  ChallengeListPagingType,
  ChallengeType,
  GuideType,
} from '../../types/challenge/challengeReduxType';
import { pagingInit } from '../../types/common';

const prefix = 'chlng';

const GET_CHLNG_LIST = prefix + '/GET_CHLNG_LIST';
const GET_CHLNG = prefix + '/GET_CHLNG';
const GET_COUNT = prefix + '/GET_COUNT';

export const getChlngList =
  createAction<ChallengeListParamType>(GET_CHLNG_LIST);
export const getChlng = createAction<number>(GET_CHLNG);
export const getChlngCount = createAction(GET_COUNT);

const getChlngListApi = (sendData: ChallengeListParamType) =>
  axiosGet('/chlng/list', sendData);
const getChlngApi = (id: number) => axiosGet(`/chlng/${id}`);
const getCountApi = () => axiosGet('/chlng/cnt');

const getChallengeListSaga = createRequestSaga(GET_CHLNG_LIST, getChlngListApi);
const getChlngSaga = createRequestSaga(GET_CHLNG, getChlngApi);
const getCountSaga = createRequestSaga(GET_COUNT, getCountApi);

export function* chlngSaga() {
  yield takeLatest(GET_CHLNG_LIST, getChallengeListSaga);
  yield takeLatest(GET_CHLNG, getChlngSaga);
  yield takeLatest(GET_COUNT, getCountSaga);
}

interface InitialStateType {
  list: ChallengeListPagingType;
  display: boolean;
  challenge: ChallengeType;
  file: File | null;
  dateNum: number;
  periodNum: number;
  removeGuideList: number[];
  count: {
    all: number;
    ongoing: number;
    waiting: number;
    finish: number;
  };
}

const initialState: InitialStateType = {
  list: pagingInit,
  display: false,
  challenge: challengeInit,
  file: null,
  dateNum: 0,
  periodNum: 0,
  removeGuideList: [],
  count: {
    all: 0,
    finish: 0,
    ongoing: 0,
    waiting: 0,
  },
};

const chlng = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setChlngDisplay: (state, action: PayloadAction<boolean>) => {
      state.display = action.payload;
    },
    setChallenge: (state, { payload }: PayloadAction<ChallengeType>) => {
      state.challenge = payload;
    },
    setChallengeFile: (state, action: PayloadAction<File | null>) => {
      state.file = action.payload;
    },
    setChallengeGuideList: (state, action: PayloadAction<GuideType[]>) => {
      state.challenge.guideList = action.payload;
    },
    setPeriodNum: (state, action: PayloadAction<number>) => {
      state.periodNum = action.payload;
    },
    setDateNum: (state, action: PayloadAction<number>) => {
      state.dateNum = action.payload;
    },
    setChlngRemoveGuideList: (state, action: PayloadAction<number[]>) => {
      state.removeGuideList = action.payload;
    },
  },
  extraReducers: {
    [GET_CHLNG_LIST + '_SUC']: (
      state,
      action: PayloadAction<ChallengeListPagingType>,
    ) => {
      state.list = action.payload;
    },
    [GET_CHLNG + '_SUC']: (
      state,
      { payload }: PayloadAction<ChallengeType>,
    ) => {
      state.challenge = payload;
      if (payload.particptnTpCd == 'DATE') {
        state.dateNum = payload.particptnNum;
      } else if (payload.particptnTpCd == 'PERIOD') {
        state.periodNum = payload.particptnNum;
      }
    },
    [GET_COUNT + '_SUC']: (
      state,
      action: PayloadAction<typeof initialState.count>,
    ) => {
      state.count = action.payload;
    },
  },
});

export default chlng.reducer;
export const {
  setChlngDisplay,
  setChallengeFile,
  setChallenge,
  setChallengeGuideList,
  setDateNum,
  setPeriodNum,
  setChlngRemoveGuideList,
} = chlng.actions;
