import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../utils/createReqSaga';
import { axiosGet } from '../../layout/AppLayout';
import {
  InquiryListParamType,
  PersonalListParamType,
} from '../../types/inquiry/inquiryParamType';
import {
  InquiryCountType,
  InquiryListType,
  PersonalListType,
  PersonalType,
} from '../../types/inquiry/inquiryReduxType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const prefix = 'inquiry';

const GET_PERSONAL_LIST = prefix + '/GET_PERSONAL_LIST';
const GET_INQUIRY_LIST = prefix + '/GET_INQUIRY_LIST';

const GET_INQUIRY_COUNT = prefix + '/GET_INQUIRY_COUNT';

const UPDATE_INQUIRY_BY_ID = 'inquiry/UPDATE_INQUIRY_BY_ID';

export const getPersonalList =
  createAction<PersonalListParamType>(GET_PERSONAL_LIST);
export const getInquiryList =
  createAction<InquiryListParamType>(GET_INQUIRY_LIST);
export const getInquiryCount = createAction(GET_INQUIRY_COUNT);

const getPersonalListApi = (sendData: PersonalListParamType) =>
  axiosGet('/support/inquiry/list/personal', sendData);

const getInquiryListApi = (sendData: InquiryListParamType) =>
  axiosGet('/support/inquiry/list', sendData);

const getInquiryCountApi = () => axiosGet('/support/inquiry/count');

const getPersonalListSaga = createRequestSaga(
  GET_PERSONAL_LIST,
  getPersonalListApi,
);

const getInquiryListSaga = createRequestSaga(
  GET_INQUIRY_LIST,
  getInquiryListApi,
);

const getInquiryCountSaga = createRequestSaga(
  GET_INQUIRY_COUNT,
  getInquiryCountApi,
);

export function* inquirySaga() {
  yield takeLatest(GET_PERSONAL_LIST, getPersonalListSaga);
  yield takeLatest(GET_INQUIRY_LIST, getInquiryListSaga);
  yield takeLatest(GET_INQUIRY_COUNT, getInquiryCountSaga);
}

interface InitialStateType {
  personalList: PersonalListType;
  inquiryList: InquiryListType;
  count: InquiryCountType;
  personalPageList: PersonalType[];
}

const initialState: InitialStateType = {
  personalList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  inquiryList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  count: {
    all: 0,
    regCnt: 0,
    ansCnt: 0,
    waitingCnt: 0,
  },
  personalPageList: [],
};

const inquiry = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setPersonalPageList: (state, action: PayloadAction<PersonalType[]>) => {
      state.personalPageList = action.payload;
    },
    updateInquiryById: (
      state,
      {
        payload,
      }: PayloadAction<{ inquiryId: number; ansr: string; ansrId: number }>,
    ) => {
      console.log(payload);
      state.personalList = {
        ...state.personalList,
        content: state.personalList.content.map(item =>
          item.inquiryId === payload.inquiryId
            ? {
                ...item,
                ansrId: payload.ansrId,
                ansr: payload.ansr,
                ansrStatusCd: 'WAITING',
                ansrRegDt:
                  item.ansrStatusCd === 'REGISTER'
                    ? String(new Date())
                    : item.ansrRegDt,
                ansrStatusCdDesc: '미확인',
              }
            : item,
        ),
      };
      state.inquiryList = {
        ...state.inquiryList,
        content: state.inquiryList.content.map(item =>
          item.inquiryId === payload.inquiryId
            ? {
                ...item,
                ansrId: payload.ansrId,
                ansr: payload.ansr,
                ansrStatusCd: 'WAITING',
                ansrRegDt:
                  item.ansrStatusCd === 'REGISTER'
                    ? String(new Date())
                    : item.ansrRegDt,
                ansrStatusCdName: '미확인',
              }
            : item,
        ),
      };
    },
  },
  extraReducers: {
    [GET_INQUIRY_COUNT + '_SUC']: (state, action: PayloadAction<any>) => {
      state.count = action.payload;
    },
    [GET_INQUIRY_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<InquiryListType>,
    ) => {
      state.inquiryList = payload;
    },
    [GET_PERSONAL_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<PersonalListType>,
    ) => {
      state.personalList = payload;
      state.personalPageList =
        payload.number == 0
          ? payload.content
          : state.personalPageList.concat(payload.content);
    },
  },
});

export default inquiry.reducer;
export const { updateInquiryById, setPersonalPageList } = inquiry.actions;
