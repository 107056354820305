import React, { useState } from 'react';
import {
  dtFormat,
  fileDownload,
  openImageInNewTab,
} from '../../utils/commonFunc';
import CircleProfileImgComponent from '../common/CircleProfileImgComponent';
import BtnComponent from '../common/BtnComponent';
import { axiosPost } from '../../layout/AppLayout';
import { useDispatch } from 'react-redux';
import { updateInquiryById } from '../../modules/support/inquiry';
import ImgComponent from '../common/ImgComponent';
import {
  AnsrStatusCdType,
  InquiryFilesType,
} from '../../types/inquiry/inquiryReduxType';
import { useTranslation } from 'react-i18next';

type Props = {
  ansrStatusCd: AnsrStatusCdType;
  ansrStatusCdName: string;
  regDt: string;
  title: string;
  ctnt: string;
  files: InquiryFilesType[];
  inquiryId: number;
  profileImg?: string;
  nickname: string;
  ansrRegDt: string;
  ansr: string;
  ansrId: number;
};
const InquiryBoxComponent = ({
  ansrStatusCd,
  ansrStatusCdName,
  regDt,
  title,
  ctnt,
  files,
  inquiryId,
  profileImg,
  nickname,
  ansrRegDt,
  ansr,
  ansrId,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isWriteMode, setWriteMode] = useState(false);
  const [text, setText] = useState('');

  const registerAnswer = () => {
    axiosPost('/support/inquiry/answer', {
      ansr: text,
      inquiryId,
    })
      .then(res => {
        console.log(res);
        if (res.resultCd === '0000') {
          dispatch(
            updateInquiryById({ inquiryId, ansr: text, ansrId: res.result }),
          );
          setWriteMode(false);
        }
      })
      .catch(e => console.log(e));
  };

  const updateAnswer = () => {
    axiosPost('/support/inquiry/modify', {
      ansr: text,
      ansrId,
      inquiryId,
    })
      .then(res => {
        if (res.resultCd === '0000') {
          dispatch(updateInquiryById({ inquiryId, ansr: text, ansrId }));
          setWriteMode(false);
        }
        console.log(res);
      })
      .catch(e => console.log(e));
  };

  const onClickAnswerBtn = () => {
    if (isWriteMode) {
      if (ansrStatusCd === 'REGISTER') {
        registerAnswer();
      } else if (ansrStatusCd === 'WAITING') {
        updateAnswer();
      }
    } else {
      setWriteMode(true);
      if (ansrStatusCd === 'WAITING') setText(ansr);
    }
  };

  return (
    <div className={'inquiry-div m-t-5'}>
      <div className={'m-l-5'}>
        <div>
          <div className={'flex justify-between h-22'}>
            <div className={'flex'}>
              <h5 className={'color-main'}>{ansrStatusCdName}</h5>
              <h5 className={'m-l-3 color-sub'}>&middot; {dtFormat(regDt)}</h5>
            </div>
          </div>
          <p className={'color-main m-t-10'}>{title}</p>
          <p className={'m-t-10'}>{ctnt}</p>
          <div>
            {files.length > 0 && (
              <p className={'color-main m-t-10 m-b-5'}>Attachment</p>
            )}
            {files.map(item => (
              <img
                key={item.fileSeq}
                src={item.fileUrl}
                width="70"
                className={'m-r-5 cursor-pointer'}
                alt={item.fileName}
                onClick={() =>
                  openImageInNewTab(
                    files.map(item => item.fileUrl.replace('W900', '')),
                    1200,
                    760,
                  )
                }
              />
            ))}
          </div>
        </div>
        <div className={'answer-box m-t-15 '}>
          {ansrStatusCd === 'REGISTER' ? (
            <>
              {isWriteMode && (
                <textarea
                  onChange={e => setText(e.target.value)}
                  placeholder={t(
                    'inquiryboxcomponent.answerbox.placeholdermain',
                  )}
                  value={text}
                />
              )}
            </>
          ) : (
            <>
              {isWriteMode ? (
                <textarea
                  onChange={e => setText(e.target.value)}
                  placeholder={t(
                    'inquiryboxcomponent.answerbox.placeholdersub',
                  )}
                  value={text}
                />
              ) : (
                <div className={'answer-div'}>
                  <div className={'flex m-b-10'}>
                    <CircleProfileImgComponent
                      src={'https://source.unsplash.com/random'}
                    />
                    <div className={'flex justify-between h-22 m-l-10'}>
                      <div className={'flex'}>
                        <h5 className={'font-14'}>{nickname}</h5>
                        <h5 className={'m-l-3 color-sub'}>
                          &middot; {dtFormat(ansrRegDt)}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <p>{ansr}</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {(ansrStatusCd === 'WAITING' || ansrStatusCd === 'REGISTER') && (
        <div className={'btn-wrap m-t-15'}>
          {isWriteMode && (
            <BtnComponent
              className={'w-90 btn-sub'}
              txt={t('inquiryboxcomponent.subbtn.cancel')}
              onClick={() => setWriteMode(false)}
            />
          )}
          <BtnComponent
            className={'w-90 btn-main'}
            txt={
              ansrStatusCd === 'REGISTER'
                ? t('inquiryboxcomponent.mainbtn.ans')
                : t('inquiryboxcomponent.mainbtn.mod')
            }
            onClick={onClickAnswerBtn}
          />
        </div>
      )}
    </div>
  );
};

export default InquiryBoxComponent;
