import React, { Component, useEffect } from 'react';
import {
  getInviteList,
  getPersonalInviteList,
} from '../../../modules/user/invite';
import DataGridComponent from '../../../components/common/DataGridComponent';
import { dtFormat } from '../../../utils/commonFunc';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../modules';
import { useTranslation } from 'react-i18next';

type Props = {
  id: number;
};
const Invite = ({ id }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { personal } = useSelector(({ invite }: RootState) => invite);
  useEffect(() => {
    dispatch(getPersonalInviteList({ id, size: 10, page: 0 }));
  }, [id]);
  const columns = [
    {
      field: 'ivtCtfctHisId',
      headerName: t('invite.datagrid.ivtctfcthisid'),
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ivtCtfctNickname',
      headerName: t('invite.datagrid.ivtctfctnickname'),
      flex: 1,
    },
    {
      field: 'ivtCtfctEmail',
      headerName: t('invite.datagrid.ivtctfctemail'),
      flex: 1,
    },
    {
      field: 'ivtCtfctReward',
      headerName: t('invite.datagrid.ivtctfctreward'),
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ivtCtfctRegDttm',
      headerName: t('invite.datagrid.ivtctfctregdttm'),
      headerAlign: 'center',
      width: 150,
      align: 'center',
      cellClassName: 'color-sub',
      renderCell: (params: any) => {
        return <p>{dtFormat(params.row.ivtCtfctRegDttm)}</p>;
      },
    },
    {
      field: 'ivtCtfctDttm',
      headerName: t('invite.datagrid.ivtctfctdttm'),
      headerAlign: 'center',
      width: 150,
      align: 'center',
      cellClassName: 'color-sub',
      renderCell: (params: any) => {
        return <p>{dtFormat(params.row.ivtCtfctDttm)}</p>;
      },
    },
  ];
  return (
    <div className={'flex'}>
      <div className={'w-100h h-625 y-scroll'}>
        <DataGridComponent
          getRowId={'ivtCtfctHisId'}
          columns={columns}
          data={personal.content}
          page={personal.number}
          totalPage={personal.totalPages}
          size={personal.size}
          onChangePerRow={size => {
            dispatch(getPersonalInviteList({ id, page: 0, size: size }));
          }}
          onChangePage={page => {
            dispatch(
              getPersonalInviteList({ id, page: page, size: personal.size }),
            );
          }}
        />
      </div>
    </div>
  );
};

export default Invite;
