import { GoodsCateCd, GoodsStateCd } from './storeCommonType';
import { PagingTypes, YNType } from '../common';

export interface StoreListType {
  goodsId: number;
  vendorId: number;
  vendorName: string;
  goodsStateCd: GoodsStateCd;
  goodsStateCdNm: string;
  goodsCateCd: GoodsCateCd;
  goodsCateCdNm: string;
  goodsName: string;
  goodsDesc: string;
  point: number;
  saleCnt: number;
  imgUrl: string;
  amount: number;
  useYn: 'Y' | 'N';
  regId: number;
  regDt: string;
  modId: number;
  modDt: string;
  refundAvailYn: string;
}

export interface StoreListApiType extends PagingTypes {
  content: StoreListType[];
}

export interface StoreCntType {
  allCnt: number;
  normalCnt: number;
  stopCnt: number;
  soldOutCnt: number;
}

export interface StoreType {
  goodsId: number;
  vendorId: number;
  vendorName: string;
  goodsStateCd: GoodsStateCd;
  goodsStateCdNm: string;
  goodsCateCd: GoodsCateCd;
  goodsCateCdNm: string;
  goodsName: string;
  goodsDesc: string;
  point: number;
  imgUrl: string;
  goodsContents: string;
  goodsNotice: string;
  goodsInfo: string;
  amount: number;
  viewCnt: number;
  goodsNoticeTitle: string;
  goodsInfoTitle: string;
  refundAvailYn: string;
  useYn: YNType;
}

export const storeInit: StoreType = {
  amount: 0,
  goodsCateCd: 'ETC',
  goodsStateCd: 'NORMAL',
  goodsContents: '',
  goodsDesc: '',
  goodsInfo: '',
  goodsInfoTitle: '',
  goodsName: '',
  goodsNotice: '',
  goodsNoticeTitle: '',
  goodsId: 0,
  imgUrl: '',
  point: 0,
  vendorId: 0,
  viewCnt: 0,
  goodsCateCdNm: '',
  goodsStateCdNm: '',
  vendorName: '',
  useYn: 'N',
  refundAvailYn: 'N',
};
