import React, { useEffect, useState } from 'react';
import BtnComponent from '../../components/common/BtnComponent';
import SwitchComponent from '../../components/common/SwitchComponent';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PopupComponent from '../../components/common/PopupComponent';
import CheckBtnComponent from '../../components/common/CheckBtnComponent';
import Select from '../../components/common/Select';
import { getFaq, getFaqList } from '../../modules/support/faq';
import { RootState } from '../../modules';
import { FaqListType } from '../../types/faq/faqReduxType';
import {
  getFaqMainCateList,
  getFaqSubCateList,
  setSubCateList,
} from '../../modules/support/faqCategory';
import DatePickerComponent from '../../components/common/DatePickerComponent';
import BtnPopupLayout from '../BtnPopupLayout';
import { axiosPost } from '../AppLayout';
import { useTranslation } from 'react-i18next';

type Props = {
  faqId: number;
  onClickClose: () => void;
  display: boolean;
};
const FaqDetailPop = ({ faqId, onClickClose, display }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { faq, faqList } = useSelector(
    ({ faq }: RootState) => faq,
    shallowEqual,
  );
  const { mainCateOptions, subCateOptions } = useSelector(
    ({ faqCate }: RootState) => faqCate,
  );
  const [info, setInfo] = useState<FaqListType>({
    faqId: 0,
    pcateId: 0,
    ccateId: 0,
    title: '',
    ctnt: '',
    useYn: 'N',
    alwsExpsrYn: 'Y',
    expsrStartDate: '',
    expsrFnshDate: '',
  });
  const [saveModal, setSaveModal] = useState(false);
  const [errModal, setErrModal] = useState({
    display: false,
    msg: '',
  });

  useEffect(() => {
    if (display) {
      dispatch(getFaqMainCateList({ cateLevel: 1, useYn: 'Y' }));
      if (faqId != 0) {
        dispatch(getFaq(faqId));
      } else {
        setInfo({
          faqId: 0,
          pcateId: 0,
          ccateId: 0,
          title: '',
          ctnt: '',
          useYn: 'N',
          alwsExpsrYn: 'Y',
          expsrStartDate: '',
          expsrFnshDate: '',
        });
      }
    }
  }, [display, faqId]);
  useEffect(() => {
    if (faq) {
      setInfo(faq);
    }
  }, [faq]);
  useEffect(() => {
    if (mainCateOptions.length > 0) {
      setInfo({ ...info, pcateId: mainCateOptions[0].faqCateId });
    }
  }, [mainCateOptions]);
  useEffect(() => {
    if (info.pcateId == 0) {
      dispatch(setSubCateList([]));
    } else {
      dispatch(getFaqSubCateList({ cateLevel: 2, pcateId: info.pcateId }));
    }
  }, [info.pcateId]);
  useEffect(() => {
    if (subCateOptions.length > 0) {
      setInfo({ ...info, ccateId: subCateOptions[0].faqCateId });
    }
  }, [subCateOptions]);

  const formCheck = () => {
    if (info.title === '' || info.ctnt === '') {
      setErrModal({ display: true, msg: t('faqdetailpop.err.title') });
      setSaveModal(false);
    } else if (
      info.alwsExpsrYn === 'N' &&
      (info.expsrStartDate == '' || info.expsrFnshDate == '')
    ) {
      setErrModal({
        display: true,
        msg: t('faqdetailpop.err.period'),
      });
      setSaveModal(false);
    } else {
      if (info.faqId === 0) {
        onSave();
      } else {
        onModify();
      }
    }
  };

  const onSave = () => {
    const sendData = {
      ...info,
      delYn: 'N',
      expsrStartDate:
        info.alwsExpsrYn === 'Y' || info.expsrStartDate == ''
          ? null
          : info.expsrStartDate,
      expsrFnshDate:
        info.alwsExpsrYn === 'Y' || info.expsrFnshDate == ''
          ? null
          : info.expsrFnshDate,
    };

    console.log(sendData);

    axiosPost('/support/faq/new', sendData)
      .then(res => {
        console.log(res);
        if (res.resultCd === '0000') {
          setSaveModal(false);
          dispatch(getFaqList({ page: faqList.number, size: faqList.size }));
          onClose();
        }
      })
      .catch(e => console.log(e));
  };

  const onModify = () => {
    console.log(info);
    const sendData = {
      ...info,
      delYn: 'N',
      expsrStartDate:
        info.alwsExpsrYn === 'Y' || info.expsrStartDate == ''
          ? null
          : info.expsrStartDate,
      expsrFnshDate:
        info.alwsExpsrYn === 'Y' || info.expsrFnshDate == ''
          ? null
          : info.expsrFnshDate,
    };
    axiosPost('/support/faq/modify', sendData)
      .then(res => {
        console.log(res);
        if (res.resultCd === '0000') {
          setSaveModal(false);
          dispatch(getFaqList({ page: faqList.number, size: faqList.size }));
          onClose();
        }
      })
      .catch(e => console.log(e));
  };

  const onClose = () => {
    onClickClose();
  };
  return (
    <PopupComponent display={display} onClose={() => onClose()}>
      <div className={'pop detail-pop'}>
        <div style={{ width: '245px' }}>
          <h3>{t('faqdetailpop.header.title')}</h3>

          <p className={'m-l-5 m-t-15'}>{t('faqdetailpop.tb1.title')}</p>
          <div className={'action-info'}>
            <div className={'flex justify-between'}>
              <p>{t('faqdetailpop.tb1.cate')}</p>
              <p className={'color-main'}>
                {t(
                  'constants.catenm.' +
                    mainCateOptions.filter(
                      item => item.faqCateId === info.pcateId,
                    )[0]?.faqCateId,
                )}
              </p>
            </div>
            <div className={'flex justify-between'}>
              <p>{t('faqdetailpop.tb1.subcate')}</p>
              <p className={'color-main'}>
                {t(
                  'constants.catenm.' +
                    subCateOptions.filter(
                      item => item.faqCateId === info.ccateId,
                    )[0]?.faqCateId,
                )}
              </p>
            </div>
            <div className={'expose'}>
              <div className={'flex'}>
                <div className={'m-r-30 title-div'}>
                  <p className={'color-white'}>
                    {t('faqdetailpop.tb1.expose')}{' '}
                  </p>
                  <h5>{t('faqdetailpop.tb1.expose.txt')}</h5>
                </div>
                <SwitchComponent
                  value={info.useYn === 'Y'}
                  onChange={() =>
                    setInfo({ ...info, useYn: info.useYn === 'Y' ? 'N' : 'Y' })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className={'detail-main'}>
          <div className="h-625 m-b-30">
            <div className={'p-t-40 m-b-20'}>
              <div className={'w-100h flex justify-between'}>
                <p>{t('faqdetailpop.tb2.title')}</p>
              </div>
              <div className={'black-div y-scroll'}>
                <div className={'color-sub p-r-20 w-50h'}>
                  <div className={'flex'}>
                    <div className={'w-50h m-r-5'}>
                      <p>{t('faqdetailpop.tb2.cate')}</p>
                      <Select
                        className={'action-input h-36'}
                        options={mainCateOptions.map(item => ({
                          value: item.faqCateId,
                          label: t('constants.catenm.' + item.faqCateId),
                        }))}
                        value={info.pcateId}
                        onChange={val => setInfo({ ...info, pcateId: val })}
                      />
                    </div>
                    <div className={'w-50h'}>
                      <p>{t('faqdetailpop.tb2.subcate')}</p>
                      <Select
                        className={'action-input h-36'}
                        options={subCateOptions.map(item => ({
                          value: item.faqCateId,
                          label: t('constants.catenm.' + item.faqCateId),
                        }))}
                        value={info.ccateId}
                        onChange={val => setInfo({ ...info, ccateId: val })}
                      />
                    </div>
                  </div>
                  <div className={'m-t-20'}>
                    <p>{t('faqdetailpop.tb2.subtitle')}</p>
                    <input
                      className={'action-input'}
                      onChange={e =>
                        setInfo({ ...info, title: e.target.value })
                      }
                      value={info.title}
                    />
                  </div>
                  <div className={'m-t-20'}>
                    <p>{t('faqdetailpop.tb2.content')}</p>
                    <textarea
                      className={'action-input'}
                      rows={15}
                      onChange={e => setInfo({ ...info, ctnt: e.target.value })}
                      value={info.ctnt}
                    />
                  </div>
                </div>

                <div className={'color-sub p-l-20 w-50h'}>
                  <div className={'flex m-b-15'}>
                    <p className={'m-r-10'}>{t('faqdetailpop.tb2.period')}</p>
                    <CheckBtnComponent
                      className={'check-main'}
                      txt={t('faqdetailpop.tb2.period.none')}
                      value={info.alwsExpsrYn === 'Y'}
                      onClick={() =>
                        setInfo({
                          ...info,
                          alwsExpsrYn: info.alwsExpsrYn === 'Y' ? 'N' : 'Y',
                        })
                      }
                    />
                  </div>
                  <div className={'flex'}>
                    <div>
                      <p className={'w-70 m-b-5'}>
                        {t('faqdetailpop.tb2.period.start')}
                      </p>
                      <DatePickerComponent
                        format={'yyyy.MM.dd'}
                        value={info.expsrStartDate}
                        minDate={new Date()}
                        onChange={e => setInfo({ ...info, expsrStartDate: e })}
                        readOnly={info.alwsExpsrYn === 'Y'}
                        boxClass={'flex action-input m-t-0'}
                        inputClass={info.alwsExpsrYn === 'Y' ? 'disabled' : ''}
                      />
                    </div>
                    <p className={'m-r-10 m-l-10'}>~</p>
                    <div>
                      <p className={'w-70 m-b-5'}>
                        {t('faqdetailpop.tb2.period.start')}
                      </p>
                      <DatePickerComponent
                        format={'yyyy.MM.dd'}
                        value={info.expsrFnshDate}
                        minDate={info.expsrStartDate}
                        onChange={e => setInfo({ ...info, expsrFnshDate: e })}
                        readOnly={info.alwsExpsrYn === 'Y'}
                        boxClass={'flex action-input m-t-0'}
                        inputClass={info.alwsExpsrYn === 'Y' ? 'disabled' : ''}
                      />
                    </div>
                  </div>
                  {/*<div className={'m-t-20'}>*/}
                  {/*  <div>*/}
                  {/*    <p>첨부파일</p>*/}
                  {/*    <div className={'m-t-10'}>*/}
                  {/*      {display && (*/}
                  {/*        <input*/}
                  {/*          type={'file'}*/}
                  {/*          onChange={(e: any) =>*/}
                  {/*            encodeFileToBase64(e.target.files[0], reader => {*/}
                  {/*              setFile(e.target.files[0]);*/}
                  {/*              setFileInfo({*/}
                  {/*                url: e.target.files[0].type.includes('image')*/}
                  {/*                  ? reader.result*/}
                  {/*                  : null,*/}
                  {/*                name: e.target.files[0].name,*/}
                  {/*                size: changeSizeUnit(e.target.files[0].size),*/}
                  {/*              });*/}
                  {/*            })*/}
                  {/*          }*/}
                  {/*        />*/}
                  {/*      )}*/}

                  {/*      <div className={'m-t-10'}>*/}
                  {/*        {fileInfo.name && <h5>{fileInfo.name}</h5>}*/}
                  {/*        {fileInfo.size && (*/}
                  {/*          <h5 className={'m-t-5 m-b-5'}>{fileInfo.size}</h5>*/}
                  {/*        )}*/}
                  {/*        /!*TODO : 2차*!/*/}
                  {/*        /!*{fileInfo.url && (*!/*/}
                  {/*        /!*  <CheckBtnComponent*!/*/}
                  {/*        /!*    class={"check-main"}*!/*/}
                  {/*        /!*    txt={"글로벌 공용"}*!/*/}
                  {/*        /!*  />*!/*/}
                  {/*        /!*)}*!/*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*    {data !== null && fileInfo.url !== null && (*/}
                  {/*      <div*/}
                  {/*        style={{ width: '140px', height: '36px' }}*/}
                  {/*        className={'action-input cursor-pointer center'}*/}
                  {/*        onClick={() => fileDownload(fileInfo.url)}>*/}
                  {/*        <FontAwesomeIcon icon={faDownload} />*/}
                  {/*        <span className={'m-l-10'}>파일 다운로드</span>*/}
                  {/*      </div>*/}
                  {/*    )}*/}
                  {/*    {data !== null && fileInfo.url !== null && (*/}
                  {/*      <img*/}
                  {/*        className={'m-t-10'}*/}
                  {/*        style={{*/}
                  {/*          width: '100%',*/}
                  {/*          maxHeight: '310px',*/}
                  {/*          borderRadius: '5px',*/}
                  {/*        }}*/}
                  {/*        src={fileInfo.url}*/}
                  {/*        alt={'profile'}*/}
                  {/*      />*/}
                  {/*    )}*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>

            {/* 하단 정보 및 버튼 */}
            <div className={'flex justify-end'}>
              <div className={'btn-wrap'} style={{ width: '220px' }}>
                <BtnComponent
                  className={'btn-close'}
                  txt={t('faqdetailpop.btn.cancel')}
                  onClick={() => onClose()}
                />
                <BtnComponent
                  className={'btn-main'}
                  txt={t('faqdetailpop.btn.save')}
                  onClick={() => setSaveModal(true)}
                />
              </div>
            </div>
          </div>

          <BtnPopupLayout
            display={saveModal}
            content={t('faqdetailpop.btnpop.save')}
            btn={[
              {
                txt: t('faqdetailpop.btnpop.cancel'),
                class: 'btn-close',
                onClick: () => setSaveModal(false),
              },
              {
                txt: t('faqdetailpop.btnpop.confirm'),
                class: 'btn-main',
                onClick: () => formCheck(),
              },
            ]}
            onClose={() => setSaveModal(false)}
          />

          <BtnPopupLayout
            display={errModal.display}
            content={errModal.msg}
            btn={[
              {
                txt: t('faqdetailpop.btnpop.errconfirm'),
                class: 'btn-main',
                onClick: () => setErrModal({ msg: '', display: false }),
              },
            ]}
            onClose={() => setErrModal({ msg: '', display: false })}
          />
        </div>
      </div>
    </PopupComponent>
  );
};

export default FaqDetailPop;
