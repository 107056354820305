import { takeLatest } from 'redux-saga/effects';
import { axiosGet } from '../../layout/AppLayout';
import createRequestSaga from '../../utils/createReqSaga';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InviteListParamType,
  PersonalInviteParamType,
} from '../../types/invite/InviteParamType';
import { InviteListApiType } from '../../types/invite/InviteReduxType';

const prefix = 'invite';

const GET_LIST = prefix + '/GET_LIST';
const GET_PERSONAL_INVITE_LIST = prefix + '/GET_PERSONAL_LIST';

export const getInviteList = createAction<InviteListParamType>(GET_LIST);
export const getPersonalInviteList = createAction<PersonalInviteParamType>(
  GET_PERSONAL_INVITE_LIST,
);

const getInviteListApi = (sendData: InviteListParamType) =>
  axiosGet('/event/invite/list', sendData);
const getPersonalInviteListApi = (sendData: PersonalInviteParamType) =>
  axiosGet('/event/invite/mmbr', sendData);

const getInviteListSaga = createRequestSaga(GET_LIST, getInviteListApi);
const getPersonalInviteListSaga = createRequestSaga(
  GET_PERSONAL_INVITE_LIST,
  getPersonalInviteListApi,
);

export function* inviteSaga() {
  yield takeLatest(GET_LIST, getInviteListSaga);
  yield takeLatest(GET_PERSONAL_INVITE_LIST, getPersonalInviteListSaga);
}

interface Types {
  list: InviteListApiType;
  personal: InviteListApiType;
}

const initialState: Types = {
  list: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  personal: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
};

const invite = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: {
    [GET_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<InviteListApiType>,
    ) => {
      state.list = payload;
    },
    [GET_PERSONAL_INVITE_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<InviteListApiType>,
    ) => {
      state.personal = payload;
    },
  },
});

export default invite.reducer;
