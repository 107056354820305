import { axiosExcel, axiosGet } from '../../layout/AppLayout';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductExcelListParamType,
  ProductTrstnListParamType,
} from '../../types/store/productParamType';
import {
  ProductTrstnListType,
  ExcelProductListType,
} from '../../types/store/productReduxType';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../utils/createReqSaga';

const prefix = 'product';

const GET_PRODUCT_TRSTN_LIST = prefix + '/GET_PRODUCT_TRSTN_LIST'; //상품 목록
const GET_PRODUCT_TRSTN_EXCEL_LIST = prefix + '/GET_PRODUCT_TRSTN_EXCEL_LIST'; //상품 엑셀 다운
export const getProductTrstnList = createAction<ProductTrstnListParamType>(
  GET_PRODUCT_TRSTN_LIST,
);

export const getProductExcelList = createAction<ProductExcelListParamType>(
  GET_PRODUCT_TRSTN_EXCEL_LIST,
);

const getProductTrstnListApi = (sendData: ProductTrstnListParamType) => {
  return axiosGet('/trstn/list', sendData).finally(() => {});
};

const getExcelProductListApi = async (sendData: ProductExcelListParamType) => {
  const response = await axiosExcel('/trstn/excel/list', sendData);
  const data = new Blob([response], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', sendData.fileName);
  document.body.appendChild(link);
  link.click();
};
const getProductListSaga = createRequestSaga(
  GET_PRODUCT_TRSTN_LIST,
  getProductTrstnListApi,
);
const getExcelProductListSaga = createRequestSaga(
  GET_PRODUCT_TRSTN_EXCEL_LIST,
  getExcelProductListApi,
);

export function* productSaga() {
  yield takeLatest(GET_PRODUCT_TRSTN_LIST, getProductListSaga);
  yield takeLatest(GET_PRODUCT_TRSTN_EXCEL_LIST, getExcelProductListSaga);
}

interface InitialStateType {
  list: ProductTrstnListType;
}
interface Types {
  list: ProductTrstnListType;
  excelProductList: ExcelProductListType[];
}
const initialState: Types = {
  list: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  excelProductList: [],
};

const product = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: {
    [GET_PRODUCT_TRSTN_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<ProductTrstnListType>,
    ) => {
      state.list = payload;
    },
    [GET_PRODUCT_TRSTN_EXCEL_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<ExcelProductListType[]>,
    ) => {
      state.excelProductList = payload;
    },
  },
});

export default product.reducer;
