import React from 'react';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  src: string;
  onClick?: () => void;
};
const ImgComponent = ({ src, onClick, style, className }: Props) => {
  return (
    <img
      className={className}
      style={style}
      src={process.env.PUBLIC_URL + '/images/' + src}
      alt={'img'}
      onClick={onClick}
    />
  );
};

export default ImgComponent;
