import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import BtnComponent from '../../components/common/BtnComponent';

import { useDispatch, useSelector } from 'react-redux';

import { userSearchOptions } from '../../types/user/userCommonType';
import { axiosPost } from '../../layout/AppLayout';
import { encryptData } from '../../utils/EncryptoUtils';
import { emailRegex } from '../../utils/commonFunc';
import { t } from 'i18next';
import BtnPopupLayout from '../../layout/BtnPopupLayout';

type Props = {
  id: number;
  email: string;
};

const ChangePwdComponent = ({ id, email }: Props) => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState(''); // 비밀번호 입력 값
  const [newEmail, setNewEmail] = useState(email); // 이메일 입력 값
  const [emailChecked, setEmailChecked] = useState(false); // 체크박스 상태 추적
  const [errM, setErrM] = useState({ display: false, msg: '' });
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 비밀번호 입력 값 처리
    setNewPassword(e.target.value);
  };
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 비밀번호 입력 값 처리
    setNewEmail(e.target.value);
  };
  const onSubmitChangeEmailPassword = async () => {
    if (!emailRegex(newEmail)) return;

    if (emailChecked && email == newEmail) {
      setErrM({
        display: true,
        msg: '변경하려는 이메일이 같습니다.',
      });

      return;
    }

    axiosPost('/user/changeemailpwd', null, {
      mmbrId: id,
      email: newEmail,
      newPwd:
        newPassword === ''
          ? ''
          : await encryptData(newPassword).then(res => res),
    })
      .then(res => {
        const msg = res.resultCd === '0000' ? '변경되었습니다.' : res.resultMsg;
        setErrM({
          display: true,
          msg: msg,
        });
        setNewPassword('');
      })
      .catch(e => alert('비밀번호 변경 오류:' + e));
  };

  return (
    <div className={'flex justify-center'}>
      <div className={'w-300'}>
        <div className={'w-100h m-t-30'}>
          <h4>이메일 변경</h4>
          <div className={'flex'}>
            <input
              className={'action-input w-300'}
              onChange={onChangeEmail}
              autoComplete={'one-time-code'}
              value={newEmail}
              disabled={!emailChecked}
            />
            <input
              className={'action-input w-15 m-l-10'}
              type="checkbox"
              checked={emailChecked}
              onChange={() => setEmailChecked(!emailChecked)}
            />
            <span className={'w-100 p-l-10'} style={{ paddingTop: '8px' }}>
              {t('orgregist.checkbox.label')}
            </span>
            {!emailRegex(newEmail) && (
              <p style={{ color: 'red', marginLeft: '5px' }}>
                {t('orgregist.errtxt.validate.email')}
              </p>
            )}
          </div>
        </div>
        <div className={'w-100h m-t-10'}>
          <h4>비밀번호 변경</h4>
          <input
            className={'action-input w-300'}
            onChange={onChangePassword}
            autoComplete={'one-time-code'}
            value={newPassword}
          />
        </div>
        <div className={'w-100h flex justify-center'}>
          <BtnComponent
            className={'btn-main'}
            txt={'변경'}
            onClick={onSubmitChangeEmailPassword}
            style={{ width: '156px' }}
          />
        </div>
      </div>
      <BtnPopupLayout
        display={errM.display}
        content={errM.msg}
        btn={[
          {
            txt: t('action2.popup.btn.errconfirm'),
            onClick: () => setErrM({ display: false, msg: '' }),
            class: 'btn-main',
          },
        ]}
        onClose={() => setErrM({ display: false, msg: '' })}
      />
    </div>
  );
};

export default ChangePwdComponent;
