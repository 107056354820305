import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreListParamType } from '../../types/store/storeParamType';
import { axiosGet } from '../../layout/AppLayout';
import createRequestSaga from '../../utils/createReqSaga';
import { takeLatest } from 'redux-saga/effects';
import {
  StoreCntType,
  storeInit,
  StoreListApiType,
  StoreType,
} from '../../types/store/storeReduxType';
import { GoodsStateCd } from '../../types/store/storeCommonType';
import { getEndDt, getStartDt } from '../../utils/commonFunc';

const prefix = 'store';

/* 상품 상태 코드 */
export const GOODS_STATE_CD: {
  [key: string]: { code: GoodsStateCd; tKey: string };
} = {
  NORMAL: { code: 'NORMAL', tKey: 'storepopinfo.info.status.normal' },
  SOLD_OUT: { code: 'SOLD_OUT', tKey: 'storepopinfo.info.status.soldout' },
  STOP: { code: 'STOP', tKey: 'storepopinfo.info.status.stop' },
};

/* API 모음 */
/* 1. 검색 조건에 따른 조회 */
const GET_STORE_LIST = prefix + '/GET_STORE_LIST';
export const getStoreList = createAction<StoreListParamType>(GET_STORE_LIST);
const getStoreListApi = (sendData: StoreListParamType) =>
  axiosGet('/product/goods/list', sendData);

const getStoreListSaga = createRequestSaga(GET_STORE_LIST, getStoreListApi);

/* 2. 상태 별 수 조회 */
const GET_STATE_COUNT = prefix + '/GET_STATE_COUNT';
export const getStoreStateCount = createAction(GET_STATE_COUNT);
const getStoreStateCntApi = () => axiosGet('/product/goods/state/count');

const getStoreCntSaga = createRequestSaga(GET_STATE_COUNT, getStoreStateCntApi);

/* 3. 상세 조회 */
const GET_STORE = prefix + '/GET_STORE';
export const getStore = createAction<number>(GET_STORE);
const getStoreApi = (goodsId: number) =>
  axiosGet('/product/goods/view/' + goodsId);

const getStoreSaga = createRequestSaga(GET_STORE, getStoreApi);

export function* storeSaga() {
  yield takeLatest(GET_STORE_LIST, getStoreListSaga);
  yield takeLatest(GET_STATE_COUNT, getStoreCntSaga);
  yield takeLatest(GET_STORE, getStoreSaga);
}

interface InitialStateType {
  storeList: StoreListApiType;
  stateCount: StoreCntType;
  store: StoreType;
  info: StoreType;
  file: any;
  content: {
    first: string;
    second: string;
    third: string;
  };
  search: {
    searching: boolean;
    searchTp: string;
    searchTxt: string;
    startDt: string;
    endDt: string;
  };
  goodsStateCd: GoodsStateCd | null;
}

const initialState: InitialStateType = {
  storeList: {
    content: [],
    number: 0,
    size: 20,
    totalPages: 0,
    totalElements: 0,
  },
  stateCount: {
    allCnt: 0,
    normalCnt: 0,
    stopCnt: 0,
    soldOutCnt: 0,
  },
  store: storeInit,
  info: storeInit,
  file: null,
  content: {
    first: '',
    second: '',
    third: '',
  },
  search: {
    searching: false,
    searchTp: 'TITLE',
    searchTxt: '',
    startDt: getStartDt(new Date()),
    endDt: getEndDt(new Date()),
  },
  goodsStateCd: null,
};

const store = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setStore: (state, action: PayloadAction<StoreType>) => {
      state.store = action.payload;
    },
    setStoreModalInfo: (state, action: PayloadAction<StoreType>) => {
      state.info = action.payload;
    },
    setFile: (state, action) => {
      state.file = action.payload;
    },
    setContent: (state, action: PayloadAction<typeof initialState.content>) => {
      state.content = action.payload;
    },
    setStoreSearch: (
      state,
      action: PayloadAction<typeof initialState.search>,
    ) => {
      state.search = action.payload;
    },
    setGoodsStateCd: (state, action: PayloadAction<GoodsStateCd | null>) => {
      state.goodsStateCd = action.payload;
    },
  },
  extraReducers: {
    [GET_STORE_LIST + '_SUC']: (
      state,
      action: PayloadAction<StoreListApiType>,
    ) => {
      state.storeList = action.payload;
    },
    [GET_STATE_COUNT + '_SUC']: (
      state,
      action: PayloadAction<StoreCntType>,
    ) => {
      state.stateCount = action.payload;
    },
    [GET_STORE + '_SUC']: (state, { payload }: PayloadAction<StoreType>) => {
      state.store = payload;
    },
  },
});

export default store.reducer;
export const {
  setStore,
  setStoreModalInfo,
  setFile,
  setContent,
  setStoreSearch,
  setGoodsStateCd,
} = store.actions;
