import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import action, { actionSaga } from './action/action';
import user, { userSaga } from './user/user';
import story, { storySaga } from './story/story';
import reply, { replySaga } from './reply/reply';
import certify, { certifySaga } from './certify/certify';
import challenge, { challengeSaga } from './challenge/challenge';
import inquiry, { inquirySaga } from './support/inquiry';
import point, { pointSaga } from './point/point';
import notice, { noticeSaga } from './setting/notice';
import popup, { popupSaga } from './setting/popup';
import login from './login/login';
import invite, { inviteSaga } from './user/invite';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import support from './support/support';
import faq, { faqSaga } from './support/faq';
import faqCate, { faqCategorySaga } from './support/faqCategory';
import store, { storeSaga } from './store/store';
import vendor, { vendorSaga } from './store/vendor';
import product, { productSaga } from './store/product';
import behavior, { behaviorSaga } from './behavior/behavior';
import meta, { metaSaga } from './behavior/meta';
import behaviorGroup, { behaviorGroupSaga } from './behavior/behaivorGroup';
import recom, { recomSaga } from './behavior/recom';
import contents from './contents/contents';
import chlng, { chlngSaga } from './challenge/challengeV2';
import chlngGrp, { chlngGrpSaga } from './challenge/chlngGrp';
import alarm, { alarmSaga } from './setting/alarm';
import common from './common';
import report, { reportSaga } from './support/report';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['login'],
};

const rootReducer = combineReducers({
  user,
  action,
  story,
  reply,
  certify,
  challenge,
  inquiry,
  point,
  notice,
  popup,
  login,
  invite,
  support,
  faq,
  faqCate,
  report,
  store,
  vendor,
  behavior,
  meta,
  behaviorGroup,
  recom,
  contents,
  chlng,
  chlngGrp,
  alarm,
  common,
  product,
});

export function* rootSaga() {
  yield all([
    userSaga(),
    actionSaga(),
    storySaga(),
    replySaga(),
    certifySaga(),
    challengeSaga(),
    inquirySaga(),
    pointSaga(),
    noticeSaga(),
    popupSaga(),
    inviteSaga(),
    faqSaga(),
    faqCategorySaga(),
    reportSaga(),
    storeSaga(),
    vendorSaga(),
    behaviorSaga(),
    metaSaga(),
    behaviorGroupSaga(),
    recomSaga(),
    chlngSaga(),
    chlngGrpSaga(),
    alarmSaga(),
    productSaga(),
  ]);
}

declare module 'react-redux' {
  type DefaultRootState = RootState;
}

export type RootState = ReturnType<typeof rootReducer>;

export default persistReducer(persistConfig, rootReducer);
