import { useLocation } from 'react-router-dom';
import React, { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '@modules/index';
import { useNavigate } from 'react-router';
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImgComponent from '../components/common/ImgComponent';
import { Menu, MENU_WEB, MENU_MOBILE, MENU_MALL } from '../modules/menu/menu';

const SideMenuLayout = () => {
  const path = useLocation().pathname;
  const { t } = useTranslation();
  const isMobile = useSelector((state: any) => state.login.isMobile);
  const { adminAuth } = useSelector(
    ({ login }: RootState) => login,
    shallowEqual,
  );

  let menu = null;
  if (adminAuth === 'ROLE_MALL') menu = MENU_MALL;
  else if (isMobile) menu = MENU_MOBILE;
  else menu = MENU_WEB;

  const navigate = useNavigate();
  const [menus, setMenus] = useState(menu);

  const updateMenuOpen = (key: string) => {
    setMenus(prevMenus =>
      prevMenus.map(menu =>
        menu.key === key ? { ...menu, open: !menu.open } : menu,
      ),
    );
  };

  const renderMenu = (menus: Menu[]) => {
    return menus.map(menu => (
      <div key={menu.key}>
        <ListItemButton onClick={() => updateMenuOpen(menu.key)}>
          <ListItemIcon sx={{ minWidth: '35px' }}>
            <menu.icon className={'color-sub'} />
          </ListItemIcon>
          <ListItemText primary={t(`${menu.title}`)} />
          {menu.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={menu.open}>{renderSubMenu(menu)}</Collapse>
        <Divider />
      </div>
    ));
  };

  const renderSubMenu = (menu: Menu) => {
    return (
      <List
        component="div"
        sx={{ background: 'black' }}
        dense={true}
        disablePadding={true}>
        {menu.subMenu.map(subMenu => (
          <ListItemButton
            key={subMenu.key}
            sx={{ pl: 2 }}
            selected={path.includes(subMenu.path)}
            onClick={() => navigate(subMenu.path)}>
            <ListItemText primary={'└　' + t(`${subMenu.title}`)} />
          </ListItemButton>
        ))}
        <Divider />
      </List>
    );
  };

  return (
    <aside>
      <List
        component="nav"
        sx={{ position: 'sticky', top: '0' }}
        dense={true}
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            color={'inherit'}
            sx={{ background: 'black' }}>
            <div
              className={'flex justify-center cursor-pointer'}
              onClick={() => navigate('/')}>
              <ImgComponent
                src={'11CarbonZero_logo.png'}
                style={{ width: '30px' }}
              />
              <h3 className={'m-l-10'}>CarbonZero</h3>
            </div>
          </ListSubheader>
        }>
        <Divider />
        {renderMenu(menus)}
      </List>
    </aside>
  );
};

export default memo(SideMenuLayout);
