import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../utils/createReqSaga';
import { axiosGet } from '../../layout/AppLayout';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PopupListParamType,
  screenTpCdSelect,
} from '../../types/popup/popupParamType';
import {
  PopupCountType,
  PopupListApiType,
  PopupListType,
} from '../../types/popup/popupReduxType';
import { ScreenTpCdType } from '../../types/popup/popupCommonType';

const prefix = 'popup';

const GET_POPUP_LIST = prefix + '/GET_POPUP_LIST';
const GET_POPUP_SORTING_LIST = prefix + '/GET_POPUP_SORTING_LIST';
const GET_POPUP_COUNT = prefix + '/GET_POPUP_COUNT';

export const getPopupList = createAction<PopupListParamType>(GET_POPUP_LIST);
export const getPopupSortingList = createAction(GET_POPUP_SORTING_LIST);
export const getPopupCount = createAction<screenTpCdSelect>(GET_POPUP_COUNT);

const getPopupListApi = (sendData: PopupListParamType) =>
  axiosGet('/board/popup/list', sendData);
const getPopupCountApi = (sendData: screenTpCdSelect) =>
  axiosGet('/board/popup/count', sendData);
const getPopupSortingListApi = () => axiosGet('/board/popup/list/all');

const getPopupListSaga = createRequestSaga(GET_POPUP_LIST, getPopupListApi);
const getPopupSortingListSaga = createRequestSaga(
  GET_POPUP_SORTING_LIST,
  getPopupSortingListApi,
);
const getPopupCountSaga = createRequestSaga(GET_POPUP_COUNT, getPopupCountApi);

export function* popupSaga() {
  yield takeLatest(GET_POPUP_LIST, getPopupListSaga);
  yield takeLatest(GET_POPUP_SORTING_LIST, getPopupSortingListSaga);
  yield takeLatest(GET_POPUP_COUNT, getPopupCountSaga);
}

interface InitialStateType {
  list: PopupListApiType;
  count: PopupCountType;
  sort: { [key: string]: Array<PopupListType & { ordr?: number }> };
  screenTpCd: ScreenTpCdType;
}

const initialState: InitialStateType = {
  list: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  count: {
    all: 0,
    use: 0,
    unUse: 0,
  },
  sort: {},
  screenTpCd: 'JOIN',
};

const popup = createSlice({
  name: prefix,
  initialState,
  reducers: {
    onSortChange: (
      state,
      { payload }: PayloadAction<typeof initialState.sort>,
    ) => {
      state.sort = payload;
    },
    setScreenTpCd: (state, { payload }: PayloadAction<ScreenTpCdType>) => {
      state.screenTpCd = payload;
    },
  },
  extraReducers: {
    [GET_POPUP_COUNT + '_SUC']: (
      state,
      { payload }: PayloadAction<PopupCountType>,
    ) => {
      state.count = payload;
    },
    [GET_POPUP_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<PopupListApiType>,
    ) => {
      state.list = payload;
    },
    [GET_POPUP_SORTING_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<PopupListType[]>,
    ) => {
      state.sort = payload.reduce((newObj, obj) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newObj[obj.screenTpCd] = payload.filter(
          item => item.screenTpCd == obj.screenTpCd,
        );
        return newObj;
      }, {});
    },
  },
});

export default popup.reducer;
export const { onSortChange, setScreenTpCd } = popup.actions;
