import React, { Component, useEffect, useState } from 'react';
import CountBtnComponent from '../../common/CountBtnComponent';
import DataGridComponent from '../../common/DataGridComponent';
import {
  getFaqList,
  setFaqId,
  setFaqModal,
  getFaqCount,
} from '../../../modules/support/faq';
import FaqDetailPop from '../../../layout/detailPopup/FaqDetailPop';
import { dtFormat } from '../../../utils/commonFunc';
import BtnComponent from '../../common/BtnComponent';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../modules';
import { useTranslation } from 'react-i18next';

const FaqListSection = () => {
  const [state, setState] = useState('ALL');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { faqList, faqId, faqModal, count } = useSelector(
    ({ faq }: RootState) => faq,
    shallowEqual,
  );
  const { faqSearch } = useSelector(
    ({ support }: RootState) => support,
    shallowEqual,
  );
  useEffect(() => {
    dispatch(getFaqCount());
  }, []);

  useEffect(() => {
    if (state === 'ALL') {
      dispatch(getFaqList({ page: 0, size: 10 }));
    } else if (state === 'USE') {
      dispatch(getFaqList({ page: 0, size: 10, useYn: 'Y' }));
    } else if (state === 'UNUSE') {
      dispatch(getFaqList({ page: 0, size: 10, useYn: 'N' }));
    }
  }, [state]);

  const columns = [
    {
      field: 'faqId',
      headerName: t('faqlistsection.datagrid.faqid'),
      headerAlign: 'center',
      align: 'center',
      width: 50,
    },
    {
      field: 'pcateIdName',
      headerName: t('faqlistsection.datagrid.pcateidname'),
      headerAlign: 'left',
      align: 'center',
      width: 170,
      renderCell: (params: any) => {
        return (
          <div className={'grid-cell'}>
            <p>
              {t(
                'constants.catenm.' +
                  params.row.ccateId +
                  '.' +
                  params.row.pcateId,
              )}
            </p>
          </div>
        );
      },
    },
    {
      field: 'ccateIdName',
      headerName: t('faqlistsection.datagrid.cateidname'),
      headerAlign: 'left',
      align: 'center',
      width: 170,
      renderCell: (params: any) => {
        return (
          <div className={'grid-cell'}>
            <p>{t('constants.catenm.' + params.row.ccateId)}</p>
          </div>
        );
      },
    },
    {
      field: 'title',
      headerName: t('faqlistsection.datagrid.title'),
      headerAlign: 'left',
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div className={'grid-cell'}>
            <p className={'color-main'}>{params.row.title}</p>
            {/*<h5 className={'color-sub text-ellipsis text-row-2'}>*/}
            {/*  {params.row.ctnt}*/}
            {/*</h5>*/}
          </div>
        );
      },
    },
    {
      field: 'dt',
      headerName: t('faqlistsection.datagrid.dt'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
      renderCell: (params: any) => {
        return params.row.alwsExpsrYn === 'Y' ? (
          <div>{t('faqlistsection.datagrid.dt.period')}</div>
        ) : (
          <div>
            <p>{dtFormat(params.row.expsrStartDate, 'date')}</p>
            <p>{dtFormat(params.row.expsrFnshDate, 'date')}</p>
          </div>
        );
      },
    },
    {
      field: 'regName',
      headerName: t('faqlistsection.datagrid.regname'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
    },
    {
      field: 'regDt',
      headerName: t('faqlistsection.datagrid.regdt'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
      renderCell: (params: any) => {
        return (
          <p className={'color-sub'}>{dtFormat(params.row.regDt, 'date')}</p>
        );
      },
    },
    {
      field: 'status',
      headerName: t('faqlistsection.datagrid.status'),
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        const status = params.row.useYn;
        return (
          <BtnComponent
            className={status === 'N' ? 'btn-sub' : 'btn-main'}
            txt={
              status === 'N'
                ? t('faqlistsection.datagrid.status.unuse')
                : t('faqlistsection.datagrid.status.use')
            }
          />
        );
      },
    },
  ];
  return (
    <div className={'dashboard-layout'}>
      <div className={'title'}>
        <h4>{t('faqlistsection.header.title')}</h4>
      </div>
      <div className={'flex justify-between'}>
        <div className={'userManage btn-wrap'}>
          <CountBtnComponent
            className={state === 'ALL' ? 'on' : undefined}
            txt={t('faqlistsection.countbtn.all')}
            count={count?.all || 0}
            onClick={() => setState('ALL')}
          />
          <CountBtnComponent
            className={state === 'USE' ? 'on' : undefined}
            txt={t('faqlistsection.countbtn.use')}
            count={count?.use || 0}
            onClick={() => setState('USE')}
            circle={'circle-green'}
          />
          <CountBtnComponent
            className={state === 'UNUSE' ? 'on' : undefined}
            txt={t('faqlistsection.countbtn.unuse')}
            count={count?.unUse || 0}
            onClick={() => setState('UNUSE')}
            circle={'circle-gray'}
          />
        </div>
        <BtnComponent
          className={'btn-auto btn-main'}
          txt={t('faqlist.barbtn.register')}
          onClick={() => {
            dispatch(setFaqModal(true));
          }}
        />
      </div>
      <DataGridComponent
        columns={columns}
        data={faqList.content}
        page={faqList.number}
        totalPage={faqList.totalPages}
        size={faqList.size}
        getRowId={'faqId'}
        onChangePerRow={size => {
          const sendData = faqSearch.searching
            ? {
                page: 0,
                size: size,
                searchTp: faqSearch.searchTp,
                searchTxt: faqSearch.searchTxt,
              }
            : {
                page: 0,
                size: size,
              };
          dispatch(getFaqList(sendData));
        }}
        onChangePage={page => {
          const sendData = faqSearch.searching
            ? {
                page: page,
                size: faqList.size,
                searchTp: faqSearch.searchTp,
                searchTxt: faqSearch.searchTxt,
              }
            : {
                page: page,
                size: faqList.size,
              };
          dispatch(
            getFaqList({
              page: page,
              size: faqList.size,
            }),
          );
        }}
        onRowClick={e => {
          console.log(e);
          dispatch(setFaqId(e.row.faqId));
          // setMmbrId(e.row.mmbrId);
          dispatch(setFaqModal(true));
        }}
        check={false}
      />
      <FaqDetailPop
        faqId={faqId}
        onClickClose={() => {
          dispatch(setFaqModal(false));
          dispatch(setFaqId(0));
        }}
        display={faqModal}
      />
    </div>
  );
};

export default FaqListSection;
