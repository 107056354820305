import { createAction } from 'redux-actions';
import { RecomListParamType } from '../../types/behavior/recomParamType';
import { axiosGet } from '../../layout/AppLayout';
import createRequestSaga from '../../utils/createReqSaga';
import { takeLatest } from 'redux-saga/effects';
import {
  recomInit,
  RecomListPagingType,
  RecomType,
} from '../../types/behavior/recomReduxType';
import { pagingInit } from '../../types/common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const prefix = 'recom';

const GET_RECOM_LIST = prefix + '/GET_RECOM_LIST';
const GET_RECOM = prefix + '/GET_RECOM';
const GET_COUNT = prefix + '/GET_COUNT';

export const getRecomList = createAction<RecomListParamType>(GET_RECOM_LIST);
export const getRecom = createAction<number>(GET_RECOM);
export const getRecomCnt = createAction(GET_COUNT);

const getRecomListApi = (sendData: RecomListParamType) =>
  axiosGet('/behavior/recom/list', sendData);
const getRecomApi = (id: number) => axiosGet(`/behavior/recom/${id}`);
const getCountApi = () => axiosGet('/behavior/recom/count');

const getRecomListSaga = createRequestSaga(GET_RECOM_LIST, getRecomListApi);
const getRecomSaga = createRequestSaga(GET_RECOM, getRecomApi);
const getCountSaga = createRequestSaga(GET_COUNT, getCountApi);

export function* recomSaga() {
  yield takeLatest(GET_RECOM_LIST, getRecomListSaga);
  yield takeLatest(GET_RECOM, getRecomSaga);
  yield takeLatest(GET_COUNT, getCountSaga);
}

interface InitialStateType {
  list: RecomListPagingType;
  display: boolean;
  recom: RecomType;
  file: File | null;
  count: {
    all: number;
    use: number;
    unUse: number;
  };
}

const initialState: InitialStateType = {
  list: pagingInit,
  display: false,
  recom: recomInit,
  file: null,
  count: {
    all: 0,
    use: 0,
    unUse: 0,
  },
};

const recom = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setRecomDisplay: (state, action) => {
      state.display = action.payload;
    },
    setRecom: (state, action: PayloadAction<RecomType>) => {
      state.recom = action.payload;
    },
    setRecomFile: (state, action: PayloadAction<File | null>) => {
      state.file = action.payload;
    },
  },
  extraReducers: {
    [GET_RECOM_LIST + '_SUC']: (
      state,
      action: PayloadAction<RecomListPagingType>,
    ) => {
      state.list = action.payload;
    },
    [GET_RECOM + '_SUC']: (state, action: PayloadAction<RecomType>) => {
      state.recom = action.payload;
    },
    [GET_COUNT + '_SUC']: (
      state,
      action: PayloadAction<typeof initialState.count>,
    ) => {
      state.count = action.payload;
    },
  },
});

export default recom.reducer;
export const { setRecomDisplay, setRecomFile, setRecom } = recom.actions;
