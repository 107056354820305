import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../utils/createReqSaga';
import { axiosGet } from '../../layout/AppLayout';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

const prefix = 'reply';

const GET_CNT = prefix + '/GET_CNT';
const GET_LIST = prefix + '/GET_LIST';
const GET_RE_REPLY = prefix + '/GET_RE_REPLY';

export const getReplyCnt = createAction<any>(GET_CNT);
export const getReplyList = createAction<any>(GET_LIST);
export const getReReplyList = createAction<any>(GET_RE_REPLY);

const getReplyCntApi = (sendData: any) => axiosGet('/reply/count', sendData);
const getReplyListApi = (sendData: any) => axiosGet('/reply/list', sendData);
const getReReplyListApi = (sendData: any) =>
  axiosGet('/reply/re/list/' + sendData);

const getReplyCntSaga = createRequestSaga(GET_CNT, getReplyCntApi);
const getReplyListSaga = createRequestSaga(GET_LIST, getReplyListApi);
const getReReplyListSaga = createRequestSaga(GET_RE_REPLY, getReReplyListApi);

export function* replySaga() {
  yield takeLatest(GET_CNT, getReplyCntSaga);
  yield takeLatest(GET_LIST, getReplyListSaga);
  yield takeLatest(GET_RE_REPLY, getReReplyListSaga);
}

const initialState = {
  count: {
    all: 0,
    show: 0,
    hide: 0,
  },
  list: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  reReply: [],
};

const reply = createSlice({
  name: prefix,
  initialState,
  reducers: {
    updateReReply: (state, action: PayloadAction<any>) => {
      console.log(action);
      state.reReply = action.payload;
    },
  },
  extraReducers: {
    [GET_CNT + '_SUC']: (state, action: PayloadAction<any>) => {
      state.count = action.payload;
    },
    [GET_LIST + '_SUC']: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    [GET_RE_REPLY + '_SUC']: (state, action: PayloadAction<any>) => {
      state.reReply = action.payload;
    },
  },
});

// const reply = handleActions(
//   {
//     [GET_CNT_SUC]: (state, action) => ({
//       ...state,
//       count: action.payload,
//     }),
//     [GET_LIST_SUC]: (state, action) => ({
//       ...state,
//       list: action.payload,
//     }),
//     [GET_RE_REPLY_SUC]: (state, action) => ({
//       ...state,
//       reReply: action.payload,
//     }),
//     [UPDATE_RE_REPLY]: (state, { payload: reReply }) => ({
//       ...state,
//       reReply: reReply,
//     }),
//   },
//   initialState,
// );

export default reply.reducer;
export const { updateReReply } = reply.actions;
