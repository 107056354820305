import ImgComponent from './ImgComponent';
import {
  addComma,
  changeSizeUnit,
  encodeFileToBase64,
  sliceWord,
} from '../../utils/commonFunc';
import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { getUserProfile } from '../../modules/user/user';
import { useTranslation } from 'react-i18next';

type Props = {
  id: number;
  orgNm?: string;
  orgYn?: string;
  setFile?: React.Dispatch<React.SetStateAction<any>>;
  imgInfo?: any;
  setImgInfo?: React.Dispatch<React.SetStateAction<any>>;
};
const ProfilePanelComponent = ({
  id,
  orgNm,
  orgYn,
  setFile,
  imgInfo,
  setImgInfo,
}: Props) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { profile } = useSelector(({ user }: RootState) => user);

  const jobs =
    profile?.jobs?.map(item =>
      t('constants.jobkywdtpcd.' + item.jobKywdTpCd.toLowerCase()),
    ) || [];

  useEffect(() => {
    id && id !== 0 && dispatch(getUserProfile(id));
  }, [id]);
  return (
    <div style={{ width: '245px' }}>
      <div className={'panel'}>
        <div className={'w-100'}>
          {orgYn &&
            orgYn === 'Y' &&
            setFile !== undefined &&
            setImgInfo !== undefined && (
              <div
                className={'org-img-upload'}
                style={{ top: '17%', left: '70%' }}>
                <ImgComponent src={'camera.svg'} />
                <input
                  type={'file'}
                  className={'uploader'}
                  accept={'image/*'}
                  onChange={(e: any) => {
                    encodeFileToBase64(e.target.files[0], reader => {
                      setFile(e.target.files[0]);
                      setImgInfo({
                        url: reader.result,
                        name: e.target.files[0].name,
                        size: changeSizeUnit(e.target.files[0].size),
                      });
                      e.target.value = '';
                    });
                  }}
                />
              </div>
            )}

          {profile.profileImg !== null && profile.profileImg !== 'null' ? (
            <img
              className={'detail-profile'}
              src={imgInfo ? imgInfo.url : profile.profileImg}
              alt={'profile'}
            />
          ) : imgInfo && imgInfo?.url !== null ? (
            <img
              className={'detail-profile'}
              src={imgInfo.url}
              alt={'profile'}
            />
          ) : (
            <div className={'detail-profile m-auto center'}>
              <ImgComponent src={'leaf.svg'} style={{ width: '30px' }} />
            </div>
          )}
        </div>

        <h3>
          {profile?.nickname}
          {profile?.wtdYn === 'Y' && t('profilepanel.profile.wtdyny')}
        </h3>
        <Tooltip title={profile?.email} placement="right">
          <p className={'text-ellipsis'}>{profile?.email}</p>
        </Tooltip>
        <p>{profile?.mainLang}</p>
        {profile?.orgYn === 'N' && profile?.belongList && (
          <>
            {profile.belongList.map((belong, index) => (
              <div key={index}>
                {`${t(`profilepanel.profile.belong${index + 1}`)} : ${
                  belong.orgNm || t('profilepanel.profile.notbelong')
                }`}
              </div>
            ))}
          </>
        )}
        {/*TODO : 2차*/}
        {jobs?.length > 0 && (
          <Tooltip title={jobs?.join(', ')} placement="right">
            <p className={'text-ellipsis'}>{jobs?.join(', ')}</p>
          </Tooltip>
        )}

        {/* 맴버쉽 배너 */}
        {/*{profile.gradCd !== null && (
          <>
            <div className={'membership'}>
              <p className={'flex'}>
                <ImgComponent
                  className={'m-t-2 m-r-5'}
                  src={'certification.svg'}
                />
                {profile.gradCd}
              </p>
              <div>
                <p className={'color-main'}>Membership No.</p>
                <p>{sliceWord(profile.mmbrshpNum, 4, ' ')}</p>
              </div>
            </div>
            <Tooltip title={profile.stateMsg} placement="right">
              <p className={'color-white text-left text-ellipsis text-row-4'}>
                {profile.stateMsg}
              </p>
            </Tooltip>
          </>
        )}*/}

        <table>
          <tbody>
            <tr>
              <th>{t('profilepanel.th.point')}</th>
              <td className={'color-main'}>{addComma(profile?.point)}</td>
            </tr>
            <tr>
              <th>{t('profilepanel.th.cero')}</th>
              <td className={'color-main'}>{addComma(profile?.cero)}</td>
            </tr>
            <tr>
              <th>{t('profilepanel.th.lvlcd')}</th>
              <td className={'color-main'}>{profile?.lvlCd}</td>
            </tr>
            <tr>
              <th>{t('profilepanel.th.exp')}</th>
              <td className={'color-main'}>{addComma(profile?.exp)}</td>
            </tr>
            {profile?.gradCd !== null && (
              <tr>
                <th>{t('profilepanel.th.subscriber')}</th>
                <td className={'color-main'}>
                  {addComma(profile?.subscriber)}
                </td>
              </tr>
            )}
            <tr>
              <th>{t('profilepanel.th.subscribe')}</th>
              <td className={'color-main'}>{addComma(profile?.subscribe)}</td>
            </tr>
            <tr>
              <th>{t('profilepanel.th.view')}</th>
              <td>{addComma(profile?.view)}</td>
            </tr>
            <tr>
              <th>{t('profilepanel.th.certify')}</th>
              <td>{addComma(profile?.certify)}</td>
            </tr>
            <tr>
              <th>{t('profilepanel.th.deviceos')}</th>
              <td>{profile?.deviceOs}</td>
            </tr>
            <tr>
              <th>{t('profilepanel.th.devicename')}</th>
              <td title={profile?.deviceName}>
                {profile?.deviceName?.length >= 20
                  ? profile?.deviceName.slice(0, 20)
                  : profile?.deviceName}
              </td>
            </tr>
            {/*TODO : 2차*/}
            {/*<tr>*/}
            {/*  <th>팔로워</th>*/}
            {/*  <td>{addComma(83)}</td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <th>팔로잉</th>*/}
            {/*  <td>{addComma(128)}</td>*/}
            {/*</tr>*/}
          </tbody>
        </table>
      </div>
      <div className={'join-div'} style={{ width: '120px' }}>
        <span className={'color-sub'}>Report</span>
        <span className={'color-red'}>{addComma(profile?.report)}</span>
        <span className={'color-sub'}>|</span>
        <span className={'color-b295 cursor-pointer'}>Block</span>
      </div>
    </div>
  );
};

export default ProfilePanelComponent;
