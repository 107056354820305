import React from 'react';
import parse from 'html-react-parser';

type Props = {
  style?: React.CSSProperties;
  className: string;
  onClick?: () => void;
  img?: string | React.ReactNode;
  txt: string;
};
const BtnComponent = ({ style, img, txt, onClick, className }: Props) => {
  return (
    <p style={style} className={'btn ' + className} onClick={onClick}>
      {img && img}
      {parse(txt)}
    </p>
  );
};

export default BtnComponent;
