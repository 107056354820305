import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
type Props = {
  value?: any;
  className?: string;
  onClick?: () => void;
  txt: string;
};
const CheckBtnComponent = ({ onClick, value, txt, className }: Props) => {
  const [check, setCheck] = useState(value);

  useEffect(() => {
    setCheck(value);
  }, [value]);

  return (
    <div
      className={'flex'}
      onClick={() => {
        setCheck(!check);
        onClick && onClick();
      }}>
      <div className={check ? 'checkbox ' + className : 'checkbox'}>
        {check && <FontAwesomeIcon icon={faCheck} />}
      </div>
      <span className={'font-14'}>{txt}</span>
    </div>
  );
};

export default CheckBtnComponent;
