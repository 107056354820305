import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../utils/createReqSaga';
import { axiosGet } from '../../layout/AppLayout';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

const prefix = 'challenge';

const GET_CNT = prefix + '/GET_CNT';

const GET_CHALLENGE_LIST = prefix + '/GET_CHALLENGE_LIST';
const GET_CHALLENGE_GROUP_LIST = prefix + '/GET_CHALLENGE_GROUP_LIST';

const GET_CHALLENGE = prefix + '/GET_CHALLENGE';

const GET_CHALLENGE_GROUP = prefix + '/GET_CHALLENGE_GROUP';

const UPDATE_SEARCH = prefix + '/UPDATE_SEARCH';

export const getChallengeCnt = createAction<any>(GET_CNT);

export const getChallengeList = createAction<any>(GET_CHALLENGE_LIST);
export const getGroupList = createAction<any>(GET_CHALLENGE_GROUP_LIST);

export const getChallenge = createAction<any>(GET_CHALLENGE);

export const getGroup = createAction<any>(GET_CHALLENGE_GROUP);

export const updateSearch = createAction(UPDATE_SEARCH, search => search);

//Api
const getChallengeCntApi = (sendData: any) =>
  axiosGet('/challenge/count', sendData);

const getChallengeListApi = (sendData: any) =>
  axiosGet('/challenge/list', sendData);
const getChallengeGroupListApi = (sendData: any) =>
  axiosGet('/challenge/group/list', sendData);

const getChallengeApi = (sendData: any) => axiosGet('/challenge/' + sendData);
const getChallengeGroupApi = (sendData: any) =>
  axiosGet('/challenge/group/' + sendData);

const getChallengeCntSaga = createRequestSaga(GET_CNT, getChallengeCntApi);

const getChallengeListSaga = createRequestSaga(
  GET_CHALLENGE_LIST,
  getChallengeListApi,
);
const getChallengeGroupListSaga = createRequestSaga(
  GET_CHALLENGE_GROUP_LIST,
  getChallengeGroupListApi,
);

const getChallengeSaga = createRequestSaga(GET_CHALLENGE, getChallengeApi);
const getChallengeGroupSaga = createRequestSaga(
  GET_CHALLENGE_GROUP,
  getChallengeGroupApi,
);

export function* challengeSaga() {
  yield takeLatest(GET_CNT, getChallengeCntSaga);

  yield takeLatest(GET_CHALLENGE_LIST, getChallengeListSaga);
  yield takeLatest(GET_CHALLENGE_GROUP_LIST, getChallengeGroupListSaga);

  yield takeLatest(GET_CHALLENGE, getChallengeSaga);
  yield takeLatest(GET_CHALLENGE_GROUP, getChallengeGroupSaga);
}

const initialState = {
  search: { state: '' },
  count: {
    all: 0,
    finish: 0,
    ongoing: 0,
    waiting: 0,
    use: 0,
    notUse: 0,
  },
  challengeList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  groupList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  challenge: {
    challengeId: 0,
    challengeStatCd: '',
    challengeStatCdDesc: '',
    cateTitle: '',
    endDt: '',
    exp: 0,
    hostName: '',
    imgUrl: '',
    linkUrl: '',
    mssnEffect: '',
    mssnEtc: '',
    mssnId: 0,
    mssnTitle: '',
    mssnTitleDtl: '',
    point: 0,
    rangeYn: 'Y',
    separatorTpCd: 'OFFICIAL',
    separatorTpCdDesc: '공식 챌린지',
    startDt: '',
    useYn: 'N',
    belongMmbrId: '',
    cateId: 0,
    autoApprovalYn: 'N',
  },
  group: {
    cateId: 0,
    imgUrl: '',
    cateTitle: '',
    cateContents: '',
    useYn: 'N',
    challengeList: [],
  },
};

const challenge = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setChallengeInit: state => {
      state.challenge = initialState.challenge;
    },
    setChallengeGroupInit: state => {
      state.group = initialState.group;
    },
  },
  extraReducers: {
    [GET_CNT + '_SUC']: (state, action: PayloadAction<any>) => {
      state.count = action.payload;
    },
    [GET_CHALLENGE_LIST + '_SUC']: (state, action: PayloadAction<any>) => {
      state.challengeList = action.payload;
    },
    [GET_CHALLENGE_GROUP_LIST + '_SUC']: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.groupList = action.payload;
    },
    [GET_CHALLENGE + '_SUC']: (state, action: PayloadAction<any>) => {
      state.challenge = action.payload;
    },
    [GET_CHALLENGE_GROUP + '_SUC']: (state, action: PayloadAction<any>) => {
      state.group = action.payload;
    },
  },
});

export default challenge.reducer;
export const { setChallengeGroupInit, setChallengeInit } = challenge.actions;
