import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../utils/createReqSaga';
import { axiosGet } from '../../layout/AppLayout';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PointListParamType,
  UserCameListParamType,
  UserPointListParamType,
} from '../../types/point/pointParamType';
import {
  PointListType,
  UserCameListType,
  UserPointListType,
} from '../../types/point/pointReduxType';
import { DataGrid } from '@mui/x-data-grid';
import DataGridComponent from '@components/common/DataGridComponent';

const prefix = 'point';

const GET_POINT_LIST = prefix + '/GET_POINT_LIST';
const GET_USER_POINT_LIST = prefix + '/GET_USER_POINT_LIST';
const GET_USER_CAME_LIST = prefix + '/GET_USER_CAME_LIST';
// 초기화
const RESET_USER_LIST = prefix + '/RESET_USER_LIST';

export const getPointList = createAction<PointListParamType>(GET_POINT_LIST);
export const getUserPointList =
  createAction<UserPointListParamType>(GET_USER_POINT_LIST);
export const getUserCameList =
  createAction<UserCameListParamType>(GET_USER_CAME_LIST);
// 초기화
export const resetUserList = createAction(RESET_USER_LIST);

const getPointListApi = (sendData: PointListParamType) => {
  return axiosGet('/reward/list', sendData).finally(() => {
    console.log('getPointListApi finally');
  });
};
const getUserPointListApi = (sendData: UserPointListParamType) =>
  axiosGet('/reward/user/list', sendData);

const getUserCameListApi = (sendData: UserCameListParamType) =>
  axiosGet('/came/user/list', sendData);

const getPointListSaga = createRequestSaga(GET_POINT_LIST, getPointListApi);
const getUserPointListSaga = createRequestSaga(
  GET_USER_POINT_LIST,
  getUserPointListApi,
);
const getUserCamelistSaga = createRequestSaga(
  GET_USER_CAME_LIST,
  getUserCameListApi,
);

export function* pointSaga() {
  yield takeLatest(GET_POINT_LIST, getPointListSaga);
  yield takeLatest(GET_USER_POINT_LIST, getUserPointListSaga);
  yield takeLatest(GET_USER_CAME_LIST, getUserCamelistSaga);
}

interface InitialStateType {
  list: PointListType;
  userList: UserPointListType;
  cameUserList: UserCameListType;
}

const initialState: InitialStateType = {
  list: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  userList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  cameUserList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
};

const point = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: {
    [GET_POINT_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<PointListType>,
    ) => {
      state.list = payload;
    },
    [GET_USER_POINT_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<UserPointListType>,
    ) => {
      state.userList = payload;
    },
    [GET_USER_CAME_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<UserCameListType>,
    ) => {
      state.cameUserList = payload;
      // if (
      //   state.userList.content.length <= 0 &&
      //   JSON.stringify(state.userList.content) !==
      //     JSON.stringify(payload.content)
      // ) {
      //   state.userList = payload;
      // } else {
      //   state.userList = {
      //     ...payload,
      //     content: state.userList.content.concat(payload.content),
      //   };
      // }
    },
    // 초기화
    [RESET_USER_LIST]: state => {
      state.userList = initialState.userList;
      state.cameUserList = initialState.cameUserList;
    },
  },
});

export default point.reducer;
