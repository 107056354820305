export interface PagingParamTypes {
  page: number;
  size: number;
}

export interface PagingTypes {
  number: number;
  size: number;
  totalPages: number;
  totalElements: number;
}

export type GradeTpCdTypes = 'CLIMATE' | 'GREEN' | 'WAITING' | 'WELCOME';

export type LangTypes = 'KO' | 'EN' | string;

export type OauthTpCdTypes = 'APPLE' | 'EMAIL' | 'FACEBOOK' | 'GOOGLE';

export type YNType = 'Y' | 'N';

export const pagingInit = {
  content: [],
  number: 0,
  size: 10,
  totalPages: 0,
  totalElements: 0,
};

export interface SearchParamType {
  searchTp?: any;
  searchTxt?: string;
}

export interface OptionsType<T> {
  value: T;
  label: string;
  selectDisabled?: boolean;
}

export type ImgPrefixType =
  | 'bhvr'
  | 'bhvrgrp'
  | 'bhvrguide'
  | 'bhvrrecom'
  | 'chlng'
  | 'chlnggrp'
  | 'chlngguide'
  | 'editor';
