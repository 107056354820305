import React from 'react';
import ReactDOM from 'react-dom/client';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer, { rootSaga } from './modules';
import App from './App';
import createSagaMiddleWare from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { createLogger } from 'redux-logger';

// const logger = createLogger();
const saga = createSagaMiddleWare();
const middle =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(saga))
    : applyMiddleware(saga);
const store = createStore(rootReducer, middle);
const persist = persistStore(store);
saga.run(rootSaga);

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persist}>
      <App />
    </PersistGate>
  </Provider>,
);
