import React, { useEffect, useRef, useState } from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Membership from './Membership';
import ProfilePanelComponent from '../../../components/common/ProfilePanelComponent';
import ImgComponent from '../../../components/common/ImgComponent';
import { addComma, dtFormat } from '../../../utils/commonFunc';
import BarBtnComponent from '../../../components/common/BarBtnComponent';
import BtnComponent from '../../../components/common/BtnComponent';
import BtnPopupLayout from '../../BtnPopupLayout';
import { useDispatch, useSelector } from 'react-redux';
import PopupComponent from '../../../components/common/PopupComponent';
import { axiosFile, axiosGet, axiosPost } from '../../AppLayout';
import { getUserCnt } from '../../../modules/user/user';
import Point from './Point';
import Inquiry from './Inquiry';
import { RootState } from '../../../modules';
import Invite from './Invite';
import { setPersonalPageList } from '../../../modules/support/inquiry';
import { useTranslation } from 'react-i18next';
import Organization from '../../../layout/detailPopup/creator/Organization';
import ChangePwdComponent from '../../../components/common/ChangePwdComponent';

type Props = {
  defaultMenu: string;
  mmbrshpId?: number;
  orgYn?: string;
  display: boolean;
  mmbrId: number;
  onClickClose: () => void;
};

const CreatorDetailPop = ({
  mmbrId,
  mmbrshpId,
  orgYn,
  defaultMenu,
  display,
  onClickClose,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { profile, mmbrshp, list } = useSelector(({ user }: RootState) => user);
  // 공통
  const [menu, setMenu] = useState(defaultMenu);
  const [modal, setModal] = useState(false);
  const [modalText, setModalText] = useState('');

  const [reject, setReject] = useState(false);
  const [choose, setChoose] = useState(null);

  // 조직 이미지 설정
  const [file, setFile] = useState(null);
  const [imgInfo, setImgInfo] = useState<any>({
    url: profile?.profileImg,
    name: null,
    size: null,
  });

  const [info, setInfo] = useState({
    mmbrId: 0,
    profileImgUrl: '',
    bussinessNo: '',
    mngEmail: '',
    mngNm: '',
    mngTelNo: '',
    orgAddr: '',
    organCateCd: '',
    orgAddrDetail: '',
    orgNm: '',
    useYn: 'Y',
  });

  const [saveM, setSaveM] = useState(false);
  const [errM, setErrM] = useState(false);
  const [errTxt, setErrTxt] = useState('');

  // Validate 체크
  const validateCheck = () => {
    /* 그룹 명 */
    if (!info.orgNm || info.orgNm.trim() === '') {
      setErrTxt(t('orgregist.errtxt.groupname'));
      setSaveM(false);
      return false;
    }

    return true;
  };

  // 수정
  const onSave = async () => {
    const sendData = {
      mmbrId: mmbrId,
      profileImg: imgInfo?.url || '',
      bussinessNo: info?.bussinessNo,
      mngEmail: info?.mngEmail,
      mngNm: info?.mngNm,
      mngTelNo: info?.mngTelNo,
      orgAddr: info?.orgAddr,
      organCateCd: info?.organCateCd,
      orgAddrDetail: info?.orgAddrDetail,
      orgNm: info?.orgNm,
      useYn: info?.useYn,
    };

    axiosFile('/user/org/modify', file ? { ...sendData, file } : sendData)
      .then(res => {
        if (res.resultCd === process.env.REACT_APP_CODE_SUC) onClose();
        else {
          setSaveM(false);
          setErrTxt(t(res.resultMsg));
          setErrM(!errM);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const onConfirm = () => {
    axiosPost('/user/mmbrshp/confirm', null, { mmbrshpId })
      .then(res => {
        if (res.resultCd === process.env.REACT_APP_CODE_SUC) {
          dispatch(getUserCnt());
          setModal(!modal);
          onClose();
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const onClose = () => {
    setMenu(defaultMenu); // 메뉴 초기화
    setReject(false);
    setChoose(null);
    setImgInfo({
      url: profile?.profileImg,
      name: null,
      size: null,
    });
    setFile(null);
    setSaveM(false);
    setErrM(false);
    setErrTxt('');
    onClickClose();
    dispatch(setPersonalPageList([]));
  };

  useEffect(() => {
    setImgInfo({ url: profile?.profileImg || null, name: null, size: null });
  }, [profile]);

  useEffect(() => {
    setMenu(defaultMenu);
  }, [defaultMenu]);

  return (
    <PopupComponent display={display} onClose={() => onClose()}>
      <div className={'pop detail-pop'}>
        {/* 프로필 판넬 */}
        <ProfilePanelComponent
          id={mmbrId}
          orgYn={orgYn}
          setFile={setFile}
          imgInfo={imgInfo}
          setImgInfo={setImgInfo}
        />

        {/* 후시 포인트 현황 */}
        <div className={'detail-main'}>
          <div className={'point'}>
            {/* TODO: 2차 */}
            {/*<ImgComponent class={"m-r-5"} src={"coin.svg"} />*/}
            {/*<div className={"center m-r-20"}>{addComma(2123)}</div>*/}

            <ImgComponent className={'m-r-5'} src={'coin_leaf.svg'} />
            <div className={'center'}>
              {addComma(profile?.point)}
              <FontAwesomeIcon
                style={{ marginLeft: '5px' }}
                icon={faAngleRight}
              />
            </div>
          </div>

          {/* 상단 버튼 */}
          <div className="h-625 m-b-30">
            <BarBtnComponent
              btn={[
                {
                  on: menu === 'membership',
                  onClick: () => setMenu('membership'),
                  txt:
                    profile.orgYn === 'N'
                      ? t('creatordetailpop.barbtn.membership')
                      : t('creatordetailpop.barbtn.group'),
                },
                {
                  on: menu === 'point',
                  onClick: () => setMenu('point'),
                  txt: t('creatordetailpop.barbtn.point'),
                },
                {
                  on: menu === 'cero',
                  onClick: () => setMenu('cero'),
                  txt: t('creatordetailpop.barbtn.cero'),
                },
                {
                  on: menu === 'inquiry',
                  onClick: () => setMenu('inquiry'),
                  txt: t('creatordetailpop.barbtn.inquiry'),
                },
                {
                  on: menu === 'invite',
                  onClick: () => setMenu('invite'),
                  txt: t('creatordetailpop.barbtn.invite'),
                },
                {
                  on: menu === 'changepwd',
                  onClick: () => setMenu('changepwd'),
                  txt: '비밀번호 변경',
                },
              ]}
            />

            {/* 멤버십내역 */}
            {menu === 'membership' && profile.orgYn === 'N' && (
              <Membership id={mmbrshpId || 0} reject={reject} />
            )}
            {menu === 'membership' && profile.orgYn === 'Y' && (
              <Organization id={mmbrId} info={info} setInfo={setInfo} />
            )}

            {/* 리워드내역 */}
            {menu === 'point' && <Point id={mmbrId} paymentTp={'point'} />}
            {menu === 'cero' && <Point id={mmbrId} paymentTp={'cero'} />}

            {/* 1:1문의 */}
            {menu === 'inquiry' && <Inquiry id={mmbrId} />}

            {/* 친구초대 */}
            {menu === 'invite' && <Invite id={mmbrId} />}
            {/* 비밀번호 변경 */}
            {menu === 'changepwd' && (
              <ChangePwdComponent id={mmbrId} email={profile.email} />
            )}
          </div>

          {/* 하단 정보 및 버튼 */}
          <div className={'flex justify-between'}>
            <div>
              <span className={'color-sub m-r-10'}>
                {t('creatordetailpop.bottominfo.daytext')}
              </span>
              <span className={'color-b295 m-r-20'}>
                {profile?.loginDays === (0 || null)
                  ? '0'
                  : addComma(profile?.loginDays)}
                {t('creatordetailpop.bottominfo.day')}
              </span>
              <span className={'color-sub m-r-10'}>
                {t('creatordetailpop.bottominfo.regtext')}
              </span>
              <span>
                {dtFormat(profile?.regDt, 'date')}{' '}
                <span className={'color-sub m-r-20'}>
                  {dtFormat(profile?.regDt, 'time')}
                </span>
              </span>
              <span className={'color-sub m-r-10'}>
                {t('creatordetailpop.bottominfo.recenttext')}
              </span>
              <span>
                {dtFormat(profile?.loginDttm, 'date')}{' '}
                <span className={'color-sub m-r-20'}>
                  {dtFormat(profile?.loginDttm, 'time')}
                </span>
              </span>
            </div>

            <div className={'btn-wrap'} style={{ width: '325px' }}>
              {menu === 'membership' && orgYn === 'N' && (
                <>
                  {reject ? (
                    <>
                      <BtnComponent
                        className={'btn-close'}
                        txt={t('creatordetailpop.btn.back')}
                        onClick={() => setReject(!reject)}
                      />
                      <BtnComponent
                        className={'btn-main'}
                        txt={t('creatordetailpop.btn.send')}
                        onClick={() => {
                          setModalText(t('creatordetailpop.modaltext.sned'));
                          setModal(!modal);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <BtnComponent
                        className={'btn-close'}
                        txt={t('creatordetailpop.btn.close')}
                        onClick={onClose}
                      />
                      {mmbrshpId && (
                        <>
                          {/* 승인 상태일떄만 반려버튼 표출 */}
                          {mmbrshp?.mmbrshpStateCd === 'NORMAL' && (
                            <BtnComponent
                              className={'btn-reject'}
                              txt={t('creatordetailpop.btn.reject')}
                              onClick={() => setReject(!reject)}
                            />
                          )}
                          {mmbrshp?.mmbrshpStateCd !== 'NORMAL' && (
                            <BtnComponent
                              className={'btn-main'}
                              txt={t('creatordetailpop.btn.approval')}
                              onClick={() => {
                                setModalText(
                                  t('creatordetailpop.modaltext.approval'),
                                );
                                setModal(!modal);
                              }}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {(menu === 'content' ||
                (menu === 'reply' && choose !== null)) && (
                <>
                  <BtnComponent
                    className={'btn-close'}
                    txt={t('creatordetailpop.btn.cancel')}
                    onClick={onClose}
                  />
                  <BtnComponent
                    className={'btn-reject'}
                    txt={t('creatordetailpop.btn.hide')}
                    onClick={() => {
                      setModal(!modal);
                      setModalText(t('creatordetailpop.modaltext.hide'));
                    }}
                  />
                  <BtnComponent
                    className={'btn-main'}
                    txt={t('creatordetailpop.btn.confirm')}
                    onClick={onClose}
                  />
                </>
              )}

              {((menu !== 'membership' &&
                menu !== 'content' &&
                menu !== 'reply') ||
                (menu === 'reply' && choose === null)) && (
                <>
                  <BtnComponent
                    className={'btn-close'}
                    txt={t('creatordetailpop.btn.close')}
                    onClick={onClose}
                  />
                </>
              )}

              {menu === 'membership' && orgYn === 'Y' && (
                <div className={'btn-wrap'}>
                  <BtnComponent
                    className={'btn-main'}
                    txt={t('orgregist.btn.modify')}
                    onClick={() => {
                      if (validateCheck()) {
                        setSaveM(!saveM);
                      }
                    }}
                  />
                  <BtnComponent
                    className={'btn-close'}
                    txt={t('creatordetailpop.btn.close')}
                    onClick={onClose}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <BtnPopupLayout
          display={modal}
          content={modalText}
          btn={[
            {
              txt: t('creatordetailpop.btn.cancel'),
              class: 'btn-close',
              onClick: () => setModal(!modal),
            },
            {
              txt: t('creatordetailpop.btn.confirm'),
              class: 'btn-main',
              onClick: () => onConfirm(),
            },
          ]}
        />

        <BtnPopupLayout
          display={saveM}
          content={t('orgregist.btn.updatetext')}
          btn={[
            {
              txt: t('orgregist.btn.cancel'),
              class: 'btn-close',
              onClick: () => setSaveM(!saveM),
            },
            {
              txt: t('orgregist.btn.confirm'),
              class: 'btn-main',
              onClick: () => onSave(),
            },
          ]}
          onClose={() => {
            setSaveM(!saveM);
          }}
        />

        <BtnPopupLayout
          display={errM}
          content={errTxt}
          btn={[
            {
              txt: t('orgregist.btn.errconfirm'),
              class: 'btn-main',
              onClick: () => setErrM(!errM),
            },
          ]}
          onClose={() => setErrM(!errM)}
        />
      </div>
    </PopupComponent>
  );
};

export default CreatorDetailPop;
