import moment from 'moment';
import { RefObject } from 'react';

export function addComma(num: number) {
  if (num !== null && num)
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  else return num;
}

export function changeSizeUnit(size: number) {
  let str;
  let sizeStr;

  //MB 단위 이상일때 MB 단위로 환산
  if (size >= 1024 * 1024) {
    size = size / (1024 * 1024);
    sizeStr = size.toFixed(2);
    str = addComma(Number(sizeStr)) + 'MB';
  }
  //KB 단위 이상일때 KB 단위로 환산
  else if (size >= 1024) {
    size = size / 1024;
    sizeStr = size.toFixed(2);
    str = addComma(Number(sizeStr)) + 'KB';
  }
  //KB 단위보다 작을때 byte 단위로 환산
  else {
    sizeStr = size.toFixed(2);
    str = addComma(Number(sizeStr)) + 'byte';
  }

  return str;
}

export function dtFormat(dt: string, type?: 'date' | 'time'): string {
  if (dt === '' || dt === null) return '';
  return type === 'date'
    ? moment(dt).format('yyyy.MM.DD')
    : type === 'time'
    ? moment(dt).format('HH:mm:ss')
    : moment(dt).format('yyyy.MM.DD HH:mm:ss');
}

export const stringToDate = (str: string) => {
  return new Date(str);
};

export const encodeFileToBase64 = (
  file: any,
  func: (reader: FileReader) => void,
) => {
  const reader = new FileReader();
  if (file !== undefined) {
    reader.readAsDataURL(file);
  }

  return new Promise<void>(resolve => {
    reader.onload = () => {
      func && func(reader);
      resolve();
    };
  });
};

export function ifntScroll(
  ref: RefObject<HTMLElement>,
  list: any[],
  size: number,
  func: () => void,
) {
  if (ref.current) {
    const { scrollTop, scrollHeight, clientHeight } = ref.current;
    if (scrollTop + clientHeight >= scrollHeight) {
      list.length === size && func();
    }
  }
}

export function sliceWord(word: string, size: number, separator: string) {
  if (word) {
    const length = word.length;
    const slice = [];

    for (let i = 0; i < length; i += size) {
      slice.push(word.substring(i, i + size));
    }

    return slice.join(separator);
  }
}

export const fileDownload = async (fileUrl: string, fileName?: string) => {
  const name = fileUrl.split('/');
  await fetch(fileUrl, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then(res => {
      return res.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName || name[name.length - 1];
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });
};

export const emailRegex = (e: string) => {
  const regex = /^[_a-zA-Z0-9-+_.]+@[a-zA-Z0-9-\.]+(\.[a-zA-Z]{2,3})$/;
  return regex.test(e);
};

export const getStartDt = (dt: Date) => {
  return new Date(
    dt.getFullYear(),
    dt.getMonth(),
    dt.getDate(),
    0,
    0,
    0,
  ).toISOString();
};

export const getEndDt = (dt: Date) => {
  return new Date(
    dt.getFullYear(),
    dt.getMonth(),
    dt.getDate(),
    23,
    59,
    59,
  ).toISOString();
};

export const openImageInNewTab = (images: string[], w = 1200, h = 760) => {
  // 화면의 넓이와 높이 가져오기
  const width =
    window.innerWidth || document.documentElement.clientWidth || screen.width;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    screen.height;

  // 새 창이 화면 가운데에 위치하도록 계산
  const left = width / 2 - w / 2;
  const top = height / 2 - h / 2;

  const newWindow = window.open(
    '',
    '_blank',
    'width = ' + w + ', height = ' + h + ', top = ' + top + ', left = ' + left,
  );
  if (newWindow) {
    newWindow.document.write(`
      <html>
        <head>
          <title>Slideshow</title>
          <link rel="stylesheet" href="https://unpkg.com/swiper/swiper-bundle.min.css">
          <link rel="stylesheet" href="/admin-web/css/popupimage.css">         
        </head>
        <body>
          <div class="swiper-container">
            <div class="swiper-wrapper">
              ${images
                .map(
                  imageUrl => `
                <div class="swiper-slide">
                  <div class="swiper-zoom-container">
                  <img src="${imageUrl}" alt="story">
                  </div>
                </div>
              `,
                )
                .join('')}
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
          <script src="https://unpkg.com/swiper/swiper-bundle.min.js"></script>
          <script>
            var swiper = new Swiper('.swiper-container', {
              loop: true,
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
              zoom: {
                maxRatio: 5,                
              },
            });
          </script>
        </body>
      </html>
    `);
  } else {
    console.error('Popup blocked or could not be opened.');
  }
};
