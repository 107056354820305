import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../utils/createReqSaga';
import { axiosGet } from '../../layout/AppLayout';
import {
  StoryApiType,
  StoryCountType,
  StoryListApiType,
} from '../../types/story/storyReduxType';
import {
  StoryCntApiType,
  StoryListParamType,
} from '../../types/story/storyParamType';

const prefix = 'story';

const GET_CNT = prefix + '/GET_CNT';
const GET_LIST = prefix + '/GET_LIST';
const GET_STORY = prefix + '/GET_STORY';

export const getStoryCnt = createAction<StoryCntApiType>(GET_CNT);
export const getStoryList = createAction<StoryListParamType>(GET_LIST);
export const getStory = createAction<number>(GET_STORY);

const getStoryCntApi = (sendData: StoryCntApiType) =>
  axiosGet('/story/count', sendData);
const getStoryListApi = (sendData: StoryListParamType) =>
  axiosGet('/story/list', sendData);
const getStoryApi = (sendData: number) => axiosGet('/story/' + sendData);

const getStoryCntSaga = createRequestSaga(GET_CNT, getStoryCntApi);
const getStoryListSaga = createRequestSaga(GET_LIST, getStoryListApi);
const getStoryApiSaga = createRequestSaga(GET_STORY, getStoryApi);

export function* storySaga() {
  yield takeLatest(GET_CNT, getStoryCntSaga);
  yield takeLatest(GET_LIST, getStoryListSaga);
  yield takeLatest(GET_STORY, getStoryApiSaga);
}

interface initialStateType {
  count: StoryCountType;
  list: StoryListApiType;
  story: StoryApiType;
}

const initialState: initialStateType = {
  count: {
    all: 0,
    active: 0,
    waiting: 0,
    inActive: 0,
    reject: 0,
  },
  list: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  story: {
    mmbrId: 0,
    storyStatCd: 'ACTIVE',
    storyTpCd: 'STORY',
    feedTpCd: 'TEXT',
    actTlId: 0,
    storyCntn: '',
    linkUrl: '',
    profileImg: null,
    nickname: null,
    userProfileImg: null,
    userNickname: null,
    afltn: null,
    files: [],
    replies: [],
    certifyRes: null,
    inactiveRsn: '',
    mainImgUrl: '',
  },
};

const story = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: {
    [GET_CNT + '_SUC']: (state, action: PayloadAction<StoryCountType>) => {
      console.log(action.payload);
      state.count = action.payload;
    },
    [GET_LIST + '_SUC']: (
      state,
      { payload: res }: PayloadAction<StoryListApiType>,
    ) => {
      state.list = res;
    },
    [GET_STORY + '_SUC']: (state, action: PayloadAction<StoryApiType>) => {
      state.story = action.payload;
    },
  },
});

export default story.reducer;
