import ImgComponent from './ImgComponent';
import React from 'react';

type Props = {
  src?: string | undefined | null;
  className?: string;
};
const CircleProfileImgComponent = ({ src, className }: Props) => {
  return (
    <>
      {src && src !== 'null' ? (
        <img className={'circle-img ' + className} src={src} alt={'profile'} />
      ) : (
        <div className={'center circle-img p-lr-6'}>
          <ImgComponent className={className} src={'leaf.svg'} />
        </div>
      )}
    </>
  );
};

export default CircleProfileImgComponent;
