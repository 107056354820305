import { takeLatest } from 'redux-saga/effects';
import { axiosExcel, axiosGet } from '../../layout/AppLayout';
import createRequestSaga from '../../utils/createReqSaga';
import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  ExcelOrgListType,
  ExcelUserListType,
  MembershipType,
  MmbrOrgType,
  MmbrshpStateCdType,
  UserCountType,
  UserListType,
  UserNameType,
  UserProfileType,
} from '../../types/user/userReduxType';
import {
  ExcelUserListParamType,
  UserListParamType,
  UserNameListParamType,
} from '../../types/user/userParamType';
import { dtFormat, getEndDt, getStartDt } from '../../utils/commonFunc';

const prefix = 'user';

const GET_CNT = prefix + '/GET_CNT';
const GET_LIST = prefix + '/GET_LIST';
const GET_PROFILE = prefix + '/GET_PROFILE';
const GET_MMBRSHP = prefix + '/GET_MMBRSHP';
const GET_NAME_LIST = prefix + '/GET_NAME_LIST';
const GET_ORG = prefix + '/GET_ORG';
const GET_EXCEL_LIST = prefix + '/GET_EXCEL_LIST';
const GET_ORG_EXCEL_LIST = prefix + '/GET_ORG_EXCEL_LIST';
// 초기화
const RESET_LIST = prefix + '/RESET_LIST';

export const getUserCnt = createAction(GET_CNT);
export const getUserList = createAction<UserListParamType>(GET_LIST);
export const getUserProfile = createAction<number>(GET_PROFILE);
export const getUserMmbrshp = createAction<number>(GET_MMBRSHP);
export const getUserNameList =
  createAction<UserNameListParamType>(GET_NAME_LIST);
export const getUserOrg = createAction<number>(GET_ORG);
export const getExcelUserList =
  createAction<ExcelUserListParamType>(GET_EXCEL_LIST);
export const getExcelOrgList =
  createAction<ExcelUserListParamType>(GET_ORG_EXCEL_LIST);
//초기화
export const resetList = createAction(RESET_LIST);

const getUserCntApi = () => axiosGet('/user/count');
const getUserListApi = (sendData: UserListParamType) =>
  axiosGet('/user/list', sendData);
const getUserProfileApi = (sendData: number) =>
  axiosGet('/user/profile/' + sendData);
const getUserMmbrshpApi = (sendData: number) =>
  axiosGet('/user/mmbrshp/' + sendData);
const getUserNameListApi = (sendData: UserListParamType) =>
  axiosGet('/user/list/name', sendData);
const getUserOrgApi = (sendData: number) => axiosGet('/user/org/' + sendData);
const getExcelUserListApi = async (sendData: ExcelUserListParamType) => {
  const response = await axiosExcel('/user/excellist', sendData);
  const data = new Blob([response], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', sendData.fileName);
  document.body.appendChild(link);
  link.click();
};
const getExcelOrgListApi = async (sendData: ExcelUserListParamType) => {
  const response = await axiosExcel('/user/excelorglist', sendData);
  const data = new Blob([response], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', sendData.fileName);
  document.body.appendChild(link);
  link.click();
};

const getUserCntSaga = createRequestSaga(GET_CNT, getUserCntApi);
const getUserListSaga = createRequestSaga(GET_LIST, getUserListApi);
const getUserProfileSaga = createRequestSaga(GET_PROFILE, getUserProfileApi);
const getUserMmbrshpSaga = createRequestSaga(GET_MMBRSHP, getUserMmbrshpApi);
const getUserNameListSaga = createRequestSaga(
  GET_NAME_LIST,
  getUserNameListApi,
);
const getUserOrgSaga = createRequestSaga(GET_ORG, getUserOrgApi);
const getExcelUserListSaga = createRequestSaga(
  GET_EXCEL_LIST,
  getExcelUserListApi,
);
const getExcelOrgListSaga = createRequestSaga(
  GET_ORG_EXCEL_LIST,
  getExcelOrgListApi,
);

export function* userSaga() {
  yield takeLatest(GET_CNT, getUserCntSaga);
  yield takeLatest(GET_LIST, getUserListSaga);
  yield takeLatest(GET_PROFILE, getUserProfileSaga);
  yield takeLatest(GET_MMBRSHP, getUserMmbrshpSaga);
  yield takeLatest(GET_NAME_LIST, getUserNameListSaga);
  yield takeLatest(GET_ORG, getUserOrgSaga);
  yield takeLatest(GET_EXCEL_LIST, getExcelUserListSaga);
  yield takeLatest(GET_ORG_EXCEL_LIST, getExcelOrgListSaga);
}

interface Types {
  count: UserCountType;
  list: UserListType;
  profile: UserProfileType;
  mmbrshp: MembershipType;
  mmbrorg: MmbrOrgType;
  search: {
    searching: boolean;
    searchTp: string;
    searchTxt: string;
    startDt: string;
    endDt: string;
  };
  nameList: UserNameType[];
  excelUserList: ExcelUserListType[];
  excelOrgList: ExcelOrgListType[];
  mmbrshpStateCd: MmbrshpStateCdType;
  sortField: string;
  sortOrder: string;
  orgYn: string;
  wtdYn: string;
  file: any;
}

const initialState: Types = {
  count: {
    all: 0,
    normal: 0,
    waiting: 0,
    stop: 0,
    reject: 0,
  },
  list: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  profile: {
    mmbrId: 0,
    nickname: '',
    email: '',
    mainLang: '',
    profileImg: '',
    regDt: '',
    gradCd: '',
    mmbrshpNum: '',
    stateMsg: '',
    loginDttm: '',
    report: 0,
    loginDays: 0,
    subscribe: 0,
    subscriber: 0,
    view: 0,
    certify: 0,
    deviceOs: '',
    deviceName: '',
    jobs: [],
    point: 0,
    wtdYn: 'N',
    lvlCd: '',
    exp: 0,
    cero: 0,
    orgMmbrId: 0,
    orgNm: '',
    orgYn: '',
    orgUseYn: '',
    belong1: { mmbrId: 0, order: 1, orgMmbrId: 0, orgNm: '' },
    belong2: { mmbrId: 0, order: 1, orgMmbrId: 0, orgNm: '' },
    belong3: { mmbrId: 0, order: 1, orgMmbrId: 0, orgNm: '' },
    belongList: [],
  },
  mmbrshp: {
    mmbrshpId: 0,
    nickname: '',
    afltn: '',
    stateMsg: '',
    mmbrshpStateCd: '',
    mmbrshpStateDesc: '',
    rjctRsn: '',
    frstRgstDttm: '',
    fnlModDttm: '',
    confirmDt: '',
    snsLinks: [],
    articles: [],
  },
  mmbrorg: {
    mmbrId: 0,
    profileImgUrl: '',
    orgNm: '',
    bussinessNo: '',
    orgAddr: '',
    organCateCd: '',
    organCateCdMsgCode: '',
    orgAddrDetail: '',
    mngNm: '',
    mngTelNo: '',
    mngEmail: '',
    useYn: 'Y',
  },
  search: {
    searching: false,
    searchTp: 'nickname',
    searchTxt: '',
    startDt: getStartDt(new Date()),
    endDt: getEndDt(new Date()),
  },
  nameList: [],
  excelUserList: [],
  excelOrgList: [],
  mmbrshpStateCd: 'ALL',
  sortField: '',
  sortOrder: '',
  orgYn: 'N',
  wtdYn: 'N',
  file: null,
};

const user = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setSearching: (state, action: PayloadAction<boolean>) => {
      state.search.searching = action.payload;
    },
    setSearchTp: (state, action: PayloadAction<string>) => {
      state.search.searchTp = action.payload;
    },
    setSearchTxt: (state, action: PayloadAction<string>) => {
      state.search.searchTxt = action.payload;
    },
    setMmbrshpStateCd: (state, action: PayloadAction<MmbrshpStateCdType>) => {
      state.mmbrshpStateCd = action.payload;
    },
    setMmbrorgYn: (state, action: PayloadAction<string>) => {
      state.orgYn = action.payload;
    },
    setStartDt: (state, action: PayloadAction<string>) => {
      state.search.startDt = action.payload;
    },
    setEndDt: (state, action: PayloadAction<string>) => {
      state.search.endDt = action.payload;
    },
    setNameList: (state, { payload }: PayloadAction<UserNameType[]>) => {
      state.nameList = payload;
    },
    setFile: (state, action) => {
      state.file = action.payload;
    },
  },
  extraReducers: {
    [GET_CNT + '_SUC']: (state, { payload }: PayloadAction<UserCountType>) => {
      state.count = payload;
    },
    [GET_LIST + '_SUC']: (state, { payload }: PayloadAction<UserListType>) => {
      state.list = payload;
    },
    [GET_PROFILE + '_SUC']: (
      state,
      { payload }: PayloadAction<UserProfileType>,
    ) => {
      state.profile = payload;
    },
    [GET_MMBRSHP + '_SUC']: (
      state,
      { payload }: PayloadAction<MembershipType>,
    ) => {
      state.mmbrshp = payload;
    },
    [GET_NAME_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<UserNameType[]>,
    ) => {
      state.nameList = payload;
    },
    [GET_ORG + '_SUC']: (state, { payload }: PayloadAction<MmbrOrgType>) => {
      state.mmbrorg = payload;
    },
    [GET_EXCEL_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<ExcelUserListType[]>,
    ) => {
      state.excelUserList = payload;
    },
    [GET_ORG_EXCEL_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<ExcelOrgListType[]>,
    ) => {
      state.excelOrgList = payload;
    },
    [RESET_LIST]: state => {
      state.list = initialState.list;
    },
  },
});

export default user.reducer;
export const {
  setSearchTp,
  setSearchTxt,
  setSearching,
  setMmbrshpStateCd,
  setMmbrorgYn,
  setStartDt,
  setEndDt,
  setNameList,
  setFile,
} = user.actions;

export const initialProfile: UserProfileType = initialState.profile;
