import React from 'react';
import BtnComponent from '../components/common/BtnComponent';
import SelectLanguage from '../components/common/SelectLanguage';
import { useDispatch } from 'react-redux';
import { doLogin, setAdminAuth } from '../modules/login/login';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  tabMenu?: {
    focus: boolean;
    txt: string;
    onClick?: () => void;
  }[];
  children?: React.ReactNode;
};
const HeaderLayout = ({ title, children, tabMenu }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className={'main-title'}>
      <h2>{title}</h2>
      <div className={'flex'}>
        {tabMenu && (
          <div className={'w-300'}>
            <div className={'header btn-wrap m-r-20'}>
              {tabMenu.map((item, idx) => (
                <BtnComponent
                  key={idx}
                  className={item.focus ? 'on' : ''}
                  txt={item.txt}
                  onClick={item.onClick ? item.onClick : undefined}
                />
              ))}
            </div>
          </div>
        )}
        {children}
        <div className={'m-l-20'}>
          <SelectLanguage />
        </div>
        <div className={'m-l-20'}>
          <BtnComponent
            className={'btn-sub w-70 on'}
            img={''}
            txt={t('common.btn.logout')}
            onClick={() => {
              dispatch(doLogin(''));
              dispatch(setAdminAuth(''));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderLayout;
