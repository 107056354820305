import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosGet } from '../../layout/AppLayout';
import createRequestSaga from '../../utils/createReqSaga';
import { takeLatest } from 'redux-saga/effects';
import { MetaCdType } from '../../types/behavior/behaviorReduxType';

const prefix = 'meta';
const GET_META_LIST = prefix + '/GET_META_LIST';

export const getMetaList = createAction(GET_META_LIST);

const getMetaListApi = () => axiosGet('/behavior/meta/list');

const getMetaListSaga = createRequestSaga(GET_META_LIST, getMetaListApi);

export function* metaSaga() {
  yield takeLatest(GET_META_LIST, getMetaListSaga);
}

interface InitialStateType {
  metaList: MetaCdType[];
}

const initialState: InitialStateType = {
  metaList: [],
};

const meta = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: {
    [GET_META_LIST + '_SUC']: (state, action: PayloadAction<MetaCdType[]>) => {
      state.metaList = action.payload;
    },
  },
});

export default meta.reducer;
