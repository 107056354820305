import React, {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

import BtnComponent from '../../components/common/BtnComponent';
import DatePickerComponent from '../../components/common/DatePickerComponent';
import DataGridComponent from '../../components/common/DataGridComponent';
import ReportDetailPop from '../../layout/detailPopup/ReportDetailPop';
import { axiosExcel, axiosGet } from '../../layout/AppLayout';
import { ReportListParamType } from '../../types/report/reportParamType';
import { ReportListType } from '../../types/report/reportReduxType';
import HeaderLayout from '../../layout/HeaderLayout';
import SearchFormComponent from '../../components/common/SearchFormComponent';
import { setSearch } from '@modules/support/support';

interface Props {
  setMenu: Dispatch<SetStateAction<'inquiry' | 'faq' | 'report'>>;
  menu: 'inquiry' | 'faq' | 'report';
}
const ReportList = ({ setMenu, menu }: Props) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0); //검색 조건
  const [size, setSize] = useState(20); //검색 조건
  const [rprtStatCd, setRprtStatCd] = useState(''); //검색 조건
  const [rprtTpCd, setRprtTpCd] = useState(''); //검색 조건
  const [searchTp, setSearchTp] = useState('mmbrNickname'); //검색 조건
  const [searchTxt, setSearchTxt] = useState(''); //검색 조건
  const [searchStartDt, setSearchStartDt] = useState(
    moment().subtract(7, 'days').startOf('day').format('YYYY.MM.DD HH:mm:ss'),
  ); // 첫로딩시 오늘기준 1주전
  const [searchEndDt, setSearchEndDt] = useState(
    moment().endOf('day').format('YYYY.MM.DD HH:mm:ss'),
  ); // 오늘

  //신고 내역 목록
  const [reportList, setReportList] = useState<ReportListType>();

  // Param Data
  const sendData = {
    page: page || 0,
    size: size || 20,
    searchTp: searchTp || '',
    searchTxt: searchTxt || '',
    fileName: t('report.main.title'),
    rprtStatCd: rprtStatCd || '',
    rprtTpCd: rprtTpCd || '',
    reportStartDt: searchStartDt || '',
    reportEndDt: searchEndDt || '',
  };

  // 신고 내역 목록 조회
  const getReportList = async (sendData: ReportListParamType) => {
    return await axiosGet(`/report/list/`, sendData)
      .then(rep => {
        setReportList(rep.result);
      })
      .catch(e => {
        setReportList(e.getMessage());
      });
  };
  // 신고 목록 엑셀 다운
  const getExcelReportListApi = async (sendData: any) => {
    const response = await axiosExcel('/report/excel/list', sendData);
    const data = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', sendData.fileName);
    document.body.appendChild(link);
    link.click();
  };
  // 엑셀 다운로드 이벤트.
  const onExcelDownload = useCallback(() => {
    getExcelReportListApi(sendData);
  }, [sendData]);

  const onSearch = () => {
    getReportList(sendData);
  };

  useEffect(() => {
    getReportList(sendData);
  }, []);

  // 신고 내역 상세 팝업 ON/OFF
  const [reportModal, setReportModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // 데이터 그리드 RowInfo
  const columns = [
    {
      field: 'mmbrEmail',
      headerName: t('report.datagrid.mmbremail'),
      cellClassName: 'color-sub',
      flex: 1,
    },
    {
      field: 'mmbrNickname',
      headerName: t('report.datagrid.mmbrnickname'),
      cellClassName: 'color-sub',
      flex: 1,
    },
    {
      field: 'rprtTrgtMmbrEmail',
      headerName: t('report.datagrid.rprttrgtemail'),
      cellClassName: 'color-sub',
      flex: 1,
    },
    {
      field: 'rprtTrgtMmbrNickname',
      headerName: t('report.datagrid.rprttrgtnickname'),
      cellClassName: 'color-sub',
      flex: 1,
    },
    {
      field: 'rprtTpCdMsgCode',
      headerName: t('report.datagrid.rprttp'),
      cellClassName: 'color-sub',
      flex: 1,
    },
    {
      field: 'rprtRsn',
      headerName: t('report.datagrid.rprtrsn'),
      cellClassName: 'color-sub',
      flex: 1,
    },
    {
      field: 'rprtStatCdMsgCode',
      headerName: t('report.datagrid.rprtstat'),
      cellClassName: 'color-sub',
      flex: 1,
    },
    {
      field: 'regDtYmdHms',
      headerName: t('report.datagrid.regdt'),
      cellClassName: 'color-sub',
      flex: 1,
    },
  ];
  return (
    <main>
      <div className={'dashboard-layout'}>
        <div className={'title'}>
          <h4>{t('report.main.title')}</h4>
        </div>
        <div className={'flex justify-between'}>
          <div className={'userManage btn-wrap'}></div>
          <div className={'btn-wrap'}>
            <DatePickerComponent
              format={'yyyy.MM.dd'}
              value={searchStartDt}
              onChange={e => {
                setSearchStartDt(
                  moment(e).startOf('day').format('YYYY.MM.DD HH:mm:ss'),
                );
              }}
              maxDate={moment(searchEndDt).toDate()}
              boxClass={'flex search-input m-t-0'}
              placeholder={'시작일'}
            />
            ~
            <DatePickerComponent
              format={'yyyy.MM.dd'}
              value={searchEndDt}
              onChange={e => {
                setSearchEndDt(
                  moment(e).endOf('day').format('YYYY.MM.DD HH:mm:ss'),
                );
              }}
              minDate={moment(searchStartDt).toDate()}
              boxClass={'flex search-input m-t-0'}
              placeholder={'종료일'}
            />
            <BtnComponent
              className={'btn-auto btn-main'}
              txt={t('report.btn.excel')}
              onClick={onExcelDownload}
            />
          </div>
        </div>
        <DataGridComponent
          prependNo={true}
          totalRows={reportList?.totalElements || 0}
          getRowId={'rprtId'}
          columns={columns}
          data={reportList?.content || []}
          page={reportList?.number || 0}
          totalPage={reportList?.totalPages || 1}
          size={reportList?.size || 10}
          onChangePerRow={size => {
            setSize(size);
            const sendData = {
              page: 0,
              size: size,
              searchTp: searchTp,
              searchTxt: searchTxt,
              reportStartDt: searchStartDt,
              reportEndDt: searchEndDt,
            };
            getReportList(sendData);
          }}
          onChangePage={page => {
            setPage(page);
            const sendData = {
              page: page,
              size: 10,
              searchTp: searchTp,
              searchTxt: searchTxt,
              reportStartDt: searchStartDt,
              reportEndDt: searchEndDt,
            };
            getReportList(sendData);
          }}
          onRowClick={params => {
            setSelectedRow(params.row);
            setReportModal(!reportModal);
          }}
          check={false}
        />
      </div>
      {/* 신고 내역 정보 */}
      <ReportDetailPop
        display={reportModal}
        onClickClose={() => setReportModal(!reportModal)}
        selectedRowInfo={selectedRow}
        setSelectedRowInfo={setSelectedRow}
      />
    </main>
  );
};

export default ReportList;
