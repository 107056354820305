import React, { Component } from 'react';
import CountBtnComponent from '../../common/CountBtnComponent';
import DataGridComponent from '../../common/DataGridComponent';
import {
  getFaqList,
  setFaqId,
  setFaqModal,
} from '../../../modules/support/faq';
import FaqDetailPop from '../../../layout/detailPopup/FaqDetailPop';
import { dtFormat } from '../../../utils/commonFunc';
import BtnComponent from '../../common/BtnComponent';
import { useTranslation } from 'react-i18next';

const FaqMainCateListSection = () => {
  const { t } = useTranslation();
  const columns = [
    {
      field: 'faqId',
      headerName: t('FaqMainCateListSection.datagrid.faqid'),
      headerAlign: 'center',
      align: 'center',
      width: 50,
    },
    {
      field: 'pcateIdName',
      headerName: t('FaqMainCateListSection.datagrid.pcateidname'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
    },
    {
      field: 'ccateIdName',
      headerName: t('FaqMainCateListSection.datagrid.ccateidname'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
    },
    {
      field: 'title',
      headerName: t('FaqMainCateListSection.datagrid.title'),
      headerAlign: 'left',
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div className={'grid-cell'}>
            <p className={'color-main'}>{params.row.title}</p>
            {/*<h5 className={'color-sub text-ellipsis text-row-2'}>*/}
            {/*  {params.row.ctnt}*/}
            {/*</h5>*/}
          </div>
        );
      },
    },
    {
      field: 'dt',
      headerName: t('FaqMainCateListSection.datagrid.dt'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
      renderCell: (params: any) => {
        return params.row.alwsExpsrYn === 'Y' ? (
          <div>{t('FaqMainCateListSection.datagrid.dt.txt')}</div>
        ) : (
          <div>
            <p>{dtFormat(params.row.expsrStartDate, 'date')}</p>
            <p>{dtFormat(params.row.expsrFnshDate, 'date')}</p>
          </div>
        );
      },
    },
    {
      field: 'regName',
      headerName: t('FaqMainCateListSection.datagrid.regname'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
    },
    {
      field: 'regDt',
      headerName: t('FaqMainCateListSection.datagrid.regdt'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
      renderCell: (params: any) => {
        return (
          <p className={'color-sub'}>{dtFormat(params.row.regDt, 'date')}</p>
        );
      },
    },
    {
      field: 'status',
      headerName: t('FaqMainCateListSection.datagrid.status'),
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        const status = params.row.useYn;
        return (
          <BtnComponent
            className={status === 'N' ? 'btn-sub' : 'btn-main'}
            txt={
              status === 'N'
                ? t('FaqMainCateListSection.datagrid.status.unuse')
                : t('FaqMainCateListSection.datagrid.status.use')
            }
          />
        );
      },
    },
  ];
  return (
    <div className={'dashboard-layout'}>
      <div className={'title'}>
        <h4>{t('faqmaincatelistsection.main.title')}</h4>
      </div>
      <div className={'flex justify-between'}>
        <div className={'userManage btn-wrap'}>
          <CountBtnComponent
            className={'on'}
            txt={t('faqmaincatelistsection.countbtn.all')}
            count={0}
            onClick={() => console.log('')}
          />
          <CountBtnComponent
            className={''}
            txt={t('faqmaincatelistsection.countbtn.use')}
            count={0}
            onClick={() => console.log('')}
            circle={'circle-green'}
          />
          <CountBtnComponent
            className={''}
            txt={t('faqmaincatelistsection.countbtn.unuse')}
            count={0}
            onClick={() => console.log('')}
            circle={'circle-gray'}
          />
        </div>
      </div>
    </div>
  );
};

export default FaqMainCateListSection;
