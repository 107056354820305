import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const prefix = 'contents';

interface InitialStateType {
  options: {
    value: any;
    label: string;
    selectDisabled?: boolean;
  }[];
  search: {
    searching: boolean;
    searchTp: string;
    searchTxt: string;
  };
  menu: 'STORY' | 'ACTION' | 'CHALLENGE';
}

const initialState: InitialStateType = {
  options: [],
  search: {
    searching: false,
    searchTp: '',
    searchTxt: '',
  },
  menu: 'ACTION',
};

const contents = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setContentsOptions: (
      state,
      { payload }: PayloadAction<typeof initialState.options>,
    ) => {
      state.options = payload;
    },
    setContentsMenu: (
      state,
      { payload }: PayloadAction<typeof initialState.menu>,
    ) => {
      state.menu = payload;
    },
    setContentsSearch: (
      state,
      { payload }: PayloadAction<typeof initialState.search>,
    ) => {
      state.search = payload;
    },
  },
});

export default contents.reducer;
export const { setContentsOptions, setContentsMenu, setContentsSearch } =
  contents.actions;
