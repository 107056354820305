import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FaqListParamType } from '../../types/faq/faqParamType';
import { axiosGet } from '../../layout/AppLayout';
import createRequestSaga from '../../utils/createReqSaga';
import { takeLatest } from 'redux-saga/effects';
import { FaqListApiType, FaqListType } from '../../types/faq/faqReduxType';
import exp from 'constants';

const prefix = 'support';

const GET_LIST = prefix + '/GET_LIST';
const GET_COUNT = prefix + '/GET_COUNT';
const GET_FAQ = prefix + '/GET_FAQ';

export const getFaqList = createAction<FaqListParamType>(GET_LIST);
export const getFaqCount = createAction(GET_COUNT);
export const getFaq = createAction<number>(GET_FAQ);

const getFaqListApi = (sendData: FaqListParamType) =>
  axiosGet('/support/faq/list', sendData);
const getFaqApi = (sendData: number) =>
  axiosGet('/support/faq/view/' + sendData);
const getFapCountApi = () => axiosGet('/support/faq/count');

const getFaqListSaga = createRequestSaga(GET_LIST, getFaqListApi);
const getFaqSaga = createRequestSaga(GET_FAQ, getFaqApi);
const getFapCountSaga = createRequestSaga(GET_COUNT, getFapCountApi);

export function* faqSaga() {
  yield takeLatest(GET_LIST, getFaqListSaga);
  yield takeLatest(GET_FAQ, getFaqSaga);
  yield takeLatest(GET_COUNT, getFapCountSaga);
}

interface InitialStateType {
  faqList: FaqListApiType;
  count: {
    all: number;
    use: number;
    unUse: number;
  };
  faq: FaqListType;
  faqModal: boolean;
  faqId: number;
}

const initialState: InitialStateType = {
  faqList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  count: {
    all: 0,
    use: 0,
    unUse: 0,
  },
  faq: {
    faqId: 0,
    pcateId: 0,
    pcateIdName: '',
    ccateId: 0,
    ccateIdName: '',
    title: '',
    ctnt: '',
    useYn: 'N',
    alwsExpsrYn: 'Y',
    expsrStartDate: '',
    expsrFnshDate: '',
    viewCnt: 0,
    regId: 0,
    regName: '',
    regDt: '',
    modId: 0,
    modName: '',
    modDt: '',
  },
  faqModal: false,
  faqId: 0,
};

const faq = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setFaqModal: (state, action: PayloadAction<boolean>) => {
      state.faqModal = action.payload;
    },
    setFaqId: (state, action: PayloadAction<number>) => {
      state.faqId = action.payload;
    },
  },
  extraReducers: {
    [GET_LIST + '_SUC']: (state, action: PayloadAction<FaqListApiType>) => {
      state.faqList = action.payload;
    },
    [GET_FAQ + '_SUC']: (state, action: PayloadAction<FaqListType>) => {
      state.faq = action.payload;
    },
    [GET_COUNT + '_SUC']: (
      state,
      action: PayloadAction<{ all: number; use: number; unUse: number }>,
    ) => {
      state.count = action.payload;
    },
  },
});

export default faq.reducer;
export const { setFaqModal, setFaqId } = faq.actions;
