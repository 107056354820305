import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../utils/createReqSaga';
import { axiosGet } from '../../layout/AppLayout';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlarmListApiType } from '../../types/alarm/alarmReduxType';
import {
  AlarmListParamType,
  AlarmParmaType,
} from '../../types/alarm/alarmParamType';

const prefix = 'alarm';

const GET_CNT = prefix + '/GET_CNT';
const GET_LIST = prefix + '/GET_LIST';

export const getAlarmCnt = createAction<AlarmParmaType>(GET_CNT);
export const getAlarmList = createAction<AlarmListParamType>(GET_LIST);

const getAlarmCntApi = (alarmDtlTpCd: AlarmParmaType) =>
  axiosGet('/alarm/count', alarmDtlTpCd);
const getAlarmListApi = (sendData: AlarmListParamType) =>
  axiosGet('/alarm/list', sendData);

const getAlarmCntSaga = createRequestSaga(GET_CNT, getAlarmCntApi);
const getAlarmListSaga = createRequestSaga(GET_LIST, getAlarmListApi);

export function* alarmSaga() {
  yield takeLatest(GET_CNT, getAlarmCntSaga);
  yield takeLatest(GET_LIST, getAlarmListSaga);
}

interface Interface {
  count: {
    all: number;
    send: number;
    unSend: number;
  };
  list: AlarmListApiType;
}

const initialState: Interface = {
  count: { all: 0, send: 0, unSend: 0 },
  list: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
};

const alarm = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: {
    [GET_CNT + '_SUC']: (
      state,
      action: PayloadAction<typeof initialState.count>,
    ) => {
      state.count = action.payload;
    },
    [GET_LIST + '_SUC']: (state, action: PayloadAction<AlarmListApiType>) => {
      state.list = action.payload;
    },
  },
});

export default alarm.reducer;
