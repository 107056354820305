import {
  DataGrid,
  GridColumnTypesRecord,
  GridFeatureMode,
  GridSelectionModel,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import { Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';

type Props = {
  prependNo?: boolean;
  totalRows?: number;
  totalPage: number;
  page: number;
  onChangePage: (page: number) => void;
  size: number;
  onChangePerRow: (value: any) => void;
  data: any;
  columns: any;
  rowHeight?: number;
  onRowClick?: (e: any) => void;
  check?: boolean;
  getRowId?: string;
  onCheck?: (checkedId: GridSelectionModel) => void;
  checkedValue?: GridSelectionModel;
  showPagination?: boolean;
  handleColumnSort?: (sortModel: GridSortModel) => void;
  sortingMode?: GridFeatureMode;
};
const DataGridComponent = ({
  prependNo = false,
  totalRows = 0,
  size,
  onChangePerRow,
  onChangePage,
  page,
  totalPage,
  data,
  columns,
  rowHeight,
  onRowClick,
  check,
  getRowId,
  onCheck,
  checkedValue,
  showPagination = true,
  handleColumnSort,
  sortingMode = 'client',
}: Props) => {
  const columnsWithNo = prependNo
    ? [
        {
          field: 'no',
          headerName: 'No.',
          headerAlign: 'center',
          width: 80,
          align: 'center',
          sortable: false,
        },
        ...columns,
      ]
    : columns;
  const [dataWithNo, setDataWithNo] = useState<any>([]);

  const DataGridPagination = () => {
    return (
      <div className={'m-t-15 flex'}>
        <div className={'m-r-15'}>
          <Pagination
            color="primary"
            count={totalPage}
            page={page + 1}
            onChange={(e, _page) => onChangePage(_page - 1)}
            showFirstButton
            showLastButton
          />
        </div>
        {showPagination && (
          <select
            value={size}
            className={'w-70 h-32'}
            onChange={e => onChangePerRow(e.target.value)}>
            <option>10</option>
            <option>20</option>
            <option>30</option>
            <option>40</option>
            <option>50</option>
          </select>
        )}
      </div>
    );
  };

  useEffect(() => {
    const dataWithNo = data.map((row: any, index: number) => {
      return { ...row, no: totalRows - index - page * size };
    });
    setDataWithNo(dataWithNo);
  }, [data]);

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        rows={dataWithNo}
        columns={columnsWithNo}
        onRowClick={onRowClick}
        rowHeight={rowHeight ? rowHeight : 50}
        autoHeight={true}
        checkboxSelection={check}
        disableColumnMenu={true}
        disableSelectionOnClick={true}
        getRowId={row => (getRowId ? row[getRowId] : row.id)}
        onSortModelChange={handleColumnSort}
        sortingMode={sortingMode}
        components={{
          Pagination: DataGridPagination,
          // Toolbar: GridToolbar,
        }}
        onSelectionModelChange={selectionModel =>
          onCheck ? onCheck(selectionModel) : undefined
        }
        selectionModel={checkedValue}
      />
    </div>
  );
};

export default DataGridComponent;
