import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChallengeGrpListParamType } from '../../types/challenge/challengeParamType';
import { axiosGet } from '../../layout/AppLayout';
import createRequestSaga from '../../utils/createReqSaga';
import { takeLatest } from 'redux-saga/effects';
import {
  chlngGrpInit,
  ChlngGrpListPagingType,
  ChlngGrpType,
} from '../../types/challenge/challengeReduxType';
import { pagingInit } from '../../types/common';

const prefix = 'chlngGrp';

const GET_CHLNG_GRP_LIST = prefix + '/GET_CHLNG_GRP_LIST';
const GET_CHLNG_GRP = prefix + '/GET_CHLNG_GRP';
const GET_COUNT = prefix + '/GET_COUNT';

export const getChlngGrpList =
  createAction<ChallengeGrpListParamType>(GET_CHLNG_GRP_LIST);
export const getChlngGrp = createAction<number>(GET_CHLNG_GRP);
export const getChlngGrpCount = createAction(GET_COUNT);

const getChlngGrpListApi = (sendData: ChallengeGrpListParamType) =>
  axiosGet('/chlng/grp/list', sendData);
const getChlngGrpApi = (id: number) => axiosGet(`/chlng/grp/${id}`);
const getCountApi = () => axiosGet('/chlng/grp/cnt');

const getChlngGrpListSaga = createRequestSaga(
  GET_CHLNG_GRP_LIST,
  getChlngGrpListApi,
);
const getChlngGrpSaga = createRequestSaga(GET_CHLNG_GRP, getChlngGrpApi);
const getCountSaga = createRequestSaga(GET_COUNT, getCountApi);

export function* chlngGrpSaga() {
  yield takeLatest(GET_CHLNG_GRP_LIST, getChlngGrpListSaga);
  yield takeLatest(GET_CHLNG_GRP, getChlngGrpSaga);
  yield takeLatest(GET_COUNT, getCountSaga);
}

interface InitialStateType {
  list: ChlngGrpListPagingType;
  grp: ChlngGrpType;
  file: File | null;
  display: boolean;
  count: {
    all: number;
    use: number;
    unUse: number;
  };
}

const initialState: InitialStateType = {
  list: pagingInit,
  grp: chlngGrpInit,
  file: null,
  display: false,
  count: {
    all: 0,
    use: 0,
    unUse: 0,
  },
};

const chlngGrp = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setChlngGrpFile: (state, action: PayloadAction<File | null>) => {
      state.file = action.payload;
    },
    setChlngGrp: (state, action: PayloadAction<ChlngGrpType>) => {
      state.grp = action.payload;
    },
    setChlngGrpDisplay: (state, action: PayloadAction<boolean>) => {
      state.display = action.payload;
    },
  },
  extraReducers: {
    [GET_CHLNG_GRP_LIST + '_SUC']: (
      state,
      action: PayloadAction<ChlngGrpListPagingType>,
    ) => {
      state.list = action.payload;
    },
    [GET_CHLNG_GRP + '_SUC']: (state, action: PayloadAction<ChlngGrpType>) => {
      state.grp = action.payload;
    },
    [GET_COUNT + '_SUC']: (
      state,
      action: PayloadAction<typeof initialState.count>,
    ) => {
      state.count = action.payload;
    },
  },
});

export default chlngGrp.reducer;
export const { setChlngGrpFile, setChlngGrp, setChlngGrpDisplay } =
  chlngGrp.actions;
