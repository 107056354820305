import { PagingTypes } from '../common';
import { BehaviorListType } from './behaviorReduxType';

export interface BhvrGrpListType {
  bhvrGrpId: number;
  imgUrl: string;
  grpTitle: string;
  grpCntn: string;
  totalPoint: number;
  regName: string;
  regDt: string;
  modeDt: string;
  useYn: 'Y' | 'N';
  bhvrCnt: number;
}

export interface BhvrGrpPagingType extends PagingTypes {
  content: BhvrGrpListType[];
}

export interface BhvrGrpCountType {
  all: number;
  use: number;
  unUse: number;
}

export interface BhvrGrpType {
  bhvrGrpId: number;
  imgUrl: string;
  useYn: string;
  grpTitle: string;
  grpCntn: string;
  bhvrIdList: BehaviorListType[];
}

export const bhvrGrpInit: BhvrGrpType = {
  bhvrGrpId: 0,
  imgUrl: '',
  useYn: 'N',
  grpTitle: '',
  grpCntn: '',
  bhvrIdList: [],
};
