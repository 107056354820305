import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addComma, dtFormat } from '../../../utils/commonFunc';
import ImgComponent from '../../../components/common/ImgComponent';
import { getUserPointList } from '../../../modules/point/point';
import { RootState } from '../../../modules';
import { PointDateType } from '../../../types/point/pointParamType';
import { UserPointType } from '../../../types/point/pointReduxType';
import DataGridComponent from '../../../components/common/DataGridComponent';
import { useTranslation } from 'react-i18next';

type Props = {
  id: number;
  paymentTp?: string;
};
const Point = ({ id, paymentTp }: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { userList } = useSelector(({ point }: RootState) => point);

  const [approveTp, setApproveTp] = useState<'Y' | 'N' | null>(null);
  const [date, setDate] = useState<PointDateType>('ALL');

  const [data, setData] = useState<UserPointType[]>([]);

  const ref = useRef<HTMLDivElement>(null);

  const columns = [
    {
      field: 'trstnDt',
      headerName: t('point.datagrid.trstndt'),
      headerAlign: 'center',
      width: 150,
      align: 'center',
      renderCell: (params: any) => {
        return <p className={'color-sub'}>{dtFormat(params.row.trstnDt)}</p>;
      },
    },
    {
      field: 'trstnTpCd',
      headerName: t('point.datagrid.trstntpcddesc'),
      headerAlign: 'center',
      align: 'left',
      width: 150,
      renderCell: (params: any) => {
        return (
          <p>
            {params.row.trstnTpCdDesc}
            <br />
            <span>Trstn ID: {params.row.trstnId}</span>
          </p>
        );
      },
    },
    {
      field: 'trstnDtlTpCd',
      headerName: t('point.datagrid.trstndtltpcddesc'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
    },
    {
      field: 'pointSaveState',
      headerName: t('point.datagrid.pointsavestatedesc'),
      headerAlign: 'center',
      align: 'center',
      width: 100,
    },
    {
      field: 'point',
      headerName: t('point.datagrid.point'),
      headerAlign: 'right',
      align: 'right',
      width: 150,
      renderCell: (params: any) => {
        return (
          <p className={params.row.point >= 0 ? 'color-main' : 'color-red'}>
            {params.row.point >= 0 ? '+' : ''}
            {addComma(params.row.point)}
          </p>
        );
      },
    },
    {
      field: 'cero',
      headerName: t('point.datagrid.cero'),
      headerAlign: 'right',
      align: 'right',
      width: 150,
      renderCell: (params: any) => {
        return (
          <p className={params.row.cero >= 0 ? 'color-main' : 'color-red'}>
            {params.row.cero > 0 ? '+' : ''}
            {addComma(params.row.cero)}
          </p>
        );
      },
    },
    // pointStatCdDesc
    {
      field: 'pointStatCdDesc',
      headerName: t('point.datagrid.pointstatcddesc'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
      renderCell: (params: any) => {
        return (
          <>
            <p
              className={
                params.row.pointStateCd.includes('WAIT_')
                  ? 'circle circle-red m-r-10'
                  : 'circle circle-green m-r-10'
              }
            />
            <p>
              {params.row.pointStateCd.includes('WAIT_')
                ? t('point.pointstatecd.wait')
                : t('point.pointstatecd.approve')}
            </p>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (id && id !== 0) {
      dispatch(
        getUserPointList({
          page: 0,
          size: 8,
          mmbrId: id,
          approveTp: approveTp,
          date: date,
          paymentTp: paymentTp,
        }),
      );
    }
  }, [id, approveTp, date, paymentTp]);
  useEffect(() => {
    if (userList) {
      setData(
        userList.content.map(d => ({
          ...d,
          trstnTpCd: t('constants.trstntpcd.' + d.trstnTpCd.toLowerCase()),
          trstnDtlTpCd: t(
            'constants.trstndtltpcd.' + d.trstnDtlTpCd.toLowerCase(),
          ),
          pointSaveState: t(
            'constants.pointsavestate.' + d.pointSaveState.toLowerCase(),
          ),
        })),
      );
    }
  }, [userList, t]);

  return (
    <div className={'flex justify-between align-start p-tb-15'}>
      <div className={'p-lr-15'} style={{ width: '100%' }}>
        <div className={'flex justify-between'}>
          <div className={'center'}>
            <h4
              className={approveTp === null ? 'tab-h4 on' : 'tab-h4'}
              onClick={() => setApproveTp(null)}>
              {t('point.btn.apporvetpnull')}
            </h4>
            <h4
              className={approveTp === 'Y' ? 'tab-h4 on' : 'tab-h4'}
              onClick={() => setApproveTp('Y')}>
              <span className={'circle circle-green m-r-10'} />
              {t('point.btn.apporvetpy')}
            </h4>
            <h4
              className={approveTp === 'N' ? 'tab-h4 on' : 'tab-h4'}
              onClick={() => setApproveTp('N')}>
              <span className={'circle circle-red m-r-10'} />
              {t('point.btn.apporvetpn')}
            </h4>
          </div>
          <select
            value={date}
            onChange={(e: any) => setDate(e.target.value)}
            style={{ height: '35px' }}>
            <option value={'ALL'}>{t('point.select.all')}</option>
            <option value={'DAY'}>{t('point.select.day')}</option>
            <option value={'WEEK'}>{t('point.select.week')}</option>
            <option value={'MONTH'}>{t('point.select.month')}</option>
          </select>
        </div>
        <div ref={ref} className={'h-530 y-scroll m-t-5'}>
          <div>
            <DataGridComponent
              getRowId={'pointId'}
              columns={
                paymentTp === 'point'
                  ? columns.filter((item: any) => item.field !== 'cero')
                  : columns.filter((item: any) => item.field !== 'point')
              }
              data={data}
              page={userList.number}
              totalPage={userList.totalPages}
              size={userList.size}
              onChangePerRow={size => {
                const sendData = {
                  page: 0,
                  size: size,
                  mmbrId: id,
                  approveTp: approveTp,
                  date: date,
                  paymentTp: paymentTp,
                };
                dispatch(getUserPointList(sendData));
              }}
              onChangePage={page => {
                const sendData = {
                  page: page,
                  size: userList.size,
                  mmbrId: id,
                  approveTp: approveTp,
                  date: date,
                  paymentTp: paymentTp,
                };
                dispatch(getUserPointList(sendData));
              }}
              showPagination={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type RewardTabProps = {
  tab: {
    focus: boolean;
    onClick: () => void;
    img: string;
    title: string;
  }[];
};
const RewardTab = ({ tab }: RewardTabProps) => {
  return (
    <div className={'wrap-reward'}>
      {tab.map((item, idx) => (
        <div
          key={idx}
          className={item.focus ? 'reward-tab on' : 'reward-tab'}
          onClick={item.onClick}>
          <ImgComponent src={item.img} />
          <p>{item.title}</p>
        </div>
      ))}
    </div>
  );
};

export default Point;
