import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Box } from '@mui/material';
import React from 'react';

type Props = {
  format: string;
  value: any;
  minDate?: any;
  maxDate?: any;
  onChange: (e: any) => void;
  readOnly?: boolean;
  inputClass?: string;
  placeholder?: string;
  boxClass: string;
};
const DatePickerComponent = ({
  minDate,
  maxDate,
  inputClass,
  placeholder,
  onChange,
  format,
  value,
  readOnly,
  boxClass,
}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        inputFormat={format}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={e => onChange(e)}
        readOnly={readOnly}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box className={boxClass}>
            <input
              className={'mui-datepicker ' + inputClass}
              ref={inputRef}
              {...inputProps}
              placeholder={placeholder}
            />
            {InputProps?.endAdornment}
          </Box>
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
