import { call, put } from 'redux-saga/effects';

export default function createRequestSaga(type: string, req: any) {
  const SUCCESS = type + '_SUC';
  const FAILURE = type + '_FAIL';

  return function* (action: any): any {
    try {
      const response = yield call(req, action.payload);
      yield put({
        type: SUCCESS,
        code: response.resultCd,
        payload: response.result,
      });
    } catch (e) {
      console.log(e);

      yield put({
        type: FAILURE,
        payload: e,
        error: true,
      });
    }
  };
}
