/**
 * Dart 쪽 로그인 암호화용 함수.
 */
export const encryptData = async (data: string) => {
  // 데이터를 UTF-8로 인코딩하여 바이트 배열로 변환
  const bytes = new TextEncoder().encode(data);

  // SHA-256 해시 생성
  const hashBuffer = await crypto.subtle.digest('SHA-256', bytes);

  // 해시 값을 16진수 문자열로 변환
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
};
