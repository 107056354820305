import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BehaviorListParamType } from '../../types/behavior/behaviorParamType';
import { axiosGet } from '../../layout/AppLayout';
import createRequestSaga from '../../utils/createReqSaga';
import { takeLatest } from 'redux-saga/effects';
import {
  BehaviorCountType,
  behaviorInit,
  BehaviorListPagingType,
  BehaviorType,
  guideInit,
  GuideListType,
} from '../../types/behavior/behaviorReduxType';
import { pagingInit } from '../../types/common';

const prefix = 'behavior';

const GET_BEHAVIOR_LIST = prefix + '/GET_BEHAVIOR_LIST';
const GET_BEHAVIOR = prefix + '/GET_BEHAVIOR';
const GET_BEHAVIOR_CNT = prefix + '/GET_BEHAVIOR_CNT';

export const getBehaviorList =
  createAction<BehaviorListParamType>(GET_BEHAVIOR_LIST);
export const getBehavior = createAction<number>(GET_BEHAVIOR);
export const getBehaviorCnt = createAction(GET_BEHAVIOR_CNT);

const getBehaviorListApi = (sendData: BehaviorListParamType) =>
  axiosGet('/behavior/list', sendData);
const getBehaviorApi = (id: number) => axiosGet(`/behavior/${id}`);
const getCountApi = () => axiosGet('/behavior/cnt');

const getBehaviorListSaga = createRequestSaga(
  GET_BEHAVIOR_LIST,
  getBehaviorListApi,
);
const getBehaviorSaga = createRequestSaga(GET_BEHAVIOR, getBehaviorApi);
const getCountSaga = createRequestSaga(GET_BEHAVIOR_CNT, getCountApi);

export function* behaviorSaga() {
  yield takeLatest(GET_BEHAVIOR_LIST, getBehaviorListSaga);
  yield takeLatest(GET_BEHAVIOR, getBehaviorSaga);
  yield takeLatest(GET_BEHAVIOR_CNT, getCountSaga);
}

interface InitialType {
  behaviorList: BehaviorListPagingType;
  modalDisplay: boolean;
  behavior: BehaviorType;
  file: File | null;
  removeGuideId: number[];
  count: BehaviorCountType;
}

const initialState: InitialType = {
  behaviorList: pagingInit,
  modalDisplay: false,
  behavior: behaviorInit,
  file: null,
  removeGuideId: [],
  count: {
    all: 0,
    use: 0,
    unUse: 0,
  },
};

const behavior = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setActionPopDisplay: (state, action: PayloadAction<boolean>) => {
      state.modalDisplay = action.payload;
    },
    setBehavior: (state, action: PayloadAction<BehaviorType>) => {
      state.behavior = action.payload;
    },
    setBehaviorFile: (state, action: PayloadAction<File | null>) => {
      state.file = action.payload;
    },
    setRemoveGuidId: (state, action: PayloadAction<number[]>) => {
      state.removeGuideId = action.payload;
    },
    setGuideList: (state, { payload }: PayloadAction<GuideListType>) => {
      state.behavior = {
        ...state.behavior,
        guideList: state.behavior.guideList.map(item =>
          item.guideId == payload.guideId
            ? { ...item, imgUrl: payload.imgUrl }
            : item,
        ),
      };
    },
    setBhvrList: (state, action: PayloadAction<BehaviorListPagingType>) => {
      state.behaviorList = action.payload;
    },
  },
  extraReducers: {
    [GET_BEHAVIOR_LIST + '_SUC']: (
      state,
      action: PayloadAction<BehaviorListPagingType>,
    ) => {
      state.behaviorList = action.payload;
    },
    [GET_BEHAVIOR + '_SUC']: (
      state,
      { payload }: PayloadAction<BehaviorType>,
    ) => {
      state.behavior = {
        ...payload,
        guideList: payload.guideList || [guideInit],
        metaCd: payload.metaCd || [],
        point: payload.point || 0,
        exp: payload.exp || 0,
      };
    },
    [GET_BEHAVIOR_CNT + '_SUC']: (
      state,
      action: PayloadAction<BehaviorCountType>,
    ) => {
      state.count = action.payload;
    },
  },
});

export default behavior.reducer;
export const {
  setActionPopDisplay,
  setBehavior,
  setBehaviorFile,
  setRemoveGuidId,
  setGuideList,
  setBhvrList,
} = behavior.actions;
