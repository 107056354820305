import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPersonalList } from '../../../modules/support/inquiry';
import { ifntScroll } from '../../../utils/commonFunc';
import { RootState } from '../../../modules';
import InquiryBoxComponent from '../../../components/inquiry/InquiryBoxComponent';
import { useTranslation } from 'react-i18next';

type Props = {
  id: number;
};
const Inquiry = ({ id }: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { personalList, personalPageList } = useSelector(
    ({ inquiry }: RootState) => inquiry,
  );
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(getPersonalList({ page: 0, size: 10, mmbrId: id }));
  }, [id]);

  return (
    <div className={'flex p-tb-15'}>
      <div
        ref={ref}
        className={'detail-content y-scroll'}
        onScroll={() =>
          ifntScroll(ref, personalPageList, 10, () =>
            dispatch(
              getPersonalList({
                page: personalList.number + 1,
                size: 10,
                mmbrId: id,
              }),
            ),
          )
        }>
        <span>{t('inquiry.inquiry.title')}</span>
        <div className={'m-t-10'}>
          {personalPageList.length > 0 ? (
            personalPageList.map(item => (
              <InquiryBoxComponent
                key={item.inquiryId}
                ansrStatusCd={item.ansrStatusCd}
                ansr={item.ansr}
                ansrRegDt={item.ansrRegDt}
                ctnt={item.ctnt}
                title={item.title}
                inquiryId={item.inquiryId}
                ansrStatusCdName={t(
                  'constants.ansrstatuscd.' + item.ansrStatusCd.toLowerCase(),
                )}
                regDt={item.regDt}
                nickname={item.ansrRegName}
                ansrId={item.ansrId}
                files={item.files}
              />
            ))
          ) : (
            <p className={'color-sub text-center m-t-50'}>
              {t('inquiry.inquiry.text')}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Inquiry;
