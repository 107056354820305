import React from 'react';
type Props = {
  className?: string;
  display: boolean;
  onClose?: () => void;
  children: React.ReactNode;
};
const PopupComponent = ({ className, onClose, display, children }: Props) => {
  return (
    <div
      className={'wrap-pop ' + className}
      style={{ display: display ? 'flex' : 'none' }}>
      <div className="black" onClick={onClose} />
      {children}
    </div>
  );
};

export default PopupComponent;
