import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const prefix = 'common';

interface Types {
  axiosErr: boolean;
}

const initialState: Types = {
  axiosErr: false,
};

const common = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setAxiosErr: (state, { payload }: PayloadAction<boolean>) => {
      state.axiosErr = payload;
    },
  },
});

export default common.reducer;
export const { setAxiosErr } = common.actions;
