import {
  ChallengeStatCdType,
  FreqTpCdType,
  ParticptnTpCdType,
  PartnerTpCdType,
} from './challengeCommonType';
import { PagingTypes } from '../common';

export interface ChallengeListType {
  chlngId: number;
  chlngTitle: string;
  imgUrl: string;
  partnerTpCd: PartnerTpCdType;
  partnerTpCdDesc: string;
  hostName: string;
  rangeYn: 'Y' | 'N';
  startDt: string;
  endDt: string;
  regDt: string;
  exp: number;
  useYn: 'Y' | 'N';
  challengeStatCd: ChallengeStatCdType;
  challengeStatCdDesc: string;
  chlngCntn: string;
  autoApprovalYn: string;
}

export interface ChallengeListPagingType extends PagingTypes {
  content: ChallengeListType[];
}

export interface GuideType {
  chlngGuide: string;
  chlngGuideId: number;
  guideSuccessYn: 'Y' | 'N';
  imgUrl: string;
  file?: File | null;
}

export interface ChallengeType {
  chlngId: number;
  chlngCntn: string;
  chlngEffect: string;
  chlngGuide: string;
  chlngTitle: string;
  effectExpsrYn: 'Y' | 'N';
  endDt: string;
  exp: number;
  freqTpCd: FreqTpCdType;
  guideList: GuideType[];
  hostName: string;
  imgUrl: string;
  langTpCd: 'KO';
  link: string;
  particptnNum: number;
  particptnTpCd: ParticptnTpCdType;
  partnerTpCd: PartnerTpCdType;
  point: number;
  rangeYn: string;
  startDt: string;
  useYn: string;
  particptnTpCdDesc: string;
  partnerTpCdDesc: string;
  freqTpCdDesc: string;
  autoApprovalYn: string;
}

export interface ChallengeGroupListAllType {
  cateId: number;
  cateTitle: string;
  useYn: 'Y' | 'N';
}

export const challengeGuideInit: GuideType = {
  chlngGuide: '',
  chlngGuideId: 0,
  guideSuccessYn: 'Y',
  imgUrl: '',
};

export const challengeInit: ChallengeType = {
  chlngId: 0,
  chlngCntn: '',
  chlngEffect: '',
  chlngGuide: '',
  chlngTitle: '',
  effectExpsrYn: 'Y',
  endDt: '',
  exp: 0,
  freqTpCd: 'DAY',
  guideList: [challengeGuideInit],
  hostName: '',
  imgUrl: '',
  langTpCd: 'KO',
  link: '',
  particptnNum: 0,
  particptnTpCd: 'NO_LIMIT',
  partnerTpCd: 'OFFICIAL',
  point: 0,
  rangeYn: 'N',
  useYn: 'N',
  startDt: '',
  partnerTpCdDesc: '공식 챌린지',
  particptnTpCdDesc: '제한 없음',
  freqTpCdDesc: '1일',
  autoApprovalYn: 'N',
};

export interface ChlngGrpListType {
  chlngCnt: number;
  chlngGrpId: number;
  gprTitle: string;
  grpCntn: string;
  imgUrl: string;
  regDt: string;
  totalPoint: number;
  useYn: 'Y' | 'N';
}

export interface ChlngGrpListPagingType extends PagingTypes {
  content: ChlngGrpListType[];
}

export interface ChlngGrpType {
  chlngGrpId: number;
  chlngIdList: number[];
  grpCntn: string;
  grpTitle: string;
  imgUrl: string;
  useYn: 'Y' | 'N';
}

export const chlngGrpInit: ChlngGrpType = {
  chlngGrpId: 0,
  chlngIdList: [],
  grpCntn: '',
  grpTitle: '',
  useYn: 'N',
  imgUrl: '',
};
