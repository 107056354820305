import React, { Component, useEffect, useState } from 'react';
import ImgComponent from '../../components/common/ImgComponent';
import BarBtnComponent from '../../components/common/BarBtnComponent';
import CountBtnComponent from '../../components/common/CountBtnComponent';
import DataGridComponent from '../../components/common/DataGridComponent';
import CreatorDetailPop from '../../layout/detailPopup/creator/CreatorDetailPop';
import { dtFormat } from '../../utils/commonFunc';
import BtnComponent from '../../components/common/BtnComponent';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { InquiryListParamType } from '../../types/inquiry/inquiryParamType';
import { getInquiryCount, getInquiryList } from '../../modules/support/inquiry';
import { AnsrStatusCdType } from '../../types/inquiry/inquiryReduxType';
import { setAnsrStatusCd, setSearch } from '../../modules/support/support';
import { useTranslation } from 'react-i18next';
const InquiryList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { inquiryList, count } = useSelector(
    ({ inquiry }: RootState) => ({
      inquiryList: inquiry.inquiryList,
      count: inquiry.count,
    }),
    shallowEqual,
  );
  const { inquiry, search } = useSelector(
    ({ support }: RootState) => support,
    shallowEqual,
  );

  const [modal, setModal] = useState(false);
  const [mmbrId, setMmbrId] = useState(0);

  useEffect(() => {
    // setSearching(false);
    // setSearchTxt('');
    dispatch(setSearch({ ...search, searching: false, searchTxt: '' }));
    dispatchList({
      ansrStatusCd: inquiry.ansrStatusCd,
      page: 0,
      size: inquiryList.size,
    });
    dispatch(getInquiryCount());
  }, [inquiry.ansrStatusCd]);

  const dispatchList = ({
    ansrStatusCd,
    searchTp,
    searchTxt,
    page,
    size,
  }: InquiryListParamType) => {
    dispatch(
      getInquiryList({
        page: page,
        size: size,
        ansrStatusCd,
        searchTp,
        searchTxt,
      }),
    );
  };

  const columns = [
    {
      field: 'inquiryId',
      headerName: t('inquirylist.datagrid.inquiryid'),
      headerAlign: 'center',
      align: 'center',
      width: 50,
    },
    {
      field: 'nickname',
      headerName: t('inquirylist.datagrid.nickname'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
    },
    {
      field: 'email',
      headerName: t('inquirylist.datagrid.email'),
      headerAlign: 'center',
      align: 'center',
      width: 200,
    },
    {
      field: 'cateTpCdNm',
      headerName: t('inquirylist.datagrid.category'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
      renderCell: (params: any) => {
        return (
          <p>{t('constants.category.' + params.row.cateTpCd.toLowerCase())}</p>
        );
      },
    },
    {
      field: 'title',
      headerName: t('inquirylist.datagrid.title'),
      headerAlign: 'left',
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div className={'grid-cell'}>
            <p className={'color-main'}>{params.row.title}</p>
            <h5 className={'color-sub text-ellipsis text-row-2'}>
              {params.row.ctnt}
            </h5>
          </div>
        );
      },
    },
    {
      field: 'fileYn',
      headerName: t('inquirylist.datagrid.fileyn'),
      headerAlign: 'center',
      align: 'center',
      width: 80,
      renderCell: (params: any) => {
        return (
          <div>
            {params.row.fileYn === 'Y' && <ImgComponent src={'file.svg'} />}
          </div>
        );
      },
    },
    {
      field: 'ansrRegDt',
      headerName: t('inquirylist.datagrid.ansrregdt'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
      renderCell: (params: any) => {
        return (
          <p className={'color-sub'}>
            {params.row.ansrRegDt
              ? dtFormat(params.row.ansrRegDt, 'date')
              : '-'}
          </p>
        );
      },
    },
    {
      field: 'regDt',
      headerName: t('inquirylist.datagrid.regdt'),
      headerAlign: 'center',
      align: 'center',
      width: 150,
      renderCell: (params: any) => {
        return (
          <p className={'color-sub'}>{dtFormat(params.row.regDt, 'date')}</p>
        );
      },
    },
    {
      field: 'ansrStatusCd',
      headerName: t('inquirylist.datagrid.ansrstatuscd'),
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        const status = params.row.ansrStatusCd;
        return (
          <BtnComponent
            className={status === 'ANSWER' ? 'btn-sub' : 'btn-main'}
            txt={t(
              'constants.ansrstatuscdname.' +
                params.row.ansrStatusCd.toLowerCase(),
            )}
          />
        );
      },
    },
  ];
  return (
    <main>
      <div className={'dashboard-layout'}>
        <div className={'title'}>
          <h4>{t('inquirylist.main.title')}</h4>
        </div>
        <div className={'flex justify-between'}>
          <div className={'userManage btn-wrap'}>
            <CountBtnComponent
              className={inquiry.ansrStatusCd === 'ALL' ? 'on' : undefined}
              txt={t('inquirylist.countbtn.all')}
              count={count?.all || 0}
              onClick={() => dispatch(setAnsrStatusCd('ALL'))}
            />
            <CountBtnComponent
              className={inquiry.ansrStatusCd === 'REGISTER' ? 'on' : undefined}
              circle={'circle-green'}
              txt={t('inquirylist.countbtn.register')}
              count={count?.regCnt || 0}
              onClick={() => dispatch(setAnsrStatusCd('REGISTER'))}
            />
            <CountBtnComponent
              className={inquiry.ansrStatusCd === 'ANSWER' ? 'on' : undefined}
              circle={'circle-white'}
              txt={t('inquirylist.countbtn.answer')}
              count={count?.ansCnt || 0}
              onClick={() => dispatch(setAnsrStatusCd('ANSWER'))}
            />
          </div>
        </div>

        <DataGridComponent
          rowHeight={100}
          columns={columns}
          data={inquiryList.content}
          page={inquiryList.number}
          totalPage={inquiryList.totalPages}
          size={inquiryList.size}
          getRowId={'inquiryId'}
          onChangePerRow={size => {
            const sendData = search.searching
              ? {
                  page: 0,
                  size: size,
                  state: inquiry.ansrStatusCd,
                  searchTp: search.searchTp,
                  searchTxt: search.searchTxt,
                }
              : {
                  page: 0,
                  size: size,
                  state: inquiry.ansrStatusCd,
                };
            dispatchList(sendData);
          }}
          onChangePage={page => {
            const sendData = search.searching
              ? {
                  page: page,
                  size: inquiryList.size,
                  state: inquiry.ansrStatusCd,
                  searchTp: search.searchTp,
                  searchTxt: search.searchTxt,
                }
              : {
                  page: page,
                  size: inquiryList.size,
                  state: inquiry.ansrStatusCd,
                };
            dispatchList(sendData);
          }}
          onRowClick={e => {
            console.log(e);
            setMmbrId(e.row.mmbrId);
            setModal(!modal);
          }}
          check={false}
        />
      </div>

      <CreatorDetailPop
        display={modal}
        mmbrId={mmbrId}
        defaultMenu={'inquiry'}
        onClickClose={() => {
          const sendData = search.searching
            ? {
                page: inquiryList.number,
                size: inquiryList.size,
                ansrStatusCd: inquiry.ansrStatusCd,
                searchTp: search.searchTp,
                searchTxt: search.searchTxt,
              }
            : {
                page: inquiryList.number,
                size: inquiryList.size,
                ansrStatusCd: inquiry.ansrStatusCd,
              };
          setModal(!modal);
          dispatch(getInquiryList(sendData));
          setMmbrId(0);
        }}
      />
    </main>
  );
};

export default InquiryList;
