import React, { useEffect } from 'react';

interface Props<T> {
  options: {
    value: T;
    label: string;
    selectDisabled?: boolean;
  }[];
  value?: T;
  onChange: (val: T, label: string) => void;
  className?: string;
  disabled?: boolean;
  selectFirstValue?: boolean;
}
const Select = <T,>({
  options,
  value,
  onChange,
  className,
  disabled,
  selectFirstValue,
}: Props<T>) => {
  useEffect(() => {
    if (selectFirstValue) {
      if (options.length > 0) {
        onChange(options[0].value, options[0].label);
      }
    }
  }, [options]);
  return (
    <select
      disabled={disabled}
      value={String(value)}
      className={className}
      onChange={e =>
        onChange(
          options[e.target.selectedIndex].value,
          options[e.target.selectedIndex].label,
        )
      }>
      {options.map((item, index) => (
        <option key={index} value={String(item.value)}>
          {item.label}
        </option>
      ))}
    </select>
  );
};

Select.defaultValue = {
  disabled: false,
};

export default Select;
