import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import createRequestSaga from '../../utils/createReqSaga';
import { axiosGet } from '../../layout/AppLayout';
import { takeLatest } from 'redux-saga/effects';
import {
  SearchVendorType,
  VendorListType,
} from '../../types/store/vendorReduxType';

/* Prefix ID */
const prefix = 'vendor';

/* API 모음 */
/* 1. 검색 조건에 따른 조회 */
const GET_SEARCH_VENDOR_LIST = prefix + '/GET_SEARCH_VENDOR_LIST';
export const getSearchVendorList = createAction<SearchVendorType>(
  GET_SEARCH_VENDOR_LIST,
);

const getSearchVendorListApi = (params: SearchVendorType) =>
  axiosGet('/product/vendor/list', params);

const getSearchVendorListSaga = createRequestSaga(
  GET_SEARCH_VENDOR_LIST,
  getSearchVendorListApi,
);

/* Saga */
export function* vendorSaga() {
  yield takeLatest(GET_SEARCH_VENDOR_LIST, getSearchVendorListSaga);
}

interface InitialStateType {
  vendorList: VendorListType;
}

const initialState: InitialStateType = {
  vendorList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
};

const vendor = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: {
    [GET_SEARCH_VENDOR_LIST + '_SUC']: (
      state,
      action: PayloadAction<VendorListType>,
    ) => {
      state.vendorList = action.payload;
    },
  },
});

export default vendor.reducer;
