import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { RootState } from '../../../modules/index';
import { getUserOrg } from '../../../modules/user/user';
import SwitchComponent from '../../../components/common/SwitchComponent';
import BtnPopupLayout from '../../../layout/BtnPopupLayout';
import Select from '../../../components/common/Select';
import { organCateCdOptions } from '../../../types/user/userCommonType';
import CheckBtnComponent from '@components/common/CheckBtnComponent';

interface Props {
  id: number;
  info: any;
  setInfo: React.Dispatch<React.SetStateAction<any>>;
}

const Organization = ({ id, info, setInfo }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mmbrorg, profile } = useSelector(({ user }: RootState) => user);

  const [orgNmChecked, setOrgNmChecked] = useState(false); // 체크박스 상태 추적

  // 체크박스 상태 변경 핸들러
  const checkboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.checked) {
        setInfo({ ...info, orgNm: mmbrorg.orgNm });
      }
      setOrgNmChecked(e.target.checked);
    },
    [info, mmbrorg],
  );

  useEffect(() => {
    dispatch(getUserOrg(id));
  }, [id]);

  useEffect(() => {
    if (mmbrorg) {
      setInfo(mmbrorg);
    }
  }, [mmbrorg]);
  return (
    <div className={'flex p-tb-15'}>
      <div className={'detail-content y-scroll'}>
        <div className={'w-600'}>
          <div className={'m-l-30 m-r-15 p-tb-15'}>
            <div className={'color-sub p-b-8 flex'}>
              <span className={'w-150'}>{t('orgregist.info.organcatecd')}</span>
              {/* 그룹 카테고리 */}
              <Select
                className={'action-input w-300'}
                options={organCateCdOptions?.map(item => ({
                  value: item.value,
                  label: t(item.label),
                }))}
                value={info.organCateCd}
                onChange={val => setInfo({ ...info, organCateCd: val })}
              />
            </div>
            <div className={'color-sub p-b-8 flex'}>
              <span className={'w-150'}>{t('orgregist.info.enprNm')}</span>
              <input
                className={'action-input w-300'}
                value={info?.orgNm}
                onChange={e => setInfo({ ...info, orgNm: e.target.value })}
                disabled={!orgNmChecked} // 체크박스가 체크되면 입력 필드 비활성화
              />
              <span className={'w-20 p-l-10'}></span>
              <input
                className={'action-input w-15'}
                type="checkbox"
                checked={orgNmChecked}
                onChange={checkboxChange}
              />
              <span className={'w-100 p-l-10'} style={{ paddingTop: '8px' }}>
                {t('orgregist.checkbox.label')}
              </span>
            </div>
            <div className={'color-sub p-b-8 flex'}>
              <span className={'w-150'}>
                {t('orgregist.info.businesslicence')}
              </span>
              <div className={'flex justify-between w-300'}>
                <input
                  className={'action-input w-300'}
                  value={info.bussinessNo}
                  onChange={e =>
                    setInfo({ ...info, bussinessNo: e.target.value })
                  }
                />
              </div>
            </div>
            <div className={'color-sub p-b-8 flex'}>
              <span className={'w-150'}>{t('orgregist.info.enprAddr')}</span>
              <input
                className={'action-input w-300'}
                value={info.orgAddr}
                onChange={e => setInfo({ ...info, orgAddr: e.target.value })}
              />
            </div>
            <div className={'color-sub p-b-8 flex'}>
              <span className={'w-150'}>
                {t('orgregist.info.enprAddrDetail')}
              </span>
              <input
                className={'action-input w-300'}
                value={info.orgAddrDetail}
                onChange={e =>
                  setInfo({ ...info, orgAddrDetail: e.target.value })
                }
              />
            </div>
            <div className={'color-sub p-b-8 flex'}>
              <span className={'w-150'}>{t('orgregist.info.mngNm')}</span>
              <input
                className={'action-input w-300'}
                value={info.mngNm}
                onChange={e => setInfo({ ...info, mngNm: e.target.value })}
              />
            </div>
            <div className={'color-sub p-b-8 flex'}>
              <span className={'w-150'}>{t('orgregist.info.mngTelNo')}</span>
              <div className={'flex justify-between w-300'}>
                <input
                  className={'action-input w-300'}
                  value={info.mngTelNo}
                  onChange={e => setInfo({ ...info, mngTelNo: e.target.value })}
                />
              </div>
            </div>
            <div className={'color-sub p-b-8 flex'}>
              <span className={'w-150'}>{t('orgregist.info.mngEmail')}</span>
              <input
                className={'action-input w-300'}
                value={info.mngEmail}
                onChange={e => setInfo({ ...info, mngEmail: e.target.value })}
              />
            </div>
            <div className={'color-sub p-b-8 flex'}>
              <span className={'w-150'}>{t('orgregist.info.useYn')}</span>
              <SwitchComponent
                value={info.useYn === 'Y'}
                onChange={e =>
                  setInfo({
                    ...info,
                    useYn: e.target.checked ? 'Y' : 'N',
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Organization.displayName = 'Organization';

export default Organization;
