import SideMenuLayout from './SideMenuLayout';
import { useLocation } from 'react-router-dom';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import BtnPopupLayout from './BtnPopupLayout';
import { ReactNode, useEffect, useState } from 'react';
import { Ring } from '@uiball/loaders';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import React from 'react';
import { RootState } from '../modules';
import { setAxiosErr } from '../modules/common';
import { useTranslation } from 'react-i18next';
import i18n from '../utils/i18nUtil';
import { doLogin, setAdminAuth } from '../modules/login/login';

declare global {
  interface Window {
    tempProperty: string;
  }
}

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  headers?: {
    'Accept-Language'?: string;
  };
}

const axiosInstance: AxiosInstance = axios.create({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

type ReturnType = {
  resultCd: string;
  resultMsg: string;
  result: any;
};

export const axiosGet = (url: string, params?: any) => {
  const localStorage = window.localStorage.getItem('persist:root');
  const tokenStr = localStorage && JSON.parse(localStorage).login;
  const token = JSON.parse(tokenStr).token;

  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return new Promise<ReturnType>((resolve, reject) => {
    axiosInstance
      .get(process.env.REACT_APP_API_PATH + url, {
        params: params,
        responseType: 'json',
        withCredentials: false,
      })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        console.log({ ...e });
        e.response ? reject(e.response) : reject(e);
      });
  });
};

export const axiosPost = (url: string, body?: any, params?: any) => {
  const localStorage = window.localStorage.getItem('persist:root');
  const tokenStr = localStorage && JSON.parse(localStorage).login;
  const token = JSON.parse(tokenStr).token;

  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  return new Promise<ReturnType>((resolve, reject) => {
    axiosInstance
      .post(process.env.REACT_APP_API_PATH + url, JSON.stringify(body), {
        withCredentials: false,
        params,
      })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        console.log({ ...e });
        e.response ? reject(e.response) : reject(e);
      });
  });
};

export const axiosFile = (url: string, params?: any) => {
  const formData = new FormData();
  for (const key in params) {
    formData.append(key, params[key]);
  }
  const localStorage = window.localStorage.getItem('persist:root');
  const tokenStr = localStorage && JSON.parse(localStorage).login;
  const token = JSON.parse(tokenStr).token;

  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  axiosInstance.defaults.headers.common['Content-Type'] = 'multipart/form-data';

  return new Promise<ReturnType>((resolve, reject) => {
    axiosInstance
      .post(process.env.REACT_APP_API_PATH + url, formData, {
        withCredentials: false,
      })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        e.response ? reject(e.response) : reject(e);
      });
  });
};

export const axiosExcel = (url: string, params?: any) => {
  const localStorage = window.localStorage.getItem('persist:root');
  const tokenStr = localStorage && JSON.parse(localStorage).login;
  const token = JSON.parse(tokenStr).token;

  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return new Promise<Blob>((resolve, reject) => {
    axiosInstance
      .get(process.env.REACT_APP_API_PATH + url, {
        params: params,
        responseType: 'blob',
        withCredentials: false,
      })
      .then(data => {
        resolve(data.data);
      })
      .catch(e => {
        console.log({ ...e });
        e.response ? reject(e.response) : reject(e);
      });
  });
};

type Props = {
  children: ReactNode;
};

const AppLayout = ({ children }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const login = path.includes('login') || path === '/';
  const height = window.innerHeight;
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const isMobile = useSelector((state: any) => state.login.isMobile);
  const { token, axiosErr } = useSelector(
    (state: RootState) => ({
      token: state.login.token,
      axiosErr: state.common.axiosErr,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (token) {
      axiosInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${token}`;
    } else {
      navigate('/login');
    }
  }, [token]);

  useEffect(() => {
    //axios interceptor
    axiosInstance.interceptors.request.use(
      config => {
        // Accept-Language 헤더를 설정
        if (config?.headers) {
          config.headers['Accept-Language'] = i18n.language;
        }

        //loading start
        setIsLoading(true);
        dispatch(setAxiosErr(false));
        return config;
      },
      err => {
        setIsLoading(false);
        dispatch(setAxiosErr(true));
        return Promise.reject(err);
      },
    );
    axiosInstance.interceptors.response.use(
      config => {
        //loading end
        setIsLoading(false);
        dispatch(setAxiosErr(false));
        return config;
      },
      async err => {
        setIsLoading(false);
        if (err.response.status === 401) {
          dispatch(doLogin(''));
          dispatch(setAdminAuth(''));
          setIsLogout(true);
        } else {
          dispatch(setAxiosErr(true));
        }
        return Promise.reject(err);
      },
    );
  }, []);

  const onClickLoginPopup = () => {
    setIsLogout(false);
    navigate('/login');
  };
  return (
    <div
      id="wrap"
      className="wrap"
      style={{
        position: login ? 'absolute' : 'inherit',
        minHeight: height + 'px',
      }}>
      {!login && !isMobile && <SideMenuLayout />}
      {children}
      <div
        className={'wrap-pop black'}
        style={{ display: isLoading ? 'flex' : 'none' }}>
        <Ring size={50} lineWeight={5} speed={3} color="#00E6C0" />
      </div>
      <BtnPopupLayout
        display={axiosErr}
        content={t('applayout.btn.err')}
        btn={[
          {
            txt: t('applayout.btn.confirm'),
            class: 'btn-main',
            onClick: () => dispatch(setAxiosErr(!axiosErr)),
          },
        ]}
        onClose={() => dispatch(setAxiosErr(!axiosErr))}
      />
      <BtnPopupLayout
        display={isLogout}
        content={t('applayout.btn.islogout')}
        btn={[
          {
            txt: t('applayout.btn.confirm'),
            class: 'btn-main',
            onClick: onClickLoginPopup,
          },
        ]}
        onClose={onClickLoginPopup}
      />
    </div>
  );
};

export default AppLayout;
