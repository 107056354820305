import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BhvrGrpListParmaType } from '../../types/behavior/bhvrGrpParamType';
import { axiosGet } from '../../layout/AppLayout';
import createRequestSaga from '../../utils/createReqSaga';
import { takeLatest } from 'redux-saga/effects';
import {
  BhvrGrpCountType,
  bhvrGrpInit,
  BhvrGrpPagingType,
  BhvrGrpType,
} from '../../types/behavior/bhvrGrpReduxType';
import { pagingInit } from '../../types/common';
import { yellow } from '@mui/material/colors';

const prefix = 'bhvrGrp';

const GET_GRP_LIST = prefix + '/GET_GRP_LIST';
const GET_GRP = prefix + '/GET_GRP';
const GET_COUNT = prefix + '/GET_COUNT';

export const getBhvrGrpList = createAction<BhvrGrpListParmaType>(GET_GRP_LIST);
export const getBhvrGrp = createAction<number>(GET_GRP);
export const getBhvrGrpCnt = createAction(GET_COUNT);

const getBhvrGrpListApi = (sendData: BhvrGrpListParmaType) =>
  axiosGet('/behavior/grp/list', sendData);
const getBhvrGrpApi = (id: number) => axiosGet(`/behavior/grp/${id}`);
const getCountApi = () => axiosGet('/behavior/grp/count');

const getBhvrGrpListSaga = createRequestSaga(GET_GRP_LIST, getBhvrGrpListApi);
const getBhvrGrpSaga = createRequestSaga(GET_GRP, getBhvrGrpApi);
const getCountSaga = createRequestSaga(GET_COUNT, getCountApi);

export function* behaviorGroupSaga() {
  yield takeLatest(GET_GRP_LIST, getBhvrGrpListSaga);
  yield takeLatest(GET_GRP, getBhvrGrpSaga);
  yield takeLatest(GET_COUNT, getCountSaga);
}

interface InitialStateType {
  list: BhvrGrpPagingType;
  count: BhvrGrpCountType;
  modalDisplay: boolean;
  grp: BhvrGrpType;
  file: File | null;
}

const initialState: InitialStateType = {
  list: pagingInit,
  count: {
    all: 0,
    use: 0,
    unUse: 0,
  },
  modalDisplay: false,
  grp: bhvrGrpInit,
  file: null,
};

const behaviorGroup = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setBhvrGrpDisplayModal: (state, action: PayloadAction<boolean>) => {
      state.modalDisplay = action.payload;
    },
    setBhvrGrp: (state, action: PayloadAction<BhvrGrpType>) => {
      state.grp = action.payload;
    },
    setBhvrGrpFile: (state, action: PayloadAction<File | null>) => {
      state.file = action.payload;
    },
  },
  extraReducers: {
    [GET_GRP_LIST + '_SUC']: (
      state,
      action: PayloadAction<BhvrGrpPagingType>,
    ) => {
      state.list = action.payload;
    },
    [GET_GRP + '_SUC']: (state, action: PayloadAction<BhvrGrpType>) => {
      state.grp = action.payload;
    },
    [GET_COUNT + '_SUC']: (
      state,
      action: PayloadAction<typeof initialState.count>,
    ) => {
      state.count = action.payload;
    },
  },
});

export default behaviorGroup.reducer;
export const { setBhvrGrpDisplayModal, setBhvrGrp, setBhvrGrpFile } =
  behaviorGroup.actions;
