import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosGet } from '../../layout/AppLayout';
import createRequestSaga from '../../utils/createReqSaga';
import { takeLatest } from 'redux-saga/effects';
import {
  FaqCateListApiType,
  FaqCateListType,
} from '../../types/faq/faqCateReduxType';
import {
  FaqCateListPagingParamType,
  FaqCateListParamType,
} from '../../types/faq/faqCateParamType';

const prefix = 'faqCate';

const GET_MAIN_CATEGORY_PAGING_LIST = prefix + '/GET_MAIN_CATEGORY_PAGING_LIST';
const GET_MAIN_CATEGORY_LIST = prefix + '/GET_MAIN_CATEGORY_LIST';
const GET_SUB_CATEGORY_PAGING_LIST = prefix + '/GET_SUB_CATEGORY_PAGING_LIST';
const GET_SUB_CATEGORY_LIST = prefix + '/GET_SUB_CATEGORY_LIST';

export const getFaqCategoryPagingList =
  createAction<FaqCateListPagingParamType>(GET_MAIN_CATEGORY_PAGING_LIST);
export const getFaqMainCateList = createAction<FaqCateListParamType>(
  GET_MAIN_CATEGORY_LIST,
);
export const getSubCatePagingList = createAction<FaqCateListPagingParamType>(
  GET_SUB_CATEGORY_PAGING_LIST,
);
export const getFaqSubCateList = createAction<FaqCateListParamType>(
  GET_SUB_CATEGORY_LIST,
);

const getFaqCategoryListPagingApi = (sendData: FaqCateListPagingParamType) =>
  axiosGet('/support/faq-cate/list', sendData);
const getFaqCategoryListApi = (sendData: FaqCateListParamType) =>
  axiosGet('/support/faq-cate/list/all', sendData);

const getFaqCateListPagingSaga = createRequestSaga(
  GET_MAIN_CATEGORY_PAGING_LIST,
  getFaqCategoryListPagingApi,
);
const getMainCateListSaga = createRequestSaga(
  GET_MAIN_CATEGORY_LIST,
  getFaqCategoryListApi,
);
const getSubCatePagingListSaga = createRequestSaga(
  GET_SUB_CATEGORY_PAGING_LIST,
  getFaqCategoryListPagingApi,
);
const getSubCateListSaga = createRequestSaga(
  GET_SUB_CATEGORY_LIST,
  getFaqCategoryListApi,
);

export function* faqCategorySaga() {
  yield takeLatest(GET_MAIN_CATEGORY_PAGING_LIST, getFaqCateListPagingSaga);
  yield takeLatest(GET_MAIN_CATEGORY_LIST, getMainCateListSaga);
  yield takeLatest(GET_SUB_CATEGORY_PAGING_LIST, getSubCatePagingListSaga);
  yield takeLatest(GET_SUB_CATEGORY_LIST, getSubCateListSaga);
}

interface InitialStateType {
  mainCateList: FaqCateListApiType;
  subCateList: FaqCateListApiType;
  mainCateOptions: FaqCateListType[];
  subCateOptions: FaqCateListType[];
}

const initialState: InitialStateType = {
  mainCateList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  subCateList: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
  mainCateOptions: [],
  subCateOptions: [],
};

const faqCate = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setSubCateList: (state, action: PayloadAction<FaqCateListType[]>) => {
      state.subCateOptions = action.payload;
    },
  },
  extraReducers: {
    [GET_MAIN_CATEGORY_LIST + '_SUC']: (
      state,
      action: PayloadAction<FaqCateListType[]>,
    ) => {
      state.mainCateOptions = action.payload;
    },
    [GET_SUB_CATEGORY_LIST + '_SUC']: (
      state,
      action: PayloadAction<FaqCateListType[]>,
    ) => {
      state.subCateOptions = action.payload;
    },
    [GET_MAIN_CATEGORY_PAGING_LIST + '_SUC']: (
      state,
      action: PayloadAction<FaqCateListApiType>,
    ) => {
      state.mainCateList = action.payload;
    },
    [GET_SUB_CATEGORY_PAGING_LIST + '_SUC']: (
      state,
      action: PayloadAction<FaqCateListApiType>,
    ) => {
      state.subCateList = action.payload;
    },
  },
});

export default faqCate.reducer;
export const { setSubCateList } = faqCate.actions;
