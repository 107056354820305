import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../utils/createReqSaga';
import { axiosGet } from '../../layout/AppLayout';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CertifyCntParamType,
  CertifyListParamType,
} from '../../types/certify/certifyParamType';
import {
  CertifyCountType,
  CertifyListType,
} from '../../types/certify/certifyReduxType';

const prefix = 'certify';

const GET_CNT = prefix + '/GET_CNT';
const GET_LIST = prefix + '/GET_LIST';

export const getCertifyCnt = createAction<CertifyCntParamType>(GET_CNT);
export const getCertifyList = createAction<CertifyListParamType>(GET_LIST);

const getStoryCntApi = (sendData: CertifyCntParamType) =>
  axiosGet('/certify/count', sendData);
const getStoryListApi = (sendData: CertifyListParamType) =>
  axiosGet('/certify/list', sendData);

const getStoryCntSaga = createRequestSaga(GET_CNT, getStoryCntApi);
const getStoryListSaga = createRequestSaga(GET_LIST, getStoryListApi);

export function* certifySaga() {
  yield takeLatest(GET_CNT, getStoryCntSaga);
  yield takeLatest(GET_LIST, getStoryListSaga);
}

interface InitialStateType {
  count: CertifyCountType;
  list: CertifyListType;
}

const initialState: InitialStateType = {
  count: {
    all: 0,
    active: 0,
    waiting: 0,
    inActive: 0,
    reject: 0,
  },
  list: {
    content: [],
    number: 0,
    size: 0,
    totalPages: 0,
    totalElements: 0,
  },
};

const certify = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: {
    [GET_CNT + '_SUC']: (
      state,
      { payload }: PayloadAction<CertifyCountType>,
    ) => {
      state.count = payload;
    },
    [GET_LIST + '_SUC']: (
      state,
      { payload }: PayloadAction<CertifyListType>,
    ) => {
      state.list = payload;
    },
  },
});

// const certify = handleActions(
//   {
//     [GET_CNT_SUC]: (state, action) => ({
//       ...state,
//       count: action.payload,
//     }),
//     [GET_LIST_SUC]: (state, action) => ({
//       ...state,
//       list: action.payload,
//     }),
//     [GET_CERTIFY_SUC]: (state, action) => ({
//       ...state,
//       story: action.payload,
//     }),
//   },
//   initialState,
// );

export default certify.reducer;
