import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import HeaderLayout from '../../layout/HeaderLayout';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SearchFormComponent from '../../components/common/SearchFormComponent';
import { RootState } from '../../modules';
import {
  setSearch,
  setFaqSearch,
  setSearchTp,
  setSearchTxt,
} from '../../modules/support/support';
import { useTranslation } from 'react-i18next';
import { setEndDt, setStartDt } from '../../modules/support/support';
import { dtFormat } from '../../utils/commonFunc';

import InquiryList from './InquiryList';
import FaqList from './FaqList';
import ReportList from './ReportList';

import { getInquiryList } from '../../modules/support/inquiry';
import { getFaqList } from '../../modules/support/faq';

const Setting = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search, inquiry, faqSearch, reportSearch } = useSelector(
    ({ support }: RootState) => support,
    shallowEqual,
  );
  const { inquiryList } = useSelector(
    ({ inquiry }: RootState) => inquiry,
    shallowEqual,
  );
  const { faqList } = useSelector(({ faq }: RootState) => faq, shallowEqual);
  const { reportList } = useSelector(
    ({ report }: RootState) => report,
    shallowEqual,
  );
  const [menu, setMenu] = useState<'inquiry' | 'faq' | 'report'>('inquiry');

  useEffect(() => {
    const currentPath = location.pathname.split('/').pop();
    if (
      currentPath === 'inquiry' ||
      currentPath === 'faq' ||
      currentPath === 'report'
    ) {
      setMenu(currentPath);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (menu === 'inquiry') {
      dispatch(setSearchTp('INQUIRY_TITLE'));
      dispatch(
        getInquiryList({
          page: 0,
          size: inquiryList.size,
          ansrStatusCd: 'ALL',
        }),
      );
    } else if (menu === 'faq') {
      dispatch(setSearchTp('TITLE'));
      dispatch(getFaqList({ page: 0, size: faqList.size }));
    }

    dispatch(setSearchTxt(''));
  }, [menu]);

  const onSearch = () => {
    dispatch(setSearch({ ...search, searching: true }));
    dispatch(setFaqSearch({ ...faqSearch, searching: true }));

    if (menu === 'inquiry') {
      dispatch(
        getInquiryList({
          page: 0,
          size: inquiryList.size,
          ansrStatusCd: inquiry.ansrStatusCd,
          searchTp: search.searchTp,
          searchTxt: search.searchTxt,
        }),
      );
    } else if (menu === 'faq') {
      if (faqSearch.searchTp === 'REGTERM') {
        dispatch(
          getFaqList({
            page: 0,
            size: faqList.size,
            searchTp: faqSearch.searchTp,
            startDt: dtFormat(faqSearch.startDt),
            endDt: dtFormat(faqSearch.endDt),
          }),
        );
      } else {
        dispatch(
          getFaqList({
            page: 0,
            size: faqList.size,
            searchTp: faqSearch.searchTp,
            searchTxt: faqSearch.searchTxt,
          }),
        );
      }
    }
  };

  return (
    <div className={'container'}>
      <HeaderLayout
        title={t('support.header.title')}
        tabMenu={[
          {
            txt: t('support.tabmenu.inquiry'),
            onClick: () => navigate('/support/inquiry'),
            focus: menu === 'inquiry',
          },
          {
            txt: t('support.tabmenu.faq'),
            onClick: () => navigate('/support/faq'),
            focus: menu === 'faq',
          },
          {
            txt: t('support.tabmenu.report'),
            onClick: () => navigate('/support/report'),
            focus: menu === 'report',
          },
        ]}>
        {menu === 'inquiry' ? (
          <select
            className={'h-32 m-r-20'}
            onChange={e =>
              dispatch(setSearch({ ...search, searchTp: e.target.value }))
            }>
            <option value={'INQUIRY_TITLE'} selected>
              {t('support.select.title')}
            </option>
            <option value={'INQUIRY_CTNT'}>
              {t('support.select.inquiry_ctnt')}
            </option>
            <option value={'EMAIL'}>{t('support.select.email')}</option>
            <option value={'NAME'}>{t('support.select.name')}</option>
          </select>
        ) : menu === 'faq' ? (
          <select
            className={'h-32 m-r-20'}
            onChange={e =>
              dispatch(setFaqSearch({ ...faqSearch, searchTp: e.target.value }))
            }>
            <option value={'TITLE'} selected>
              {t('support.select.title')}
            </option>
            <option value={'REGTERM'}>{t('support.select.regdt')}</option>
          </select>
        ) : (
          <select
            className={'h-32 m-r-20'}
            onChange={e =>
              dispatch(setSearch({ ...search, searchTp: e.target.value }))
            }>
            <option value={'mmbrNickname'} selected>
              {t('support.select.mmbrnickname')}
            </option>
            <option value={'mmbrEmail'}>{t('support.select.mmbremail')}</option>
            <option value={'rprtTrgtmmbrNickname'}>
              {t('support.select.rprttrgtmmbrnickname')}
            </option>
            <option value={'rprtTrgtmmbrEmail'}>
              {t('support.select.rprttrgtmmbremail')}
            </option>
          </select>
        )}

        <SearchFormComponent
          normalValue={{
            value: search.searchTxt,
            onChange: val => dispatch(setSearchTxt(val)),
          }}
          placeholder={t('support.searchform.placeholder')}
          onSearch={() => onSearch()}
          type={
            menu === 'faq' && faqSearch.searchTp === 'REGTERM'
              ? 'DATE'
              : 'INPUT'
          }
          dateValue={{
            startDt: faqSearch.startDt,
            endDt: faqSearch.endDt,
            onChangeStartDt: value => dispatch(setStartDt(value)),
            onChangeEndDt: value => dispatch(setEndDt(value)),
          }}
        />
      </HeaderLayout>

      {menu === 'inquiry' && <InquiryList />}
      {menu === 'faq' && <FaqList />}
      {menu === 'report' && <ReportList setMenu={setMenu} menu={menu} />}
    </div>
  );
};

export default Setting;
