import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../utils/createReqSaga';
import { axiosGet } from '../../layout/AppLayout';
import {
  InquiryListParamType,
  PersonalListParamType,
} from '../../types/inquiry/inquiryParamType';
import {
  AnsrStatusCdType,
  InquiryCountType,
  InquiryListType,
  PersonalListType,
} from '../../types/inquiry/inquiryReduxType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getEndDt, getStartDt } from '../../utils/commonFunc';

const prefix = 'support';

interface InitialStateType {
  search: {
    searchTp: string | 'INQUIRY_TITLE' | 'INQUIRY_CTNT' | 'EMAIL' | 'NAME';
    searching: boolean;
    searchTxt: string;
  };
  faqSearch: {
    searchTp: string | 'TITLE' | 'REGTERM';
    searching: boolean;
    searchTxt: string;
    startDt: string;
    endDt: string;
  };
  reportSearch: {
    searching: boolean;
    searchTp: string;
    searchTxt: string;
    startDt: string;
    endDt: string;
  };
  inquiry: {
    ansrStatusCd: AnsrStatusCdType;
  };
}

const initialState: InitialStateType = {
  search: {
    searching: false,
    searchTp: 'INQUIRY_TITLE',
    searchTxt: '',
  },
  faqSearch: {
    searching: false,
    searchTp: 'TITLE',
    searchTxt: '',
    startDt: getStartDt(new Date()),
    endDt: getEndDt(new Date()),
  },
  reportSearch: {
    searching: false,
    searchTp: 'mmbrNickname',
    searchTxt: '',
    startDt: getStartDt(new Date()),
    endDt: getEndDt(new Date()),
  },
  inquiry: {
    ansrStatusCd: 'ALL',
  },
};

const support = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<typeof initialState.search>) => {
      state.search = action.payload;
    },
    setFaqSearch: (
      state,
      action: PayloadAction<typeof initialState.faqSearch>,
    ) => {
      state.faqSearch = action.payload;
    },
    setSearchTp: (state, action: PayloadAction<string>) => {
      state.search.searchTp = action.payload;
      state.faqSearch.searchTp = action.payload;
    },
    setSearchTxt: (state, action: PayloadAction<string>) => {
      state.search.searchTxt = action.payload;
      state.faqSearch.searchTxt = action.payload;
    },
    setAnsrStatusCd: (state, action: PayloadAction<AnsrStatusCdType>) => {
      state.inquiry.ansrStatusCd = action.payload;
    },
    setStartDt: (state, action: PayloadAction<string>) => {
      state.faqSearch.startDt = action.payload;
    },
    setEndDt: (state, action: PayloadAction<string>) => {
      state.faqSearch.endDt = action.payload;
    },
  },
});
export default support.reducer;
export const {
  setSearch,
  setFaqSearch,
  setSearchTp,
  setSearchTxt,
  setAnsrStatusCd,
  setStartDt,
  setEndDt,
} = support.actions;
