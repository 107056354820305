import { PagingTypes } from '../common';

export interface MetaCdType {
  imgUrl: string;
  langCd: string;
  metaCd: string;
  metaCntn: string;
  metaTitle: string;
  useYn: string;
}

export interface BehaviorListType {
  approvalCnt: number;
  bhvrCntn: string;
  bhvrId: number;
  bhvrTitle: string;
  exp: number;
  hit: number;
  link: string;
  modDt: string;
  point: number;
  regDt: string;
  regName: string;
  rejectCnt: number;
  useYn: string;
  metaCd: MetaCdType[];
  imgUrl: string;
}

export interface BehaviorListPagingType extends PagingTypes {
  content: BehaviorListType[];
}

export interface GuideListType {
  guideId: number;
  bhvrId: number;
  imgUrl: string;
  bhvrGuide: string;
  guideSuccessYn: 'Y' | 'N';
  file?: File | null;
}

export interface BehaviorType {
  bhvrId: number;
  bhvrTitle: string;
  bhvrCntn: string;
  useYn: 'Y' | 'N' | null;
  metaCd: MetaCdType[];
  point: number;
  exp: number;
  link: string;
  grpTitleList: string[];
  bhvrEffect: string;
  bhvrGuide: string;
  guideList: GuideListType[];
  imgUrl: string;
  hit?: number | null;
}

export const guideInit: GuideListType = {
  guideId: 0,
  guideSuccessYn: 'Y',
  bhvrGuide: '',
  imgUrl: '',
  bhvrId: 0,
};

export const behaviorInit: BehaviorType = {
  bhvrId: 0,
  bhvrTitle: '',
  bhvrCntn: '',
  useYn: 'N',
  metaCd: [],
  point: 0,
  exp: 0,
  link: '',
  grpTitleList: [],
  bhvrEffect: '',
  bhvrGuide: '',
  guideList: [guideInit],
  imgUrl: '',
  hit: 0,
};

export interface BehaviorCountType {
  all: number;
  use: number;
  unUse: number;
}
