import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PopupComponent from '../../components/common/PopupComponent';
import BtnComponent from '../../components/common/BtnComponent';

type Props = {
  display: boolean;
  onClickClose: () => void;
  selectedRowInfo: any;
  setSelectedRowInfo: (info: any) => void; // 추가
};

const ReportDetailPop = ({
  display,
  onClickClose,
  selectedRowInfo,
  setSelectedRowInfo,
}: Props) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState({
    mmbrEmail: selectedRowInfo ? selectedRowInfo.mmbrEmail : '',
    mmbrNickname: selectedRowInfo ? selectedRowInfo.mmbrNickname : '',
    rprtTrgtMmbrEmail: selectedRowInfo ? selectedRowInfo.rprtTrgtMmbrEmail : '',
    rprtTrgtMmbrNickname: selectedRowInfo
      ? selectedRowInfo.rprtTrgtMmbrNickname
      : '',
    rprtTpCdMsgCode: selectedRowInfo ? selectedRowInfo.rprtTpCdMsgCode : '',
    rprtRsn: selectedRowInfo ? selectedRowInfo.rprtRsn : '',
    rprtStatCdMsgCode: selectedRowInfo ? selectedRowInfo.rprtStatCdMsgCode : '',
    regDtYmdHms: selectedRowInfo ? selectedRowInfo.regDtYmdHms : '',
  });

  useEffect(() => {
    setInfo({
      mmbrEmail: selectedRowInfo ? selectedRowInfo.mmbrEmail : '',
      mmbrNickname: selectedRowInfo ? selectedRowInfo.mmbrNickname : '',
      rprtTrgtMmbrEmail: selectedRowInfo
        ? selectedRowInfo.rprtTrgtMmbrEmail
        : '',
      rprtTrgtMmbrNickname: selectedRowInfo
        ? selectedRowInfo.rprtTrgtMmbrNickname
        : '',
      rprtTpCdMsgCode: selectedRowInfo ? selectedRowInfo.rprtTpCdMsgCode : '',
      rprtRsn: selectedRowInfo ? selectedRowInfo.rprtRsn : '',
      rprtStatCdMsgCode: selectedRowInfo
        ? selectedRowInfo.rprtStatCdMsgCode
        : '',
      regDtYmdHms: selectedRowInfo ? selectedRowInfo.regDtYmdHms : '',
    });
  }, [selectedRowInfo]);

  // 닫기 버튼 클릭시 값 초기화
  const onClose = () => {
    setInfo({
      mmbrEmail: '',
      mmbrNickname: '',
      rprtTrgtMmbrEmail: '',
      rprtTrgtMmbrNickname: '',
      rprtTpCdMsgCode: '',
      rprtRsn: '',
      rprtStatCdMsgCode: '',
      regDtYmdHms: '',
    });
    setSelectedRowInfo(null);
    onClickClose();
  };
  return (
    <PopupComponent display={display} onClose={() => onClose()}>
      <div className={'pop detail-register'}>
        <div className={'detail-main'}>
          <div className={'title'}>
            <div className={'flex justify-between m-r-20'}>
              <h3>{t('report.header.title.detail')}</h3>
            </div>
            <hr className={'w-600 m-t-15 m-b-20'} />
          </div>
          <div className={'flex justify-center'}>
            <div className={'w-400 m-r-20'}>
              <div>
                {/* 신고자 이메일 */}
                <div className={'color-sub p-b-8 flex justify-between'}>
                  <span>{t('report.datagrid.mmbremail')}</span>
                  <input
                    className={'action-input w-300'}
                    value={info.mmbrEmail}
                    autoComplete={'one-time-code'}
                    readOnly
                  />
                </div>
                {/* 신고자 닉네임 */}
                <div className={'color-sub p-b-8 flex justify-between'}>
                  <span>{t('report.datagrid.mmbrnickname')}</span>
                  <input
                    className={'action-input w-300'}
                    value={info.mmbrNickname}
                    autoComplete={'one-time-code'}
                    readOnly
                  />
                </div>
                {/* 피신고자 이메일 */}
                <div className={'color-sub p-b-8 flex justify-between'}>
                  <span>{t('report.datagrid.rprttrgtemail')}</span>
                  <input
                    className={'action-input w-300'}
                    value={info.rprtTrgtMmbrEmail}
                    autoComplete={'one-time-code'}
                    readOnly
                  />
                </div>
                {/* 피신고자 닉네임 */}
                <div className={'color-sub p-b-8 flex justify-between'}>
                  <span>{t('report.datagrid.rprttrgtnickname')}</span>
                  <input
                    className={'action-input w-300'}
                    value={info.rprtTrgtMmbrNickname}
                    autoComplete={'one-time-code'}
                    readOnly
                  />
                </div>
                {/* 신고 유형 */}
                <div className={'color-sub p-b-8 flex justify-between'}>
                  <span>{t('report.datagrid.rprttp')}</span>
                  <input
                    className={'action-input w-300'}
                    value={info.rprtTpCdMsgCode}
                    autoComplete={'one-time-code'}
                    readOnly
                  />
                </div>
                {/* 신고 사유 */}
                <div className={'color-sub p-b-8 flex justify-between'}>
                  <span>{t('report.datagrid.rprtrsn')}</span>
                  <textarea
                    className={'action-input w-300'}
                    value={info.rprtRsn}
                    autoComplete={'one-time-code'}
                    readOnly
                  />
                </div>
                {/* 신고 상태 */}
                <div className={'color-sub p-b-8 flex justify-between'}>
                  <span>{t('report.datagrid.rprtstat')}</span>
                  <input
                    className={'action-input w-300'}
                    value={info.rprtStatCdMsgCode}
                    autoComplete={'one-time-code'}
                    readOnly
                  />
                </div>
                {/* 신고 일자 */}
                <div className={'color-sub p-b-8 flex justify-between'}>
                  <span>{t('report.datagrid.regdt')}</span>
                  <input
                    className={'action-input w-300'}
                    value={info.regDtYmdHms}
                    autoComplete={'one-time-code'}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
          {/* 하단 정보 및 버튼 */}
          <div className={'flex justify-center m-t-20 m-r-20'}>
            <div className={'btn-wrap'}>
              <BtnComponent
                className={'btn-close'}
                txt={t('product.btn.close')}
                onClick={onClose}
                style={{ width: '156px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </PopupComponent>
  );
};

export default ReportDetailPop;
