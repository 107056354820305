import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import BtnComponent from '../components/common/BtnComponent';
import PopupComponent from '../components/common/PopupComponent';
import parse from 'html-react-parser';

type Props = {
  display: boolean;
  onClose?: () => void;
  content: string;
  children?: React.ReactNode;
  btn: {
    class: string;
    txt: string;
    img?: string;
    onClick: () => void;
  }[];
};
const BtnPopupLayout = ({
  btn,
  display,
  onClose,
  content,
  children,
}: Props) => {
  return (
    <PopupComponent display={display} className={'z-9999'} onClose={onClose}>
      <div className={'btn-modal'}>
        <div className={'modal-x'} onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} />
        </div>
        <div>{parse(content)}</div>
        <div>{children}</div>
        <div className={'btn-wrap'}>
          {btn.map((item, idx) => (
            <BtnComponent
              key={idx}
              className={item.class}
              txt={item.txt}
              img={item.img}
              onClick={item.onClick}
            />
          ))}
        </div>
      </div>
    </PopupComponent>
  );
};

export default BtnPopupLayout;
