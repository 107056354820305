import React, { Component, useState } from 'react';
import { setAnsrStatusCd } from '../../modules/support/support';
import CountBtnComponent from '../../components/common/CountBtnComponent';
import ImgComponent from '../../components/common/ImgComponent';
import BarBtnComponent from '../../components/common/BarBtnComponent';
import { dtFormat } from '../../utils/commonFunc';
import BtnComponent from '../../components/common/BtnComponent';
import DataGridComponent from '../../components/common/DataGridComponent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { getFaqList, setFaqModal } from '../../modules/support/faq';
import FaqDetailPop from '../../layout/detailPopup/FaqDetailPop';
import FaqListSection from '../../components/inquiry/faq/FaqListSection';
import FaqMainCateListSection from '../../components/inquiry/faq/FaqMainCateListSection';
import { useTranslation } from 'react-i18next';

const FaqList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [menu, setMenu] = useState<'FAQ' | 'MAIN' | 'SUB'>('FAQ');
  return (
    <main>
      {/*<div
        className={'plus-div'}
        onClick={() => {
          dispatch(setFaqModal(true));
        }}>
        <h4 className={'color-main'}>{t('faqlist.barbtn.register')}</h4>
        <ImgComponent className={'m-l-10'} src={'plus.svg'} />
      </div>
      <BarBtnComponent
        btn={[
          {
            on: menu === 'FAQ',
            onClick: () => setMenu('FAQ'),
            txt: t('faqlist.barbtn.faq'),
          },
          {
            on: menu == 'MAIN',
            onClick: () => setMenu('MAIN'),
            txt: t('faqlist.barbtn.main'),
          },
          {
            on: menu == 'SUB',
            onClick: () => setMenu('SUB'),
            txt: t('faqlist.barbtn.sub'),
          },
        ]}
      />*/}
      {menu === 'FAQ' && <FaqListSection />}
      {menu === 'MAIN' && <FaqMainCateListSection />}
    </main>
  );
};

export default FaqList;
