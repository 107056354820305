import { PagingTypes } from '../common';
import { BehaviorListType } from './behaviorReduxType';

export interface RecomListType {
  bhvrCnt: number;
  bhvrRecomId: number;
  imgUrl: string;
  modDt: string;
  recomCntn: string;
  recomTitle: string;
  regDt: string;
  regName: string;
  totalPoint: number;
  useYn: string;
}

export interface RecomListPagingType extends PagingTypes {
  content: RecomListType[];
}

export interface RecomType {
  bhvrRecomId: number;
  imgUrl: string;
  recomTitle: string;
  recomCntn: string;
  useYn: string;
  bhvrList: BehaviorListType[];
}

export const recomInit: RecomType = {
  bhvrRecomId: 0,
  imgUrl: '',
  recomTitle: '',
  recomCntn: '',
  bhvrList: [],
  useYn: 'N',
};
